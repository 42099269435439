import styles from "./styles";
import SpriteAnimator from "../spriteAnimator/SpriteAnimator";
import {
  DEFAULT_EQUIPEED_BOTTOM,
  DEFAULT_EQUIPEED_FACE,
  DEFAULT_EQUIPEED_HAIR,
  DEFAULT_EQUIPEED_SKIN,
  DEFAULT_EQUIPEED_TOP,
  // DEFAULT_EQUIPEED_WOMAN_BOTTOM,
  // DEFAULT_EQUIPEED_WOMAN_HAIR,
  // DEFAULT_EQUIPEED_WOMAN_TOP,
} from "../../../utils/shop";
import useAuth from "../../../hooks/useAuth";
import { useEffect, useLayoutEffect, useState } from "react";
import { Gender } from "../../../api/auth";
const spriteSteps = 3; // 스프라이트 이미지에 있는 동작의 수
const animationInterval = 500;
interface SpriteAnimatorProps {
  topImage: string; // 상의
  onePieceImage?: string; // 한벌옷
  bottomImage: string; // 하의
  shoesImage: string; // 신발
  hatImage: string; // 머리
  capeImage: string; // 머리
  earringImage: string; // 머리

  hairImage: string; // 머리
  faceImage: string; // 얼굴
  accImage: string; // 악세사리
  faceAccessoryImage: string; // 얼굴장식
  handAccessoryImage: string; // 얼굴장식
  skinImage: string; // 피부색
  isSetEquipped: boolean;
  gender?: Gender;
  size?: number;
  // width?: number;
  // height?: number;
}

export default function Avator({
  topImage,
  onePieceImage,
  bottomImage,
  shoesImage,
  hatImage,
  hairImage,
  earringImage,
  faceImage,
  capeImage,
  accImage,
  faceAccessoryImage,
  handAccessoryImage,
  skinImage,
  gender,
  isSetEquipped,
  size,
  // height,
}: SpriteAnimatorProps) {
  const auth = useAuth();

  const [isReady, setIsReady] = useState(false);

  useLayoutEffect(() => {
    if (auth.state === "VALID") {
      setIsReady(true);
    }
  }, []);
  const [step, setStep] = useState<number>(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setStep((prevStep) => (prevStep + 1) % spriteSteps);
    }, animationInterval);

    return () => clearInterval(intervalId);
  }, []);

  const newGender = gender ? gender : auth.gender === "WOMAN" ? "WOMAN" : "MAN";
  // 비로그인시 스토리지에서 gender 가져옴
  const nonUserGender = window.sessionStorage.getItem("gender");

  // console.log("cape", capeImage);

  return (
    <div css={styles.avatarContainer}>
      {/* 로그아웃 상태일때 기본 아바타 */}
      {!isReady ? (
        <>
          {DEFAULT_EQUIPEED_TOP[nonUserGender] && (
            <SpriteAnimator step={step} type="BODY" imageUrl={DEFAULT_EQUIPEED_SKIN} />
          )}
          <div css={styles.avatarSpriteContainer}>
            <SpriteAnimator
              step={step}
              type="HAIR"
              imageUrl={DEFAULT_EQUIPEED_HAIR[nonUserGender]}
              size={size}
            />
            <SpriteAnimator
              step={step}
              type="FACE"
              imageUrl={DEFAULT_EQUIPEED_FACE[nonUserGender]}
              size={size}
            />
            <SpriteAnimator
              step={step}
              type="TOP"
              imageUrl={DEFAULT_EQUIPEED_TOP[nonUserGender]}
              size={size}
            />
            <SpriteAnimator
              step={step}
              type="BOTTOM"
              imageUrl={DEFAULT_EQUIPEED_BOTTOM[nonUserGender]}
              size={size}
            />
          </div>
        </>
      ) : (
        // 로그인 상태일때 아바타
        <>
          {isSetEquipped && (
            <>
              <SpriteAnimator step={step} type="CAPE" imageUrl={capeImage} size={size} />

              <SpriteAnimator
                step={step}
                type="BODY"
                imageUrl={skinImage || DEFAULT_EQUIPEED_SKIN}
                size={size}
              />

              <div css={styles.avatarSpriteContainer}>
                <SpriteAnimator
                  step={step}
                  type="HAIR"
                  imageUrl={hairImage || DEFAULT_EQUIPEED_HAIR[newGender]}
                  size={size}
                />
                <SpriteAnimator
                  step={step}
                  type="FACE"
                  imageUrl={faceImage || DEFAULT_EQUIPEED_FACE[newGender]}
                  size={size}
                />
                <SpriteAnimator step={step} type="HAT" imageUrl={hatImage} size={size} />
                <SpriteAnimator
                  step={step}
                  type="FACE_ACCESSORY"
                  imageUrl={faceAccessoryImage}
                  size={size}
                />
                <SpriteAnimator step={step} type="EARRING" imageUrl={earringImage} size={size} />
                <SpriteAnimator step={step} type="SHOES" imageUrl={shoesImage} size={size} />

                {onePieceImage !== DEFAULT_EQUIPEED_TOP[newGender] &&
                onePieceImage !== undefined ? (
                  <>
                    {/* <SpriteAnimator step={step} type="TOP" imageUrl={onePieceImage} /> */}
                    {/* <SpriteAnimator step={step} type="BOTTOM" imageUrl={bottomImage} /> */}
                    <SpriteAnimator
                      step={step}
                      type="TOP"
                      imageUrl={onePieceImage || topImage || DEFAULT_EQUIPEED_TOP[newGender]}
                      size={size}
                    />
                    <SpriteAnimator
                      step={step}
                      type="BOTTOM"
                      imageUrl={bottomImage || DEFAULT_EQUIPEED_BOTTOM[newGender]}
                      size={size}
                    />
                    <SpriteAnimator
                      step={step}
                      type="TOP"
                      imageUrl={onePieceImage || topImage || DEFAULT_EQUIPEED_TOP[newGender]}
                    />
                  </>
                ) : (
                  <>
                    <SpriteAnimator
                      step={step}
                      type="TOP"
                      imageUrl={topImage || DEFAULT_EQUIPEED_TOP[newGender]}
                      size={size}
                    />
                    <SpriteAnimator
                      step={step}
                      type="BOTTOM"
                      imageUrl={bottomImage || DEFAULT_EQUIPEED_BOTTOM[newGender]}
                      size={size}
                    />
                    <SpriteAnimator
                      step={step}
                      type="TOP"
                      imageUrl={topImage || DEFAULT_EQUIPEED_TOP[newGender]}
                    />
                  </>
                )}

                <SpriteAnimator
                  step={step}
                  type="HAND_ACCESSORY"
                  imageUrl={handAccessoryImage}
                  size={size}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
