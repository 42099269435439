import { BoardItemList, ChannelPluginMap, GetChannelInfoResponse } from "../api/channel";
import { RootReducer } from "../modules/reducers";
import { useDispatch, useSelector } from "react-redux";
import {
  ChannelInitalState,
  ChannelRole,
  actionSetChannelInfo,
  actionSetChannelMenu,
  actionSetChannelPlugin,
  actionSetChannelRole,
  actionSetIsReady,
} from "../modules/reducers/channel";
import { PluginList, PluginType } from "../api/channel-setting";

const useChannel = () => {
  const dispatch = useDispatch();

  const { info, pluginMap, menu, role, isReady }: ChannelInitalState = useSelector(
    (state: RootReducer) => state.channelReducer,
  );

  const setChannelPlugin = (pluginMap: ChannelPluginMap) => {
    dispatch(actionSetChannelPlugin(pluginMap));
  };

  const setInfo = (info: GetChannelInfoResponse) => {
    dispatch(actionSetChannelInfo(info));
  };

  const setMenu = (menu: BoardItemList[]) => {
    dispatch(actionSetChannelMenu(menu));
  };

  const setRole = (role: ChannelRole) => {
    dispatch(actionSetChannelRole(role));
  };

  const setIsReady = (isReady: boolean) => {
    dispatch(actionSetIsReady(isReady));
  };

  return {
    info,
    pluginMap,
    menu,
    role,
    isReady,
    setInfo: (info: GetChannelInfoResponse) => {
      setInfo(info);
    },
    setChannelPlugin: (pluginList: PluginList[]) => {
      const pluginMap: Map<PluginType, PluginList> = new Map(
        pluginList.map((plugin) => {
          return [plugin.pluginType, plugin];
        }),
      );

      setChannelPlugin({
        GOOGLE_ANALYTICS_4: {
          isUsed: pluginMap.get("GOOGLE_ANALYTICS_4")?.isUsed,
          pluginKey: pluginMap.get("GOOGLE_ANALYTICS_4")?.pluginKey,
        },
        NAVER_SEARCH_ADVISOR: {
          isUsed: pluginMap.get("NAVER_SEARCH_ADVISOR")?.isUsed,
          pluginKey: pluginMap.get("NAVER_SEARCH_ADVISOR")?.pluginKey,
        },
        CHANNEL_TALK: {
          isUsed: pluginMap.get("CHANNEL_TALK")?.isUsed,
          pluginKey: pluginMap.get("CHANNEL_TALK")?.pluginKey,
        },
        KAKAO_CHANNEL: {
          isUsed: pluginMap.get("KAKAO_CHANNEL")?.isUsed,
          pluginKey: pluginMap.get("KAKAO_CHANNEL")?.pluginKey,
        },
      });
    },
    setMenu: (menu: BoardItemList[]) => {
      setMenu(menu);
    },
    setRole: (role: ChannelRole) => {
      setRole(role);
    },
    setIsReady: (isReady: boolean) => {
      setIsReady(isReady);
    },
  };
};

export default useChannel;
