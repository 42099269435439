import AnimationConfetti from "../../components/animation/AnimationConfetti";
import useAnimation from "../../hooks/useAnimation";
import { Fragment } from "react";

const AnimationHandler = () => {
  const animation = useAnimation();

  return <Fragment>{animation.confetti.visible && <AnimationConfetti />}</Fragment>;
};

export default AnimationHandler;
