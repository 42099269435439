import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "../shared/request";
import { Gender } from "./auth";
import { EquippedItem } from "common/hooks/useAvator";

export interface GetAvatorLevelExperienceResponse {
  level: number;
  levelExp: number;
  requiredLevelPoint: number;
}

export type ResponseEquippedItems = {
  equippedUserItemList: EquippedItem[];
};

/**
 * API: Inventory
 * 장비 장착 목록
 */
export const getEquippedItems = () => {
  return axiosInstance
    .get<ResponseEquippedItems>(`v1/auth/worlds/equipped-user-items`)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 아바타 > 레벨
 */
export const getAvatorLevelExperience = () => {
  return axiosInstance
    .get<GetAvatorLevelExperienceResponse>("/v1/users/experiences")
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 로그인시 아바타 성별 선택
 */
export const postAvatorGender = (gender: Omit<Gender, "NOT_SELECTED">) => {
  return axiosInstance
    .post("/v1/users/profiles/genders", { gender })
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};
