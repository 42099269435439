import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "../shared/request";

export interface GetSocketTokenResponse {
  token: string;
  worldId: number;
  roomId: number;
}

/**
 * API:
 * 소켓 연결 Token
 */
export const getSocketToken = (target: "notify" | "rooms" | "worlds") => {
  return axiosInstance
    .post<GetSocketTokenResponse>(
      `/v1/${target === "notify" ? "users" : "auth"}/${target}/connections`,
    )
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};
