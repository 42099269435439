import { ShopLargeCategory, ShopMediumCategory } from "../api/shop";

/**
 * @description: 캐릭터 상의+하의 기본 이미지
 */
const DEFAULT_EQUIPEED_HAIR = {
  MAN: "/resources/img/avators/default-hair-sprite.png",
  WOMAN: "/resources/img/avators/default-hair-woman-sprite.png",
  NOT_SELECTED: "/resources/img/avators/default-hair-sprite.png",
};
const DEFAULT_EQUIPEED_FACE = {
  MAN: "/resources/img/avators/default-face-man-sprite.png",
  WOMAN: "/resources/img/avators/default-face-woman-sprite.png",
  NOT_SELECTED: "/resources/img/avators/default-face-man-sprite.png",
};
const DEFAULT_EQUIPEED_TOP = {
  MAN: "/resources/img/avators/default-top-sprite.png",
  WOMAN: "/resources/img/avators/default-top-woman-sprite.png",
  NOT_SELECTED: "/resources/img/avators/default-top-sprite.png",
};
const DEFAULT_EQUIPEED_BOTTOM = {
  MAN: "/resources/img/avators/default-bottom-sprite.png",
  WOMAN: "/resources/img/avators/default-bottom-woman-sprite.png",
  NOT_SELECTED: "/resources/img/avators/default-bottom-sprite.png",
};
const DEFAULT_EQUIPEED_SKIN = "/resources/img/avators/default-body-sprite.png";

/**
 * description: 캐릭터 상의+하의 기본 이미지 (여자)
 */
// const DEFAULT_EQUIPEED_WOMAN_HAIR = "/resources/img/avators/default-hair-woman-sprite.png";
// const DEFAULT_EQUIPEED_WOMAN_TOP = "/resources/img/avators/default-top-woman-sprite.png";
// const DEFAULT_EQUIPEED_WOMAN_BOTTOM = "/resources/img/avators/default-bottom-woman-sprite.png";

/**
 * @description 장비 장착 칸 아이콘
 */

const ICON_IMAGE_VIEW = {
  HAT: "/resources/img/avators/inventory-hat.png",
  CAPE: "/resources/img/avators/inventory-cape.png",
  TOP: "/resources/img/avators/inventory-shirt.png",
  BOTTOM: "/resources/img/avators/inventory-pants.png",
  SHOES: "/resources/img/avators/inventory-shoes.png",
  ACCESSORY: "/resources/img/avators/inventory-acc.png",
  NECKLACE: "/resources/img/avators/inventory-necklace.png",
  HAIR: "/resources/img/avators/inventory-hair.png",
  SKIN: "/resources/img/avators/inventory-skin.png",
  FACE: "/resources/img/avators/inventory-face.png",
  FACE_ACCESSORY: "/resources/img/avators/inventory-acc.png",
  HAND_ACCESSORY: "/resources/img/avators/inventory-handacc.png",
};

// const container1DefaultIcon = [
//   {
//     HAT: "/resources/svg/icons/shop/inventory-hat.svg",
//   },
//   {
//     TOP: "/resources/svg/icons/shop/inventory-shirt.svg",
//   },
//   {
//     BOTTOM: "/resources/svg/icons/shop/inventory-pants.svg",
//   },
//   {
//     SHOES: "/resources/svg/icons/shop/inventory-shoes.svg",
//   },
// ];

// /**
//  * @description 악세사리, 목걸이 장착 칸 아이콘
//  */
// const container2DefaultIcon = [
//   {
//     ACC: "/resources/svg/icons/shop/inventory-acc.svg",
//   },
//   {
//     NECKLACE: "/resources/svg/icons/shop/inventory-necklace.svg",
//   },
// ];

/**
 *
 * @description: 메인메뉴, 서브메뉴 한 <--> 영 맵핑
 */
// function translateMenuNameToEnglish(categoryNameInKorean: string): string {
//   const mapping: { [key: string]: string } = {
//     모자: "HAT",
//     얼굴장식: "FACE_ACCESSORY",
//     눈장식: "EYE_ACCESSORY",
//     신발: "SHOES",
//     한벌: "ONE_PIECE",
//     상의: "TOP",
//     하의: "BOTTOM",
//     장갑: "GLOVES",
//     무기: "WEAPON",
//     링: "RING",
//     PC방: "PC_ROOM",
//     망토: "CLOAK",
//     메인: "MAIN",
//     아바타: "AVATAR",
//     쿠폰: "COUPON",
//     기타: "ETC",
//     장비: "EQUIPMENT",
//     소비: "CONSUMPTION",
//     찜목록: "WISH_PRODUCT",
//     헤어: "HAIR",
//     귀걸이: "EARING",
//     얼굴: "FACE",
//   };

//   return mapping[categoryNameInKorean] || categoryNameInKorean;
// }

const INVENTORY_TAB_TITLE = {
  EQUIPMENT: "장비",
  CONSUMPTION: "소비",
  ETC: "기타",
};

/** @description 대분류 카테고리 */
const CATEGORY_MAIN_TITLE = {
  MAIN: "메인",
  AVATAR: "아바타",
  WISH_PRODUCT: "찜목록",
};

/** @description 메인 하위 메뉴 */
const MAIN_CATEGORY_SUB_TITLE = {
  NEWEST: "신상",
  PERIOD: "기간 판매",
  RECOMMENDATIONS: "추천",
  SALE: "할인",
} as const;

/** @description 아바타, 찜목록 하위 메뉴 */
const CATEGORY_SUB_TITLE = {
  TOP: "상의",
  BOTTOM: "하의",
  SHOES: "신발",
  HAIR: "헤어",
  HAT: "모자",
  ONE_PIECE: "한벌",
  FACE: "얼굴",
  FACE_ACCESSORY: "얼굴장식",
  HAND_ACCESSORY: "손장식",
  CAPE: "망토",
  EARRING: "귀걸이",
  SKIN: "피부",
};

export {
  DEFAULT_EQUIPEED_HAIR,
  DEFAULT_EQUIPEED_FACE,
  DEFAULT_EQUIPEED_TOP,
  DEFAULT_EQUIPEED_BOTTOM,
  DEFAULT_EQUIPEED_SKIN,
  // DEFAULT_EQUIPEED_WOMAN_HAIR,
  // DEFAULT_EQUIPEED_WOMAN_TOP,
  // DEFAULT_EQUIPEED_WOMAN_BOTTOM,
  INVENTORY_TAB_TITLE,
  ICON_IMAGE_VIEW,
  MAIN_CATEGORY_SUB_TITLE,
  CATEGORY_SUB_TITLE,
  CATEGORY_MAIN_TITLE,
};
