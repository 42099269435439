import { Howl } from "howler";
import {
  CLEAR_MUSIC,
  LOAD_MUSIC,
  SET_ABSOLUTE_VOLUME,
  SET_CURRENT_INDEX,
  SET_IS_READY,
  SET_MUSIC_STATE,
  SET_QUEUE,
  SET_SEEK,
  SET_VOLUME,
} from "../actions/music";

export interface MusicData {
  theme: string;
  src: string;
}

export interface MusicInitialState {
  player: Howl;
  data: MusicData[];
  theme: string;
  seek: number;
  duration: number;
  absoluteVolume: number;
  volume: number;
  currentIndex: number;
  state: MusicState;
  queue: {
    data: MusicData[];
  };
  isReady: boolean;
}

type MusicState =
  | "PLAYING"
  | "FADE_PLAY"
  | "PAUSE"
  | "FADE_PAUSE"
  | "TRANSITIONING"
  | "TRANSITION_PAUSE";

const initialState: MusicInitialState = {
  player: null,
  data: null,
  theme: null,
  seek: 0,
  duration: 0,
  absoluteVolume: 0.5,
  volume: 1,
  currentIndex: 0,
  state: "PAUSE",
  queue: {
    data: null,
  },
  isReady: false,
};

export const actionLoadMusic = (
  player: Howl,
  data: MusicData[],
  theme: string,
  seek: number,
  duration: number,
) => ({
  type: LOAD_MUSIC,
  player,
  data,
  theme,
  seek,
  duration,
});

export const actionSetIsReady = (isReady: boolean) => ({
  type: SET_IS_READY,
  isReady,
});

export const actionSetSeek = (seek: number) => ({
  type: SET_SEEK,
  seek,
});

export const actionSetMusicState = (state: MusicState) => ({
  type: SET_MUSIC_STATE,
  state,
});

export const actionSetCurrentIndex = (currentIndex: number) => ({
  type: SET_CURRENT_INDEX,
  currentIndex,
});

export const actionSetAbsoluteVolume = (absoluteVolume: number) => ({
  type: SET_ABSOLUTE_VOLUME,
  absoluteVolume,
});

export const actionSetVolume = (volume: number) => ({
  type: SET_VOLUME,
  volume,
});

export const actionSetQueue = (data: MusicData[]) => ({
  type: SET_QUEUE,
  data,
});

export const actionClearMusic = () => ({
  type: CLEAR_MUSIC,
});

const music = (state = initialState, action: any) => {
  switch (action.type) {
    case LOAD_MUSIC:
      return {
        ...state,
        player: action.player,
        data: action.data,
        theme: action.theme,
        seek: action.seek,
        duration: action.duration,
      };
    case SET_IS_READY:
      return {
        ...state,
        isReady: action.isReady,
      };
    case SET_SEEK:
      return {
        ...state,
        seek: action.seek,
      };
    case SET_MUSIC_STATE:
      return {
        ...state,
        state: action.state,
      };
    case SET_CURRENT_INDEX:
      return {
        ...state,
        currentIndex: action.currentIndex,
      };
    case SET_ABSOLUTE_VOLUME:
      return {
        ...state,
        absoluteVolume: action.absoluteVolume,
      };
    case SET_VOLUME:
      return {
        ...state,
        volume: action.volume,
      };
    case SET_QUEUE:
      return {
        ...state,
        queue: {
          data: action.data,
        },
      };
    case CLEAR_MUSIC:
      return {
        ...initialState,
        absoluteVolume: state.absoluteVolume,
        queue: state.queue,
      };
    default:
      return state;
  }
};

export default music;
