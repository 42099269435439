import useAnimation from "../../../hooks/useAnimation";
import { ConfettiAnimation } from "../../../animations/confetti";
import { styles } from "./styles";
import { useEffect, useRef } from "react";

const AnimationConfetti = () => {
  const animation = useAnimation();

  const canvasElRef = useRef<HTMLCanvasElement>(null);
  const confettiAnimationRef = useRef<ConfettiAnimation>(null);
  const containerElRef = useRef<HTMLDivElement>(null);

  const closeAnimation = () => {
    containerElRef.current.classList.remove("show");

    setTimeout(() => {
      animation.confetti.close();
    }, 150);
  };

  // Animation init.
  useEffect(() => {
    confettiAnimationRef.current = new ConfettiAnimation(canvasElRef.current);

    // Clean Up
    return () => {
      if (confettiAnimationRef.current) {
        confettiAnimationRef.current.destroy();
      }
    };
  }, []);

  return (
    <div ref={containerElRef} css={styles.container} className="fade show" onClick={closeAnimation}>
      <canvas ref={canvasElRef} css={styles.canvas} />
    </div>
  );
};

export default AnimationConfetti;
