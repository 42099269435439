import { QuestList } from "../../api/quest";
import {
  SET_NOTICE_CONTENT,
  SET_NOTICE_ONCE,
  SET_NOTICE_VISIBLE,
  SET_PREMIUM_REQIRED_VISIBLE,
  SET_TUTORIAL_VISIBLE,
  SET_HEADER_QUEST_POPUP,
  SET_QUEST_QUEUE,
  SET_QUEST_VISIBLE,
  SET_RECOMMEND_QUEST_ONCE,
  SET_RECOMMEND_QUEST_VISIBLE,
  SET_REWARD_CONTENT,
  SET_REWARD_VISIBLE,
  SET_SIGNUP_REQIRED_VISIBLE,
  SET_USER_DETAIL_VISIBLE,
  SET_CLICK_USER_VISIBLE,
  SET_FRIENDSHIP_VISIBLE,
} from "../actions/popup";

export interface PopupInitialState {
  notice: {
    once: boolean;
    content: {
      title: string;
      content: string;
      link: string;
    };
    visible: boolean;
  };
  userDetail: {
    userId: string;
    visible: boolean;
  };
  friendship: {
    visible: boolean;
  };
  clickUser: {
    userId: string;
    nickname: string;
    top: number;
    left: number;
    visible: boolean;
  };
  premiumReqired: {
    visible: boolean;
  };
  tutorial: {
    visible: boolean;
  };
  reward: {
    content: {
      description: string;
      amount: number;
    };
    visible: boolean;
  };
  recommendQuest: {
    once: boolean;
    visible: boolean;
  };
  quest: {
    queue: QuestList[];
    visible: boolean;
  };
  headerQuestPopup: boolean;
}

const initialState: PopupInitialState = {
  notice: {
    once: false,
    content: {
      title: "",
      content: "",
      link: "",
    },
    visible: false,
  },
  userDetail: {
    userId: null,
    visible: false,
  },
  friendship: {
    visible: false,
  },
  clickUser: {
    top: 0,
    left: 0,
    userId: null,
    nickname: null,
    visible: false,
  },
  premiumReqired: {
    visible: false,
  },
  tutorial: {
    visible: false,
  },
  reward: {
    content: {
      description: "",
      amount: null,
    },
    visible: false,
  },
  recommendQuest: {
    once: false,
    visible: false,
  },
  quest: {
    queue: [],
    visible: false,
  },
  headerQuestPopup: false,
};

export const actionSetNoticeOnce = () => ({
  type: SET_NOTICE_ONCE,
});

export const actionSetNoticeVisible = (visible: boolean) => ({
  type: SET_NOTICE_VISIBLE,
  visible,
});

export const actionSetNoticeContent = (content: {
  title: string;
  content: string;
  link: string;
}) => ({
  type: SET_NOTICE_CONTENT,
  content,
});

export const actionSetClickUserVisible = (
  userId: string,
  nickname: string,
  left: number,
  top: number,
  visible: boolean,
) => ({
  type: SET_CLICK_USER_VISIBLE,
  userId,
  nickname,
  left,
  top,
  visible,
});

export const actionSetUserDetailVisible = (userId: string, visible: boolean) => ({
  type: SET_USER_DETAIL_VISIBLE,
  userId,
  visible,
});

export const actionSetFriendshipVisible = (visible: boolean) => ({
  type: SET_FRIENDSHIP_VISIBLE,
  visible,
});

export const actionSetPremiumReqiredVisible = (visible: boolean) => ({
  type: SET_PREMIUM_REQIRED_VISIBLE,
  visible,
});

export const actionSetTutorialVisible = (visible: boolean) => ({
  type: SET_TUTORIAL_VISIBLE,
  visible,
});

export const actionSetRewardVisible = (visible: boolean) => ({
  type: SET_REWARD_VISIBLE,
  visible,
});

export const actionSetRewardContent = (content: { description: string; amount: number }) => ({
  type: SET_REWARD_CONTENT,
  content,
});

export const actionSetRecommendQuestOnce = () => ({
  type: SET_RECOMMEND_QUEST_ONCE,
});

export const actionSetRecommendQuestVisible = (visible: boolean) => ({
  type: SET_RECOMMEND_QUEST_VISIBLE,
  visible,
});

export const actionSetQuestQueue = (questList: QuestList[]) => ({
  type: SET_QUEST_QUEUE,
  questList,
});

export const actionSetQuestVisible = (visible: boolean) => ({
  type: SET_QUEST_VISIBLE,
  visible,
});

export const actionSetHeaderQuestPopup = (visible: boolean) => ({
  type: SET_HEADER_QUEST_POPUP,
  visible,
});

const popup = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTICE_ONCE:
      return {
        ...state,
        notice: {
          ...state.notice,
          once: true,
        },
      };
    case SET_NOTICE_VISIBLE:
      return {
        ...state,
        notice: {
          ...state.notice,
          visible: action.visible,
        },
      };
    case SET_NOTICE_CONTENT:
      return {
        ...state,
        notice: {
          ...state.notice,
          content: action.content,
        },
      };
    case SET_USER_DETAIL_VISIBLE:
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          userId: action.userId,
          visible: action.visible,
        },
      };

    case SET_FRIENDSHIP_VISIBLE:
      return {
        ...state,
        friendship: {
          ...state.friendship,
          visible: action.visible,
        },
      };
    case SET_CLICK_USER_VISIBLE:
      return {
        ...state,
        clickUser: {
          ...state.clickUser,
          left: action.left,
          top: action.top,
          userId: action.userId,
          nickname: action.nickname,
          visible: action.visible,
        },
      };

    case SET_PREMIUM_REQIRED_VISIBLE:
      return {
        ...state,
        premiumReqired: {
          ...state.premiumReqired,
          visible: action.visible,
        },
      };
    case SET_TUTORIAL_VISIBLE:
      return {
        ...state,
        tutorial: {
          ...state.tutorial,
          visible: action.visible,
        },
      };
    case SET_REWARD_VISIBLE:
      return {
        ...state,
        reward: {
          ...state.reward,
          visible: action.visible,
        },
      };
    case SET_REWARD_CONTENT:
      return {
        ...state,
        reward: {
          ...state.reward,
          content: action.content,
        },
      };
    case SET_RECOMMEND_QUEST_ONCE:
      return {
        ...state,
        recommendQuest: {
          ...state.recommendQuest,
          once: true,
        },
      };
    case SET_RECOMMEND_QUEST_VISIBLE:
      return {
        ...state,
        recommendQuest: {
          ...state.recommendQuest,
          visible: action.visible,
        },
      };
    case SET_QUEST_QUEUE:
      return {
        ...state,
        quest: {
          ...state.quest,
          queue: action.questList,
        },
      };
    case SET_QUEST_VISIBLE:
      return {
        ...state,
        quest: {
          ...state.quest,
          visible: action.visible,
        },
      };
    case SET_HEADER_QUEST_POPUP:
      return {
        ...state,
        headerQuestPopup: action.visible,
      };

    default:
      return state;
  }
};

export default popup;
