import usePopup from "../../../hooks/usePopup";
import { CLIENT_URL } from "../../../constants/urls";
import { formatAmount } from "common/utils/format";
import { Fragment, useEffect, useRef } from "react";
import { styles } from "./styles";

const PopupPremiumReqired = () => {
  const popup = usePopup();

  const containerElRef = useRef<HTMLDivElement>(null);

  const purchase = () => {
    containerElRef.current.classList.remove("animate__bounceIn");
    containerElRef.current.classList.add("animate__bounceOut");

    setTimeout(() => {
      popup.premiumReqired.close();

      window.location.href = `${CLIENT_URL}/purchase/product`;
    }, 500);
  };

  const handleClose = () => {
    containerElRef.current.classList.remove("animate__bounceIn");
    containerElRef.current.classList.add("animate__bounceOut");

    setTimeout(() => {
      popup.premiumReqired.close();
    }, 500);
  };

  useEffect(() => {
    if (!popup.premiumReqired.visible) {
      return;
    }

    containerElRef.current.classList.add("animate__bounceIn");
    containerElRef.current.classList.add("animate__faster");
  }, [popup.premiumReqired.visible]);

  return (
    <Fragment>
      {popup.premiumReqired.visible && (
        <Fragment>
          <div css={styles.backdrop} onClick={handleClose}></div>
          <div css={styles.wrapper}>
            <div
              ref={containerElRef}
              css={styles.container}
              className="border bg-white rounded-2 shadow-lg animate__animated"
            >
              <div css={styles.heading}>
                <div css={styles.icon} className="bg-warning rounded-circle">
                  <i className="bi bi-rocket-takeoff m-0"></i>
                </div>
                <h5 css={styles.title}>Premium 구독이 필요합니다.</h5>
                {/* 기능 정리 */}
                {/* <p css={styles.description} className="m-0">
                  구독 가격
                  <br />
                  <span className="opacity-50">일반 유저를 제외한 정회원 수</span> &times;{" "}
                  <span className="text-primary">
                    <strong>{formatAmount(5000)}원</strong>
                  </span>
                </p> */}
              </div>
              <button
                type="button"
                css={styles.button}
                className="btn btn-light border animate__animated animate__pulse animate__slow animate__infinite"
                onClick={purchase}
              >
                구매하기
              </button>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default PopupPremiumReqired;
