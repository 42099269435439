import { QuestList } from "../../api/quest";
import {
  CLEAR_QUEST,
  SET_IS_READY,
  SET_MAIN_PAGINATION,
  SET_MAIN_QUEST,
  SET_SUB_PAGINATION,
  SET_SUB_QUEST,
} from "../actions/quest";

export type QuestType = "MAIN" | "SUB";

export interface QuestPagination {
  itemsPerPage: number;
  total: number;
  currentPage: number;
}

export interface QuestInitialState {
  main: {
    questList: QuestList[];
    pagination: QuestPagination;
  };
  sub: {
    questList: QuestList[];
    pagination: QuestPagination;
  };
  isReady: boolean;
}

const initialState: QuestInitialState = {
  main: {
    questList: [],
    pagination: {
      itemsPerPage: 0,
      total: 0,
      currentPage: 1,
    },
  },
  sub: {
    questList: [],
    pagination: {
      itemsPerPage: 0,
      total: 0,
      currentPage: 1,
    },
  },
  isReady: false,
};

export const actionSetMainQuest = (questList: QuestList[]) => ({
  type: SET_MAIN_QUEST,
  questList,
});

export const actionSetMainPagination = (pagination: QuestPagination) => ({
  type: SET_MAIN_PAGINATION,
  pagination,
});

export const actionSetSubQuest = (questList: QuestList[]) => ({
  type: SET_SUB_QUEST,
  questList,
});

export const actionSetSubPagination = (pagination: QuestPagination) => ({
  type: SET_SUB_PAGINATION,
  pagination,
});

export const actionSetIsReady = (isReady: boolean) => ({
  type: SET_IS_READY,
  isReady,
});

export const actionClearQuest = () => ({
  type: CLEAR_QUEST,
});

const quest = (state = initialState, action) => {
  switch (action.type) {
    case SET_MAIN_QUEST:
      return {
        ...state,
        main: {
          ...state.main,
          questList: action.questList,
        },
      };
    case SET_MAIN_PAGINATION:
      return {
        ...state,
        main: {
          ...state.main,
          pagination: action.pagination,
        },
      };
    case SET_SUB_QUEST:
      return {
        ...state,
        sub: {
          ...state.sub,
          questList: action.questList,
        },
      };
    case SET_SUB_PAGINATION:
      return {
        ...state,
        sub: {
          ...state.sub,
          pagination: action.pagination,
        },
      };
    case SET_IS_READY:
      return {
        ...state,
        isReady: action.isReady,
      };
    case CLEAR_QUEST:
      return initialState;
    default:
      return state;
  }
};

export default quest;
