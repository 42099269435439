export interface Store {
  target: "music";
  state?: "PLAY" | "STOP";
  volume?: number;
}

export interface ConfigStorage {
  music: {
    state: "PLAY" | "STOP";
    volume: number;
  };
}

const FORMAT: ConfigStorage = {
  music: {
    state: "STOP",
    volume: 0.5,
  },
};

/**
 * 로컬 스토리지에서 설정 정보 가져오기
 */
export const getConfigStorage = () => {
  if (!localStorage.getItem("config")) {
    return null;
  }

  return JSON.parse(localStorage.getItem("config"));
};

/**
 * 로컬 스토리지에 설정 정보 저장
 */
export const saveConfigStorage = (store: Store) => {
  let configStorage: ConfigStorage = {
    ...(getConfigStorage() || FORMAT),
  };

  switch (store.target) {
    case "music":
      if (typeof store.state !== "undefined") {
        configStorage.music.state = store.state;
      }

      if (typeof store.volume !== "undefined") {
        configStorage.music.volume = store.volume;
      }
      break;
    default:
  }

  localStorage.setItem("config", JSON.stringify(configStorage));
};

/**
 * 로컬 스토리지 관리
 * 배포 후 로컬 스토리지 초기화
 */
export const manageStorage = (version: string) => {
  if (typeof window === "undefined") {
    return;
  }

  if (!localStorage.getItem("version")) {
    localStorage.clear();
    localStorage.setItem("version", version);
    return;
  }

  if (version !== localStorage.getItem("version")) {
    localStorage.clear();
  }
};
