import useToast from "common/hooks/useToast";
import { Fragment, useEffect, useRef } from "react";
import { styles } from "./styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ToastNotification = () => {
  const toast = useToast();

  const toastElRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (toast.isReady) {
      return;
    }

    toast.init(toastElRef.current);
  }, [toast.isReady]);

  return (
    <Fragment>
      <div css={styles.container}>
        <div ref={toastElRef} css={styles.toast} className={`toast bg-${toast.theme}`}>
          <div css={styles.body}>
            <div className="toast-body">
              <span
                className={`${
                  toast.theme === "primary" || toast.theme === "danger" ? "text-white" : ""
                }`}
              >
                {toast.content}
              </span>
            </div>
            <button
              type="button"
              css={styles.button}
              className={`btn-close ${
                toast.theme === "primary" || toast.theme === "danger" ? "btn-close-white" : ""
              }`}
              data-bs-dismiss="toast"
            />
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default ToastNotification;
