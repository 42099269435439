import useAuth from "../../hooks/useAuth";
import useNotification from "../../hooks/useNotification";
import { AxiosError } from "axios";
import { getNotification, GetNotificationResponse } from "../../api/notification";
import { useEffect } from "react";
// import useRoom from "../../hooks/useRoom";

const NotificationFetcher = () => {
  const auth = useAuth();
  const notification = useNotification();
  // const room = useRoom(null);

  useEffect(() => {
    if (!auth.isReady || auth.state !== "VALID" || notification.isDataReady) {
      return;
    }

    getNotification({ params: { page: 1 } })
      .then((response: GetNotificationResponse) => {
        notification.appendData(response.notificationList);
        notification.setPagination({
          itemsPerPage: response.pageCount,
          total: response.totalCount,
          currentPage: 1,
        });
      })
      .catch((error: AxiosError) => {});
  }, [auth.isReady, notification.isDataReady]);

  // 페이지네이션
  useEffect(() => {
    if (!notification.isDataReady || notification.pagination.currentPage === 1) {
      return;
    }

    getNotification({ params: { page: notification.pagination.currentPage } })
      .then((response: GetNotificationResponse) => {
        notification.appendData(response.notificationList);
      })
      .catch((error: AxiosError) => {});
  }, [notification.pagination.currentPage, notification.isDataReady]);

  return null;
};

export default NotificationFetcher;
