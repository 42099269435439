import { RootReducer } from "../modules/reducers";
import { useDispatch, useSelector } from "react-redux";
import {
  PopupInitialState,
  actionSetNoticeContent,
  actionSetNoticeOnce,
  actionSetNoticeVisible,
  actionSetPremiumReqiredVisible,
  actionSetTutorialVisible,
  actionSetQuestQueue,
  actionSetQuestVisible,
  actionSetRecommendQuestOnce,
  actionSetRecommendQuestVisible,
  actionSetRewardContent,
  actionSetRewardVisible,
  actionSetUserDetailVisible,
  actionSetClickUserVisible,
  actionSetHeaderQuestPopup,
  actionSetFriendshipVisible,
  // actionSetSignupReqiredVisible,
} from "../modules/reducers/popup";
import { QuestList } from "../api/quest";

const usePopup = () => {
  const dispatch = useDispatch();

  const popupState: PopupInitialState = useSelector((state: RootReducer) => state.popupReducer);

  const notice = {
    show: (content: { title: string; content: string; link: string }) => {
      dispatch(actionSetNoticeOnce());
      dispatch(actionSetNoticeContent(content));
      dispatch(actionSetNoticeVisible(true));
    },
    close: () => {
      dispatch(actionSetNoticeVisible(false));
    },
  };

  const userDetail = {
    show: (userId: string) => {
      dispatch(actionSetUserDetailVisible(userId, true));
    },

    close: () => {
      dispatch(actionSetUserDetailVisible(null, false));
    },
  };

  const friendship = {
    show: () => {
      dispatch(actionSetFriendshipVisible(true));
    },
    close: () => {
      dispatch(actionSetFriendshipVisible(false));
    },
  };

  const clickUser = {
    show: (userId: string, nickname: string, left: number, top: number) => {
      dispatch(actionSetClickUserVisible(userId, nickname, left, top, true));
    },
    close: () => {
      dispatch(actionSetClickUserVisible(null, null, 0, 0, false));
    },
  };

  const premiumReqired = {
    show: () => {
      dispatch(actionSetPremiumReqiredVisible(true));
    },
    close: () => {
      dispatch(actionSetPremiumReqiredVisible(false));
    },
  };

  const tutorial = {
    show: () => {
      dispatch(actionSetTutorialVisible(true));
    },
    close: () => {
      dispatch(actionSetTutorialVisible(false));
    },
  };
  const reward = {
    show: (content: { description: string; amount: number }) => {
      dispatch(actionSetRewardContent(content));
      dispatch(actionSetRewardVisible(true));
    },
    close: () => {
      dispatch(actionSetRewardVisible(false));
    },
  };

  const recommendQuest = {
    show: () => {
      dispatch(actionSetRecommendQuestOnce());
      dispatch(actionSetRecommendQuestVisible(true));
    },
    close: () => {
      dispatch(actionSetRecommendQuestVisible(false));
    },
  };

  const quest = {
    setQueue: (questList: QuestList[]) => {
      dispatch(actionSetQuestQueue(questList));
    },
    show: () => {
      if (popupState.quest.queue.length === 0) {
        return;
      }

      dispatch(actionSetQuestVisible(true));
    },
    showMiniQuest: () => {
      dispatch(actionSetHeaderQuestPopup(true));
    },
    close: () => {
      dispatch(actionSetQuestVisible(false));
    },
  };

  return {
    notice: {
      ...popupState.notice,
      ...notice,
    },
    clickUser: {
      ...popupState.clickUser,
      ...clickUser,
    },
    userDetail: {
      ...popupState.userDetail,
      ...userDetail,
    },
    friendship: {
      ...popupState.friendship,
      ...friendship,
    },
    premiumReqired: {
      ...popupState.premiumReqired,
      ...premiumReqired,
    },
    tutorial: {
      ...popupState.tutorial,
      ...tutorial,
    },
    reward: {
      ...popupState.reward,
      ...reward,
    },
    recommendQuest: {
      ...popupState.recommendQuest,
      ...recommendQuest,
    },
    quest: {
      ...popupState.quest,
      ...quest,
    },
  };
};

export default usePopup;
