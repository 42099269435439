import ReactGA from "react-ga4";
import useAuth from "../../hooks/useAuth";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useChannel from "../../hooks/useChannel";
import { getSubDomain } from "../../utils/domain";
import { isClient } from "common/utils/common";

const RouteChangeTracker = () => {
  const auth = useAuth();
  const location = useLocation();
  const channel = useChannel();

  const [isReady, setIsReady] = useState<boolean>(false);

  const subDomain = isClient() ? getSubDomain() : null;

  useEffect(() => {
    if (!auth.isReady) {
      return;
    }

    // 운영 중인 서버에만 Google Analytics 분석 시작
    if (!window.location.hostname.includes("eduenv.com")) {
      return;
    }

    ReactGA.initialize(
      !subDomain
        ? process.env.GOOGLE_ANALYTICS_TRACKING_ID
        : channel.pluginMap?.GOOGLE_ANALYTICS_4?.pluginKey
          ? channel.pluginMap?.GOOGLE_ANALYTICS_4?.pluginKey
          : process.env.GOOGLE_ANALYTICS_TRACKING_ID,
      {
        ...(auth.state !== "INVALID" && {
          gtagOptions: {
            user_id: auth.userId,
          },
        }),
      },
    );

    // 사용자 ID를 이벤트로 전송
    if (auth.state !== "INVALID") {
      ReactGA.gtag("event", "bind_user", {
        bind_user_id: auth.userId,
      });
    }

    setIsReady(true);
  }, [auth.isReady, channel.isReady]);

  useEffect(() => {
    if (!isReady) {
      return;
    }

    // 페이지 정보를 이벤트로 전송
    ReactGA.gtag("event", "bind_page_view", {
      bind_page_path: location.pathname + location.search,
    });

    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [isReady, location.key]);

  return null;
};

export default RouteChangeTracker;
