import Cookies from "js-cookie";
import useChannel from "./useChannel";
import { AxiosError } from "axios";
import { getSubDomain } from "../utils/domain";
import { isClient } from "common/utils/common";
import { RootReducer } from "../modules/reducers";
import { useDispatch, useSelector } from "react-redux";
import {
  ChannelDto,
  Gender,
  GetUserChannelRoleResponse,
  GetUserInfoResponse,
  RoleList,
  SignupPath,
  SubscriptionList,
  UserState,
  VerifiedState,
  getUserChannelRole,
  getUserInfo,
} from "../api/auth";
import {
  UserInitialState,
  actionClearUser,
  actionSetPortrait,
  actionSetUser,
  actionSetVerifiedState,
} from "../modules/reducers/user";

interface CookieOption {
  accessToken?: string;
  refreshId?: string;
}

const useAuth = () => {
  const dispatch = useDispatch();

  const channel = useChannel();

  const subDomain = isClient() ? getSubDomain() : null;

  const {
    userId,
    level,
    roleList,
    subscriptionList,
    name,
    nickname,
    channelNickname,
    email,
    portrait,
    verifiedState,
    state,
    signupPath,
    isReady,
    gender,
    channelProfile,
  }: UserInitialState = useSelector((state: RootReducer) => state.userReducer);

  // accessToken, refreshId를 쿠키에 저장
  const saveCookie = (store: CookieOption) => {
    if (store?.accessToken) {
      Cookies.set("accessToken", store.accessToken, { domain: `.${process.env.CLIENT_HOST}` });
    }

    if (store?.refreshId) {
      Cookies.set("refreshId", store.refreshId, { domain: `.${process.env.CLIENT_HOST}` });
    }
  };

  // accessToken, refreshId를 쿠키에서 제거
  const removeCookie = () => {
    Cookies.remove("accessToken");
    Cookies.remove("refreshId");

    Cookies.remove("accessToken", { domain: `.${process.env.CLIENT_HOST}` });
    Cookies.remove("refreshId", { domain: `.${process.env.CLIENT_HOST}` });
  };

  const setUserState = (
    userId: string,
    level: number,
    roleList: RoleList[],
    subscriptionList: SubscriptionList[],
    name: string,
    nickname: string,
    channelNickname: string,
    email: string,
    portrait: string,
    verifiedState: VerifiedState,
    state: UserState,
    signupPath: SignupPath,
    gender: Gender,
    channelProfile: ChannelDto,
  ) => {
    dispatch(
      actionSetUser(
        userId,
        level,
        roleList,
        subscriptionList,
        name,
        nickname,
        channelNickname,
        email,
        portrait,
        verifiedState,
        state,
        signupPath,
        gender,
        channelProfile,
      ),
    );
  };

  const isValidCookie = () => {
    return Cookies.get("accessToken") && Cookies.get("refreshId");
  };

  const invalid = () => {
    removeCookie();
    setUserState(
      "",
      1,
      [],
      [],
      "",
      "",
      "",
      "",
      "",
      "UNVERIFIED",
      "INVALID",
      null,
      "NOT_SELECTED",
      null,
    );
  };

  /**
   * 인증 여부 확인
   * 쿠키에 저장되어 있는 accessToken이 유효한지 확인
   */
  const init = async () => {
    if (isReady) {
      return;
    }

    const isValid = isValidCookie();

    if (!isValid) {
      invalid(); // isReady 값이 true가 됨
      return;
    }

    let result: any = {};

    // 사용자 정보 동기화
    await getUserInfo()
      .then((response: GetUserInfoResponse) => {
        const {
          userId,
          level,
          userRoleList,
          subscribeList,
          name,
          nickname,
          email,
          profile,
          verifiedState,
          state,
          signupPath,
          gender,
          channelProfile,
        } = response;

        result = {
          userId,
          level,
          userRoleList,
          subscribeList,
          name,
          nickname,
          email,
          profile: profile || "/resources/img/icon_portrait.png",
          verifiedState,
          state,
          signupPath,
          gender,
          channelProfile,
        };
      })
      .catch((error: AxiosError) => {
        invalid();
        return Promise.reject(error);
      });

    // 채널 > 사용자 등급 동기화
    if (subDomain) {
      await getUserChannelRole(channel.info.id)
        .then((response: GetUserChannelRoleResponse) => {
          result.userRoleList = [...result.userRoleList, response.channelMemberRole];
          result.channelNickname = response.channelNickname;
        })
        .catch((error: AxiosError) => {
          invalid();
          return Promise.reject(error);
        });
    }

    setUserState(
      result.userId,
      result.level,
      result.userRoleList,
      result.subscribeList,
      result.name,
      result.nickname,
      result.channelNickname,
      result.email,
      result.profile,
      result.verifiedState,
      result.state,
      result.signupPath,
      result.gender,
      result.channelProfile,
    );
  };

  const setAccessToken = (accessToken: string) => {
    saveCookie({ accessToken });
  };

  const setRefreshId = (refreshId: string) => {
    saveCookie({ refreshId });
  };

  const updatePortrait = (portrait: string) => {
    dispatch(actionSetPortrait(portrait));
  };

  const updateVerifiedState = (verifiedState: VerifiedState) => {
    dispatch(actionSetVerifiedState(verifiedState));
  };

  const clear = () => {
    removeCookie();
    dispatch(actionClearUser());
  };

  return {
    userId,
    level,
    roleList,
    subscriptionList,
    name,
    nickname,
    channelNickname,
    email,
    portrait,
    verifiedState,
    state,
    signupPath,
    isReady,
    gender,
    channelProfile,
    init,
    setAccessToken: (accessToken: string) => {
      setAccessToken(accessToken);
    },
    setRefreshId: (refreshId: string) => {
      setRefreshId(refreshId);
    },
    setUserState: (
      userId: string,
      level: number,
      roleList: RoleList[],
      subscriptionList: SubscriptionList[],
      name: string,
      nickname: string,
      channelNickname: string,
      email: string,
      portrait: string,
      verifiedState: VerifiedState,
      state: UserState,
      signupPath: SignupPath,
      gender: Gender,
    ) => {
      setUserState(
        userId,
        level,
        roleList,
        subscriptionList,
        name,
        nickname,
        channelNickname,
        email,
        portrait,
        verifiedState,
        state,
        signupPath,
        gender,
        channelProfile,
      );
    },
    updatePortrait: (portrait: string) => {
      updatePortrait(portrait);
    },
    updateVerifiedState: (verifiedState: VerifiedState) => {
      updateVerifiedState(verifiedState);
    },
    invalid,
    clear,
  };
};

export default useAuth;
