import { ChangeEvent, useEffect, useRef, useState } from "react";
import * as S from "./styles";
import { Modal } from "bootstrap";
import { isClient } from "common/utils/common";
import { getUserLevelColor } from "common/utils/common";
import { css } from "@emotion/react";
import { TextareaInputState } from "../../ui/TextareaInput/TextareaInput";
import { camelToKebab } from "common/utils/format";
import useToast from "common/hooks/useToast";
import { AxiosError } from "axios";
import { v4 } from "uuid";

import useAuth from "../../../hooks/useAuth";
import Avator from "../../shop/avator";

import {
  EquippedItemForProfile,
  GetUserProfileInfoResponse,
  getUserProfilePageInfo,
} from "../../../api/user";

import { requestFriendship } from "../../../api/friendship";
import { useTranslation } from "react-i18next";
import { ItemCategory } from "common/hooks/useAvator";
import { ServerError } from "../../../constants/errors";
import Swal, { SweetAlertResult } from "sweetalert2";

interface UserDetailProfileProps {
  userId: string;
  onClose: () => void;
  userInfo: GetUserProfileInfoResponse;
  equippedMap: Map<ItemCategory, EquippedItemForProfile>;
  isLoaded: boolean;
}

export default function UserDetailProfile({
  userId,
  onClose,
  userInfo,
  equippedMap,
  isLoaded,
}: UserDetailProfileProps) {
  const { t } = useTranslation();

  const modalElRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<Modal>(null);
  const toast = useToast();

  const id = v4();
  const [requestState, setRequestState] = useState(false);

  const handleRequestFriend = () => {
    setRequestState(true);
    requestFriendship({ userId: userId })
      .then((response) => {
        toast.notification("success", `친구 요청이 완료되었습니다.`).show();
        setRequestState(false);
      })
      .catch((error: AxiosError<ServerError>) => {
        switch (error.response.data.errorCode) {
          case "ALREADY_FRIEND_USER":
            toast.notification("success", `이미 친구입니다.`).show();
            setRequestState(false);
            break;
          default:
        }
      });
  };

  useEffect(() => {
    if (isClient()) {
      import("bootstrap").then(({ Modal }) => {
        modalRef.current = new Modal(modalElRef.current);

        modalRef.current.show();
      });
    }

    modalElRef.current.addEventListener("hidden.bs.modal", () => {
      onClose();
    });
  }, []);

  return (
    <div
      ref={modalElRef}
      css={css`
        z-index: 70 !important;

        .modal-header {
          position: relative;
          padding: 0.75rem 0.75rem 1.5rem 0.75rem;
        }

        .modal-title {
          position: absolute;
          top: 1.25rem;
          left: 50%;
          transform: translateX(-50%);
        }

        .btn-close {
          z-index: 10;
        }

        .modal-body {
          padding: 0.9375rem;
        }

        .modal-footer {
          padding: 1.25rem;
        }
      `}
      className="modal fade"
    >
      <S.ModalWapp className="modal-dialog  modal-dialog-centered modal-dialog-scrollable modal-lg">
        <S.Wrapper className="modal-content shadow">
          <S.Header className="">
            <button
              type="button"
              onClick={() => {
                requestFriendship({ userId: userId })
                  .then((response) => {
                    toast.notification("success", `친구 요청 중 입니다.`).show();
                  })
                  .catch((error: AxiosError<ServerError>) => {
                    switch (error.response.data.errorCode) {
                      case "ALREADY_FRIEND_USER":
                        toast.notification("success", `이미 친구입니다.`).show();
                        break;
                      default:
                    }
                  });
              }}
            >
              <img src="/resources/svg/icons/shop/avatar-user-detail-add.svg" alt="add" />
            </button>
            <span>유저 정보</span>
            <button type="button" data-bs-dismiss="modal">
              <img
                src="/resources/svg/icons/shop/avatar-user-detail-close.svg"
                alt="add"
                onClick={onClose}
              />
            </button>
          </S.Header>
          <S.Avatar>
            <S.AvatarInner />
            <Avator
              earringImage={equippedMap.get("EARRING")?.spriteStand1}
              topImage={equippedMap.get("TOP")?.spriteStand1}
              capeImage={equippedMap.get("CAPE")?.spriteStand1}
              onePieceImage={equippedMap.get("ONE_PIECE")?.spriteStand1}
              bottomImage={equippedMap.get("BOTTOM")?.spriteStand1}
              shoesImage={equippedMap.get("SHOES")?.spriteStand1}
              hatImage={equippedMap.get("HAT")?.spriteStand1}
              hairImage={equippedMap.get("HAIR")?.spriteStand1}
              accImage={equippedMap.get("ACCESSORY")?.spriteStand1}
              faceImage={equippedMap.get("FACE")?.spriteStand1}
              faceAccessoryImage={equippedMap.get("FACE_ACCESSORY")?.spriteStand1}
              handAccessoryImage={equippedMap.get("HAND_ACCESSORY")?.spriteStand1}
              skinImage={equippedMap.get("SKIN")?.spriteStand1}
              gender={userInfo.gender}
              isSetEquipped={isLoaded}
            />
          </S.Avatar>

          <S.ContentWrap className="modal-body">
            <S.DetailInfo>
              <S.DetailInfoTitle>
                <img
                  src={userInfo.profile || "/resources/img/icon_portrait.png"}
                  alt="profile"
                  style={{
                    marginLeft: "10px",
                    width: "30px",
                    borderRadius: "50%",
                  }}
                />
                <h4>{userInfo.nickname || "-"}</h4>
                <span
                  className="badge rounded-pill"
                  css={css`
                    background: ${getUserLevelColor(userInfo.level)};
                  `}
                >
                  Lv.{userInfo.level}
                </span>
              </S.DetailInfoTitle>
              {/* <S.DetailInfoItem>
                <p>전공</p>
                <p>컴퓨터공학</p>
              </S.DetailInfoItem> */}
              {/* <S.DetailInfoItem>
                <p>인기도</p>
                <p>+ 15</p>
              </S.DetailInfoItem> */}
              <S.DetailInfoItem>
                <p>대표채널</p>
                {/* <p>{auth?.channelProfile?.channelName || "-"}</p> */}
                <div css={[S.styles.form, S.styles.select]}>
                  {/* <button
                    type="button"
                    css={S.styles.dropdown}
                    className="btn border-0 bg-light"
                    data-bs-toggle="dropdown"
                  > */}
                  {userInfo.channelProfile
                    ? userInfo.channelProfile.channelName
                    : "대표 채널이 없습니다."}
                  {/* <i className="bi bi-chevron-down m-0"></i> */}
                  {/* </button> */}
                  {/* <ul className="dropdown-menu border-0 p-0 shadow">
                    {myChannels.map((channel) => (
                      <li key={channel.channelId}>
                        <button
                          type="button"
                          className="dropdown-item"
                          onClick={() =>
                            setSelectedChannel({
                              channelId: channel.channelId,
                              channelName: channel.channelName,
                            })
                          }
                        >
                          {channel.channelName}
                        </button>
                      </li>
                    ))}
                  </ul> */}
                </div>
              </S.DetailInfoItem>

              <S.DetailInfoItem>
                <div key={"introduction"} css={S.styles.input_form} className="form-group">
                  <label htmlFor={`eduenv-${id}-${"introduction"}`} className="form-label">
                    {t(camelToKebab("introduction"))}
                  </label>
                  <textarea
                    name={"introduction"}
                    id={`eduenv-${id}-${"introduction"}`}
                    className="form-control"
                    // rows={form["introduction"].rows || 3}
                    // placeholder={form["introduction"].placeholder}
                    value={userInfo.introduction || "자기소개가 없습니다."}
                    disabled={true}
                    // onChange={handleTextarea}
                  ></textarea>
                </div>
              </S.DetailInfoItem>
              <div css={S.styles.submit}>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={() => handleRequestFriend()}
                >
                  친구 추가
                </button>
              </div>
              {/* <S.DetailInfoItem>
                <p>랭킹</p>
                <p style={{ color: "#0088ff" }}>12위</p>
              </S.DetailInfoItem> */}
            </S.DetailInfo>

            {/* <S.ButtonGroup>
              <S.PrimaryButton>장비</S.PrimaryButton>
              <S.PrimaryButton>귓속말</S.PrimaryButton>
              <S.ActivateButon>초대하기</S.ActivateButon>
            </S.ButtonGroup> */}
          </S.ContentWrap>
        </S.Wrapper>
      </S.ModalWapp>
    </div>
  );
}
