import { ChangeEvent, Fragment, useEffect, useRef, useState } from "react";

import useToast from "common/hooks/useToast";

import useAuth from "../../../hooks/useAuth";
import { styles } from "./styles";

import UserDetailProfile from "../UserDetailProfile";
import MyDetailProfile from "../MyDetailProfile";
import {
  EquippedItemForProfile,
  GetUserProfileInfoResponse,
  getUserProfilePageInfo,
} from "../../../api/user";
import { updateEquippedItemWithDefault } from "common/hooks/useAvatorEquippedItem";
import { AxiosError } from "axios";
import usePopup from "../../../hooks/usePopup";
import { ItemCategory } from "common/hooks/useAvator";

export default function PopupUserDetailProfile() {
  const auth = useAuth();
  const popup = usePopup();

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<GetUserProfileInfoResponse>();
  const [equippedMap, setEquippedMap] = useState<Map<ItemCategory, EquippedItemForProfile>>(
    new Map([]),
  );

  const init = () => {
    getUserProfilePageInfo(popup.userDetail.userId)
      .then((response: GetUserProfileInfoResponse) => {
        setUserInfo(response);

        const equipmentMap: Map<ItemCategory, EquippedItemForProfile> = new Map(
          response.equippedUserItemList.map((item) => {
            return [item.itemCategory, item];
          }),
        );

        updateEquippedItemWithDefault(equipmentMap, response?.gender);
        setEquippedMap(equipmentMap);
        setIsLoaded(true);
      })
      .catch((error: AxiosError) => {});
  };

  useEffect(() => {
    if (!popup.userDetail.userId) {
      setIsLoaded(false);
      return;
    }

    if (isLoaded) {
      return;
    }

    init();
  }, [isLoaded, popup.userDetail.userId]);

  return (
    popup.userDetail.visible && (
      <Fragment>
        {isLoaded && auth.userId === popup.userDetail.userId
          ? isLoaded && (
              <MyDetailProfile
                onClose={() => {
                  popup.userDetail.close();
                }}
                userLevel={auth.level}
              />
            )
          : isLoaded && (
              <UserDetailProfile
                userId={popup.userDetail.userId}
                onClose={() => {
                  popup.userDetail.close();
                }}
                userInfo={userInfo}
                equippedMap={equippedMap}
                isLoaded={isLoaded}
              />
            )}
      </Fragment>
    )
  );
}
