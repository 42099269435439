const useEnquiry = () => {
  const init = () => {
    if (typeof window === "undefined") {
      return;
    }

    if ((window as any).ChannelIO) {
      return (window.console.error || window.console.log || function () {})(
        "ChannelIO script included twice.",
      );
    }

    const channel = function () {
      channel.c(arguments);
    };

    channel.q = [];

    channel.c = function (args) {
      channel.q.push(args);
    };

    (window as any).ChannelIO = channel;

    if (document.readyState === "complete") {
      load();
    } else if ((window as any).attachEvent) {
      (window as any).attachEvent("onload", load);
    } else {
      window.addEventListener("DOMContentLoaded", load, false);
      window.addEventListener("load", load, false);
    }
  };

  const load = () => {
    if ((window as any).ChannelIOInitialized) {
      return;
    }

    (window as any).ChannelIOInitialized = true;

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = "https://cdn.channel.io/plugin/ch-plugin-web.js";

    const tag = document.getElementsByTagName("script")[0];
    tag.parentNode.insertBefore(script, tag);
  };

  const boot = (pluginKey, settings: any) => {
    (window as any).ChannelIO("boot", {
      pluginKey,
      ...settings,
    });
  };

  const on = (event: "onShowMessenger" | "onHideMessenger", callback) => {
    (window as any).ChannelIO(event, callback);
  };

  const show = () => {
    (window as any).ChannelIO("showMessenger");
  };

  const hide = () => {
    (window as any).ChannelIO("hideMessenger");
  };

  const shutdown = () => {
    (window as any).ChannelIO("shutdown");
  };

  return {
    init,
    boot: (pluginKey: string, settings: any) => {
      boot(pluginKey, settings);
    },
    on: (event: "onShowMessenger" | "onHideMessenger", callback) => {
      on(event, callback);
    },
    show,
    hide,
    shutdown,
  };
};

export default useEnquiry;
