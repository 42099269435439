import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "../shared/request";

export type PluginType =
  | "CHANNEL_TALK"
  | "GOOGLE_ANALYTICS_4"
  | "NAVER_SEARCH_ADVISOR"
  | "KAKAO_CHANNEL";

export interface GetChannelPluginRequest {
  params: {
    plugin_type: PluginType;
  };
}

export interface GetChannelPluginResponse {
  pluginType: PluginType;
  pluginKey: string;
  isUsed: boolean;
}

export interface GetSavedChannelPluginResponse {
  pluginList: PluginList[];
}

export interface PluginList {
  pluginType: PluginType;
  pluginKey: string;
  isUsed: boolean;
}

export interface SaveChannelPluginRequest {
  pluginType: PluginType;
  pluginKey: string;
  isUsed: boolean;
}

export interface GetSavedChannelPopupResponse {
  popupList: PopupList[];
  pageCount: number;
  totalCount: number;
}

export interface PopupList {
  channelPopupId: number;
  userProfile: UserProfile;
  title: string;
  content: string;
  link: string;
  popupShowingMethod: "ALL" | "CHANNEL_HOME" | "CHANNEL_DOLPHIN_HOME";
  startDate: string;
  endDate: string;
  isUsed: boolean;
}

export interface UserProfile {
  nickname: string;
  userId: string;
  profile: string;
  introduction: string;
}

export interface SaveChannelPopupRequest {
  title: string;
  link: string;
  content: string;
  popupShowingMethod: "ALL" | "CHANNEL_HOME" | "CHANNEL_DOLPHIN_HOME";
  startDate: Date;
  endDate: Date;
  isUsed: boolean;
}

export interface GetSavedChannelBusinessResponse {
  isUsed: boolean;
  representative: string;
  businessName: string;
  channelWhiteLogo: string;
  businessRegistrationNumber: string;
  email: string;
  telephone: string;
  address: string;
  managementState: string;
}

export interface GetAcceptedChannelBusinessResponse {
  isUsed: boolean;
  representative: string;
  businessName: string;
  businessRegistrationNumber: string;
  email: string;
  telephone: string;
  address: string;
}

export interface SaveChannelBusinessRequest {
  isUsed: boolean;
  businessRegistrationNumber: string;
  businessName: string;
  representative: string;
  address: string;
  email: string;
  telephone: string;
}

/**
 * API:
 * 채널톡 연동 정보 가져오기 (전체 사용자)
 */
export const getChannelPlugin = (params: GetChannelPluginRequest) => {
  return axiosInstance
    .get<GetSavedChannelPluginResponse>("/v1/channels/plugins", params)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 채널톡 연동 설정 정보 가져오기
 */
export const getSavedChannelPlugin = () => {
  return axiosInstance
    .get<GetSavedChannelPluginResponse>("/v1/users/channels/plugins")
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 채널톡 연동하기
 */
export const saveChannelPlugin = (payload: SaveChannelPluginRequest) => {
  return axiosInstance
    .post<unknown>("/v1/users/channels/plugins", payload)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 팝업 설정 정보 가져오기 (전체 사용자)
 */
export const getSavedChannelPopup = () => {
  return axiosInstance
    .get<GetSavedChannelPopupResponse>("/v1/users/channels/popups")
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 팝업 설정하기
 */
export const saveChannelPopup = (payload: SaveChannelPopupRequest) => {
  return axiosInstance
    .post<unknown>("/v1/users/channels/popups", payload)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 팝업 설정 업데이트
 */
export const updateChannelPopup = (popupId: number, payload: SaveChannelPopupRequest) => {
  return axiosInstance
    .put<unknown>(`/v1/users/channels/popups/${popupId}`, payload)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 사업자 정보 가져오기
 */
export const getSavedChannelBusiness = () => {
  return axiosInstance
    .get<GetSavedChannelBusinessResponse>("/v1/users/channels/businesses")
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 사용자에게 보여지는 승인된 사업자 정보 가져오기
 */
export const getAcceptedChannelBusiness = () => {
  return axiosInstance
    .get<GetAcceptedChannelBusinessResponse>("/v1/channels/businesses")
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 사업자 정보 설정하기
 */
export const saveChannelBusiness = (payload: SaveChannelBusinessRequest) => {
  return axiosInstance
    .post<unknown>("/v1/users/channels/businesses", payload)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};
