import { useState } from "react";
import useAvator, { EquippedItem, ITEM_CATEGORY_LIST, ItemCategory, MyItems } from "./useAvator";
import { Gender } from "../constants/users";

// export type InventoryType = "EQUIPMENT" | "FACE" | "HAIR" | "SKIN" | "CONSUMPTION" | "ETC" | "NULL";

// export type ItemCategory =
//   | "HAT"
//   | "TOP"
//   | "BOTTOM"
//   | "ONE_PIECE"
//   | "SHOES"
//   | "CAPE"
//   | "ACCESSORY"
//   | "EARRING"
//   | "SKIN"
//   | "NECKLACE"
//   | "HAIR"
//   // | "EARING"
//   | "FACE"
//   | "FACE_ACCESSORY"
//   | "NULL";

// export const ITEM_CATEGORY_LIST: ItemCategory[] = [
//   "HAT",
//   "TOP",
//   "BOTTOM",
//   "ONE_PIECE",
//   "SHOES",
//   "EARRING",
//   "ACCESSORY",
//   "CAPE",
//   "SKIN",
//   "NECKLACE",
//   "HAIR",
//   // "EARING", // 이 줄은 주석 처리되어 있으므로 포함하지 않았습니다.
//   "FACE",
//   "FACE_ACCESSORY",
//   "NULL",
// ];

// export type Item = {
//   itemId: number;
//   name: string;
//   thumbnail: string;
//   sprite: string;
//   itemCategory: ItemCategory;
// };
// /**
//  * API: Inventory
//  * 사용자가 보유하고 있는 아이템 리스트
//  */
// export type MyItems = {
//   inventoryId: number;
//   itemId: Item["itemId"];
//   name: Item["name"];
//   itemCategory: Item["itemCategory"];
//   inventoryCategory: InventoryType;
//   sprite: Item["sprite"];
//   thumbnail: Item["thumbnail"];
//   description: string;
// };

// export type EquippedItem = {
//   equippedUserItemId: number;
//   itemId: Item["itemId"];
//   name: Item["name"];
//   sprite: string;
//   spriteStand1: string;
//   itemCategory: Item["itemCategory"];
//   thumbnail: Item["thumbnail"];
// };

type EquippedItems = {
  HAT: MyItems;
  TOP: MyItems;
  BOTTOM: MyItems;
  ONE_PIECE: MyItems;
  CAPE: MyItems;
  SHOES: MyItems;
  ACCESSORY: MyItems;
  SKIN: MyItems;
  NECKLACE: MyItems;
  EARRING: MyItems;
  HAIR: MyItems;
  FACE: MyItems;
  FACE_ACCESSORY: MyItems;

  // container1: MyItems[];
  // container2: MyItems[];
  // container3: MyItems[];
};

export type EquippedItemForRoom = Pick<
  EquippedItem,
  "itemCategory" | "sprite" | "spriteStand1" | "thumbnail" | "name"
  // | "equippedUserItemId" | "itemId" | "name" |
>;

export const DEFAULT_EQ_ITEM_MAP = {
  MAN: {
    HAIR: {
      sprite: "/resources/img/avatar/avatar-default-hair-man-sprite.png",
    } as EquippedItemForRoom,

    FACE: {
      sprite: "/resources/img/avatar/avatar-default-face-man-sprite.png",
    } as EquippedItemForRoom,
    SKIN: {
      sprite: "/resources/img/avatar/avatar-default-skin-sprite.png",
    } as EquippedItemForRoom,

    TOP: {
      sprite: "/resources/img/avatar/avatar-default-top-man-sprite.png",
    } as EquippedItemForRoom,
    BOTTOM: {
      sprite: "/resources/img/avatar/avatar-default-bottom-man-sprite.png",
    } as EquippedItemForRoom,
  },
  WOMAN: {
    HAIR: {
      sprite: "/resources/img/avatar/avatar-default-hair-woman-sprite.png",
    } as EquippedItemForRoom,

    FACE: {
      sprite: "/resources/img/avatar/avatar-default-face-woman-sprite.png",
    } as EquippedItemForRoom,
    SKIN: {
      sprite: "/resources/img/avatar/avatar-default-skin-sprite.png",
    } as EquippedItemForRoom,

    TOP: {
      sprite: "/resources/img/avatar/avatar-default-top-woman-sprite.png",
    } as EquippedItemForRoom,
    BOTTOM: {
      sprite: "/resources/img/avatar/avatar-default-bottom-woman-sprite.png",
    } as EquippedItemForRoom,
  },

  // ONE_PIECE: {
  //   sprite: "",
  // } as EquippedItemForRoom,
  // SHOSE: {
  //   sprite: "",
  // } as EquippedItemForRoom,
};
/**
 * @returns {Object} - 상의, 하의, 신발 이미지를 저장하는 state와 setState 함수를 반환
 */

// container1: MyItems[];
// container2: MyItems[];
// container3: MyItems[];

export function toEquippedMap(
  equippedItems: EquippedItems,
  equipmentMap: Map<ItemCategory, EquippedItemForRoom>,
  gender: Gender,
) {
  ITEM_CATEGORY_LIST.forEach((category: ItemCategory) => {
    if (
      !(
        equippedItems[category] &&
        equippedItems[category].sprite &&
        equippedItems[category].sprite !== ""
      )
    ) {
      if (["TOP", "BOTTOM", "HAIR", "SKIN", "FACE"].includes(category)) {
        switch (category) {
          case "TOP":
            if (
              !(
                equippedItems["ONE_PIECE"] &&
                equippedItems["ONE_PIECE"].sprite &&
                equippedItems["ONE_PIECE"].sprite !== ""
              )
            ) {
              equipmentMap.set(category, DEFAULT_EQ_ITEM_MAP[gender][category]);
            }
            break;
          case "BOTTOM":
            if (
              !(
                equippedItems["ONE_PIECE"] &&
                equippedItems["ONE_PIECE"].sprite &&
                equippedItems["ONE_PIECE"].sprite !== ""
              )
            ) {
              equipmentMap.set(category, DEFAULT_EQ_ITEM_MAP[gender][category]);
            }
            break;
          case "ONE_PIECE":
            equipmentMap.set(category, DEFAULT_EQ_ITEM_MAP[gender][category]);
            break;
          default:
            equipmentMap.set(category, DEFAULT_EQ_ITEM_MAP[gender][category]);
            break;
        }
      }
    }
  });
}

export function updateEquippedItemWithDefault(
  equippedItems: Map<ItemCategory, EquippedItemForRoom>,
  gender: Gender,
) {
  ITEM_CATEGORY_LIST.forEach((category: ItemCategory) => {
    setItem(equippedItems, category, gender === "NOT_SELECTED" || gender === null ? "MAN" : gender);
  });
  return equippedItems;
}

function setItem(
  equippedItems: Map<ItemCategory, EquippedItemForRoom>,
  category: ItemCategory,
  gender: Gender,
) {
  const item = equippedItems.get(category);
  if (!(item && item.sprite && item.sprite !== "")) {
    if (["TOP", "BOTTOM", "HAIR", "SKIN", "FACE"].includes(category)) {
      switch (category) {
        case "TOP":
          if (
            !(
              equippedItems["ONE_PIECE"] &&
              equippedItems["ONE_PIECE"].sprite &&
              equippedItems["ONE_PIECE"].sprite !== ""
            )
          ) {
            equippedItems.set(category, DEFAULT_EQ_ITEM_MAP[gender][category]);
          }
          break;
        case "BOTTOM":
          if (
            !(
              equippedItems["ONE_PIECE"] &&
              equippedItems["ONE_PIECE"].sprite &&
              equippedItems["ONE_PIECE"].sprite !== ""
            )
          ) {
            equippedItems.set(category, DEFAULT_EQ_ITEM_MAP[gender][category]);
          }
          break;
        case "ONE_PIECE":
          equippedItems.set(category, DEFAULT_EQ_ITEM_MAP[gender][category]);
          break;
        default:
          equippedItems.set(category, DEFAULT_EQ_ITEM_MAP[gender][category]);
          break;
      }
    }
  }
}

export default function useAvatorEquippedItem() {
  const avatar = useAvator();

  const [topImg, setTopImg] = useState(""); // 상의
  const [onePieceImg, setOnePieceImg] = useState(""); // 한벌 옷
  const [bottomImg, setBottomImg] = useState(""); // 하의
  const [shoesImg, setShoesImg] = useState(""); // 신발
  const [capeImg, setCapeImg] = useState(""); // 머리
  const [hairImg, setHairImg] = useState(""); // 머리
  const [hatImg, setHatImg] = useState(""); // 머리
  const [earringImg, setEarringImg] = useState(""); // 귀걸이
  const [accImg, setAccImg] = useState("");
  const [faceImg, setFaceImg] = useState(""); // 얼굴
  const [faceAccImg, setFaceAccImg] = useState(""); // 얼굴 악세사리
  const [skinImg, setSkinImg] = useState(""); // 얼굴 악세사리

  return {
    topImg,
    setTopImg,
    onePieceImg,
    hairImg,
    earringImg,
    faceImg,
    accImg,
    faceAccImg,
    setOnePieceImg,
    bottomImg,
    setBottomImg,
    shoesImg,
    setShoesImg,
    setHairImg,
    setEarringImg,
    capeImg,
    setCapeImg,
    setFaceImg,
    setAccImg,
    setFaceAccImg,
    skinImg,
    setSkinImg,
    hatImg,
    setHatImg,
  };
}
