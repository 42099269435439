import { styles } from "./styles";

const ErrorContent = () => {
  return (
    <div css={styles.wrapper}>
      <div css={styles.container} className="container">
        <div css={styles.content}>
          <img src="/resources/img/not_found_character.png" />
          <h3>
            <span className="text-warning">오류</span>가 발생했습니다.
          </h3>
          <p>
            서비스 이용에 불편을 드려 죄송합니다.
            <br />
            궁금하신 사항은 관리자에게 문의해 주세요.
            <br />
            (team@eduenv.com)
          </p>
          <a
            href="https://dolphincoding.tistory.com/category/%EA%B3%B5%EC%A7%80"
            target="__blank"
            className="btn btn-warning"
          >
            공지사항 확인하기
          </a>
        </div>
      </div>
    </div>
  );
};

export default ErrorContent;
