import { css } from "@emotion/react";

const styles = {
  container: css`
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    z-index: 100;
    width: 100%;
    height: 100%;
  `,
  canvas: css`
    width: 100%;
    height: 100%;
  `,
};

export { styles };
