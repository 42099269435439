import usePopup from "../../../hooks/usePopup";
import { Fragment, useEffect, useRef } from "react";
import { styles } from "./styles";

const PopupReward = () => {
  const popup = usePopup();

  const containerElRef = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    containerElRef.current.classList.remove("animate__bounceIn");

    popup.reward.close();

    // 다음 퀘스트가 있을 경우 표시
    popup.quest.show();
  };

  useEffect(() => {
    if (!popup.reward.visible) {
      return;
    }

    containerElRef.current.classList.add("animate__bounceIn");
    containerElRef.current.classList.add("animate__faster");
  }, [popup.reward.visible]);

  return (
    <Fragment>
      {popup.reward.visible && (
        <div css={styles.wrapper}>
          <div
            ref={containerElRef}
            css={styles.container}
            className="card shadow-lg text-white animate__animated"
          >
            <h4 css={styles.title}>퀘스트 보상</h4>
            <p css={styles.description} className="m-0">
              {popup.reward.content.description}
            </p>
            <div css={styles.coin}>
              <img src="/resources/img/animation_coin.png" />
            </div>
            <div css={styles.benefit}>
              <img src="/resources/svg/icons/icon_point.svg" className="icon" />
              <span> +{popup.reward.content.amount} 획득</span>
            </div>
            <button
              type="button"
              css={styles.button}
              className="btn btn-primary"
              onClick={handleClose}
            >
              확인
            </button>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default PopupReward;
