import { css } from "@emotion/react";
import { MEDIA_QUERIES } from "common/constants/styles/responsive";

const styles = {
  backdrop: css`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
  `,
  wrapper: css`
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    flex-direction: column;
    transform: translate(-50%, -50%);
    z-index: 1060;

    ${MEDIA_QUERIES.max.sm} {
      left: 0;
      transform: translate(0, -50%);
      width: calc(100% - 2rem);
    }
  `,
  container: css`
    position: relative;
    padding: 1.875rem;
    width: 26.875rem;

    ${MEDIA_QUERIES.max.sm} {
      margin-right: 1rem;
      margin-left: 1rem;
      padding: 1.875rem 1.25rem 1.25rem 1.25rem;
      width: 100%;
    }
  `,
  heading: css`
    margin-bottom: 2.5rem;
  `,
  icon: css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 3.125rem;
    height: 3.125rem;
    font-size: 1.25rem;
  `,
  title: css`
    margin-top: 1rem;
    margin-bottom: 0.875rem;
    text-align: center;
  `,
  description: css`
    text-align: center;
    font-size: 0.875rem;
  `,
  button: css`
    width: 100%;
    font-weight: 600;
  `,
};

export { styles };
