import { css } from "@emotion/react";

const styles = {
  avatarContainer: css`
    position: relative;
    width: 100%;
    height: 100%;
    top: 50%;
    transform: translate(0, -65%);
    z-index: 1;
  `,
  avatarFace: css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -48%);
  `,
  avatarBody: css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-52%, 13%);
  `,

  avatarSpriteContainer: css`
    position: absolute;
    width: 100%;
    height: 100%;
  `,
};

export default styles;
