/**
 * 클라이언트 유무 확인
 */
export const isClient = () => {
  return typeof window !== "undefined";
};

export const removeBackdrop = () => {
  const backdrops = document.querySelectorAll(".modal-backdrop");

  backdrops.forEach((backdrop) => backdrop.remove());

  document.body.classList.remove("modal-open");

  document.body.style.removeProperty("overflow");

  document.body.style.removeProperty("padding-right");
};

/**
 * 사용자 OS 확인
 */
export const detectOS = () => {
  const platform = navigator.userAgent.toLowerCase();

  if (platform.includes("win")) {
    return "Windows";
  }
  if (platform.includes("mac")) {
    return "MacOS";
  }
};

/**
 * 이미지 파일인지 확인
 */
export const isImageFile = (type: string) => {
  return (
    type === "image/jpeg" ||
    type === "image/png" ||
    type === "image/gif" ||
    type === "image/bmp" ||
    type === "image/webp"
  );
};

/**
 * 유저 레벨에 따른 색상
 */
export const getUserLevelColor = (level: number) => {
  switch (true) {
    case level <= 10:
      return "#ecb24e"; //yellow
    case level <= 15:
      return "#66bc99"; //green
    case level <= 20:
      return "#3970c1"; //blue
    case level <= 25:
      return "#e8678a"; //red
    case level <= 30:
      return "#a872d1"; //purple
    default:
      return "#ecb24e"; //yellow
  }
};
