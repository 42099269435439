import { CommonReducer } from "../modules/reducers";
import { getConfigStorage } from "../utils/storage";
import { upperSnakeToCamel } from "../utils/format";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  SoundInitialState,
  SoundTarget,
  actionEndSound,
  actionPlaySound,
} from "../modules/reducers/sound";

const useSound = () => {
  const dispatch = useDispatch();

  const soundState: SoundInitialState = useSelector((state: CommonReducer) => state.soundReducer);

  // 효과음 로드
  const load = ({ target }: { target: SoundTarget }) => {
    try {
      return new Audio(soundState[upperSnakeToCamel(target)].src);
    } catch (error) {}
  };

  // 재생
  const play = (options: { target: SoundTarget }) => {
    const configStorage = getConfigStorage();

    // 이벤트 루프에 등록되어 있는 경우 최신 State를 가져오지 못하기 때문에 로컬 스토리지에서 설정 정보를 확인함
    if (
      configStorage.music.state === "STOP" ||
      soundState[upperSnakeToCamel(options.target)].state === "PLAY"
    ) {
      return;
    }

    const audio = load({ target: options.target });

    if (audio) {
      audio.onloadedmetadata = () => {
        audio.volume = Number(configStorage.music.volume);

        audio.play();
        dispatch(actionPlaySound({ target: options.target }));
      };

      audio.onended = () => {
        dispatch(actionEndSound({ target: options.target }));
      };
    }
  };

  return {
    splash: soundState.splash,
    play: (options: { target: SoundTarget }) => {
      play(options);
    },
  };
};

export default useSound;
