import { GoalList } from "../api/quest";

/**
 * 채널 URL
 */
const getChannelUrl = (channelDomain: string) => {
  return `${process.env.CLIENT_PROTOCOL}://${channelDomain}.${process.env.CLIENT_HOST}:${process.env.CLIENT_PORT}`;
};

/**
 * 채널의 돌핀코딩 URL
 */
const getChannelDolphinCodingUrl = (channelDomain: string) => {
  return `${process.env.DOLPHIN_CODING_CLIENT_PROTOCOL}://${channelDomain}.${process.env.DOLPHIN_CODING_CLIENT_HOST}:${process.env.DOLPHIN_CODING_CLIENT_PORT}`;
};

/**
 * 돌핀코딩 URL
 */
const getDolphinCodingUrl = () => {
  return `${process.env.DOLPHIN_CODING_CLIENT_PROTOCOL}://${process.env.DOLPHIN_CODING_CLIENT_HOST}:${process.env.DOLPHIN_CODING_CLIENT_PORT}`;
};

/**
 * 퀘스트 수행 URL
 */
const getQuestUrl = (goalList: GoalList) => {
  let result = "/";

  switch (goalList.goalCategory) {
    // 임의의 문제 풀기
    case "ANY_PROBLEM_SUBMISSION":
      return "/problem?page=1&order=DESC";
    // 임의의 문제집 등록하기
    case "ANY_ENROLL_WORKSHEET":
      return "/worksheet?page=1";
    // 임의의 강의 등록하기
    case "ANY_ENROLL_COURSE":
      return "/course?page=1&order=POPULAR";
    // 사전에 지정된 문제 ID 퀘스트
    case "SPECIAL_PROBLEM_NUMBER":
    case "SPECIAL_VIDEO_NUMBER":
    case "VIDEO_LOG":
    // 사용자가 최초로 푼 문제에 대한 ID 퀘스트
    case "PROBLEM_LOG":
    // 돌핀 튜터 튜토리얼
    case "OPEN_DOLPHIN_AI":
      {
        // 문제집
        if (goalList.worksheetId && goalList.chapterId && goalList.problemId) {
          result = `/learn/worksheet/${goalList.worksheetId}/chapter/${goalList.chapterId}/problem/${goalList.problemId}`;

          return result;
        }

        // 강의
        if (goalList.courseId && goalList.lessonId) {
          result = `/learn/course/${goalList.courseId}/lesson/${goalList.lessonId}`;

          if (goalList.videoId) {
            // 강의 > 동영상 + 문제
            if (goalList.problemId) {
              result += `/video/${goalList.videoId}/problem/${goalList.problemId}`;
            }

            // 강의 > 동영상
            else {
              result += `/video/${goalList.videoId}`;
            }
          }

          // 강의 > 문제
          else if (goalList.problemId) {
            result += `/problem/${goalList.problemId}`;
          }

          return result;
        }

        // 문제
        if (goalList.problemId) {
          result = `/learn/problem/${goalList.problemId}`;

          return result;
        }
      }
      break;
    case "SPECIAL_PROBLEM_CATEGORY": {
      result = `/problem/category/${goalList.actionName}?page=1&order=DESC`;

      return result;
    }
    default:
  }

  return result;
};

export { getDolphinCodingUrl, getChannelUrl, getChannelDolphinCodingUrl, getQuestUrl };
