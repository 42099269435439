import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "../shared/request";
import { Character } from "../constants/styles/theme";

export interface GetQuestRequest {
  params: { page: number };
}

export interface GetQuestResponse {
  questList: QuestList[];
  pageCount: number;
  totalCount: number;
}

export interface QuestList {
  userQuestId: number;
  quest: Quest;
  state: "PERFORMING" | "COMPLETE";
}

export interface Quest {
  questId: number;
  title: string;
  largeCategory: "MAIN" | "SUB";
  mediumCategory: string;
  smallCategory: string;
  process: string;
  publishEventView: "POP_UP" | "NOTIFICATION";
  acquisitionMethod: "DAILY" | "WEEKLY";
  completionMethod: string;
  rewardType: "POINT";
  rewardCount: number;
  description: string;
  completeDescription: string;
  goalList: GoalList[];
  level: number;
  character: Character;
  clear: boolean;
}

export interface GoalList {
  userQuestId: number;
  goalCategory:
    | "ATTENDANCE_CHECK"
    | "BGM_MUSIC"
    | "ANY_PROBLEM_SUBMISSION"
    | "ANY_ENROLL_WORKSHEET"
    | "ANY_ENROLL_COURSE"
    | "SPECIAL_ENROLL_WORKSHEET"
    | "SPECIAL_PROBLEM_NUMBER"
    | "SPECIAL_VIDEO_NUMBER"
    | "SPECIAL_PROBLEM_CATEGORY"
    | "VIDEO_LOG"
    | "PROBLEM_LOG"
    | "OPEN_DOLPHIN_AI";
  goalName: string;
  action: string;
  actionId: number;
  actionName: string;
  completedCount: number;
  goalCount: number;
  courseId: number;
  worksheetId: number;
  lessonId: number;
  chapterId: number;
  videoId: number;
  problemId: number;
}

export interface GetProgressQuestResponse {
  clearCount: number;
  total: number;
  questId: number;
  userQuest: QuestList;
}

/**
 * API:
 * 메인 퀘스트 전체 목록
 */
export const getMainQuest = (params: GetQuestRequest) => {
  return axiosInstance
    .get<GetQuestResponse>("/v1/users/quests/mains", params)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 서브 퀘스트 전체 목록
 */
export const getSubQuest = (params: GetQuestRequest) => {
  return axiosInstance
    .get<GetQuestResponse>("/v1/users/quests/subs", params)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 남은 메인 퀘스트 목록
 */
export const getRemainingMainQuest = (params: GetQuestRequest) => {
  return axiosInstance
    .get<GetQuestResponse>("/v1/users/quests/mains/performing", params)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 남은 서브 퀘스트 목록
 */
export const getRemainingSubQuest = (params: GetQuestRequest) => {
  return axiosInstance
    .get<GetQuestResponse>("/v1/users/quests/subs/performing", params)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 메인 퀘스트 진행률
 */
export const getProgressMainQuest = () => {
  return axiosInstance
    .get<GetProgressQuestResponse>("/v1/users/quests/mains/progress")
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 서브 퀘스트 진행률
 */
export const getProgressSubQuest = () => {
  return axiosInstance
    .get<GetProgressQuestResponse>("/v1/users/quests/subs/progress")
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 배경음악 재생 퀘스트 성공
 */
export const completeMusicQuest = () => {
  return axiosInstance
    .post<unknown>("/v1/users/quests/music")
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 돌핀 튜터 튜토리얼 퀘스트 성공
 */
export const completeTutorTutorialQuest = () => {
  return axiosInstance
    .post<unknown>("/v1/users/quests/tutor")
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 퀘스트 보상
 */
export const rewardQuest = (userQuestId: number) => {
  return axiosInstance
    .put<unknown>(`/v1/users/quests/userquests/${userQuestId}/complete`)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 퀘스트 보상
 */
export const rewardSubQuestAll = (userQuestIdList: number[]) => {
  return axiosInstance
    .put<unknown>(`/v1/users/quests/userquests/subs/complete`, { userQuestIdList })
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 동영상 퀘스트 진행 사항 보고
 */
export const reportVideoQuest = (videoId: number) => {
  return axiosInstance
    .post<unknown>(`/v1/users/quests/videos/${videoId}/done`)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};
