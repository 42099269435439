import useAuth from "../../hooks/useAuth";
import usePoint from "../../hooks/usePoint";
import { AxiosError } from "axios";
import { getPoint, GetPointResponse } from "../../api/point";
import { useEffect } from "react";

const PointFetcher = () => {
  const auth = useAuth();
  const point = usePoint();

  useEffect(() => {
    if (!auth.isReady || point.isReady) {
      return;
    }

    if (auth.state === "VALID") {
      getPoint()
        .then((response: GetPointResponse) => {
          point.init(response.point);
        })
        .catch((error: AxiosError) => {});
    } else {
      point.init(0);
    }
  }, [auth.isReady, point.isReady]);

  return null;
};

export default PointFetcher;
