import useAuth from "../../hooks/useAuth";
import useQuest from "../../hooks/useQuest";
import { AxiosError } from "axios";
import { getMainQuest, GetQuestResponse, getSubQuest } from "../../api/quest";
import { useEffect } from "react";

const QuestFetcher = () => {
  const auth = useAuth();
  const quest = useQuest();

  const fetchQuest = async () => {
    await getMainQuest({ params: { page: 1 } })
      .then((response: GetQuestResponse) => {
        quest.appendData("MAIN", response.questList);
        quest.setPagination("MAIN", {
          itemsPerPage: response.pageCount,
          total: response.totalCount,
          currentPage: 1,
        });
      })
      .catch((error: AxiosError) => {
        return Promise.reject(error);
      });

    await getSubQuest({ params: { page: 1 } })
      .then((response: GetQuestResponse) => {
        quest.appendData("SUB", response.questList);
        quest.setPagination("SUB", {
          itemsPerPage: response.pageCount,
          total: response.totalCount,
          currentPage: 1,
        });
      })
      .catch((error: AxiosError) => {
        return Promise.reject(error);
      });

    quest.ready();
  };

  useEffect(() => {
    if (!auth.isReady || quest.isReady) {
      return;
    }

    if (auth.state === "VALID") {
      fetchQuest();
    } else {
      // 퀘스트 없이 진행
      quest.appendData("MAIN", []);
      quest.appendData("SUB", []);
    }
  }, [auth.isReady, quest.isReady]);

  // 메인 > 페이지네이션
  useEffect(() => {
    if (!quest.isReady || quest.main.pagination.currentPage === 1) {
      return;
    }

    getMainQuest({ params: { page: quest.main.pagination.currentPage } })
      .then((response: GetQuestResponse) => {
        quest.appendData("MAIN", response.questList);
      })
      .catch((error: AxiosError) => {
        return Promise.reject(error);
      });
  }, [quest.main.pagination.currentPage, quest.isReady]);

  // 서브 > 페이지네이션
  useEffect(() => {
    if (!quest.isReady || quest.sub.pagination.currentPage === 1) {
      return;
    }

    getSubQuest({ params: { page: quest.sub.pagination.currentPage } })
      .then((response: GetQuestResponse) => {
        quest.appendData("SUB", response.questList);
      })
      .catch((error: AxiosError) => {
        return Promise.reject(error);
      });
  }, [quest.sub.pagination.currentPage, quest.isReady]);

  return null;
};

export default QuestFetcher;
