import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "../shared/request";

export interface PrepareDolphinSubscriptionRequest {
  engCodeName: "DOLPHIN_PRO";
}

export interface PrepareDolphinSubscriptionResponse {
  paymentPrepareId: number;
  customerUid: string;
  merchantUid: string;
  amount: number;
  email: string;
  name: string;
  phoneNumber: string;
  custom_data: SubscriptionCustomData;
}

export interface SubscriptionCustomData {
  prepareId: number;
  merchantUid: string;
}

export interface PrepareChannelSubscriptionRequest {
  engCodeName: "CHANNEL_PRO";
}

export interface PrepareChannelSubscriptionResponse {
  paymentPrepareId: number;
  customerUid: string;
  merchantUid: string;
  amount: number;
  email: string;
  name: string;
  phoneNumber: string;
  custom_data: SubscriptionCustomData;
}

export interface SubscriptionCustomData {
  prepareId: number;
  merchantUid: string;
}

/**
 * API:
 * 돌핀 PRO 구독 결제 > merchant_uid 생성
 */
export const prepareDolphinSubscription = (payload: PrepareDolphinSubscriptionRequest) => {
  return axiosInstance
    .post<PrepareDolphinSubscriptionResponse>("/v1/users/subscribes/preparations/dolphin", payload)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 프리미엄 채널 구독 결제 > merchant_uid 생성
 */
export const prepareChannelSubscription = (
  channelId: number,
  payload: PrepareChannelSubscriptionRequest,
) => {
  return axiosInstance
    .post<PrepareChannelSubscriptionResponse>(
      `/v1/users/subscribes/preparations/channel/${channelId}`,
      payload,
    )
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};
