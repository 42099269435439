import { FrameUtil } from "common/utils/animation";

// 애니메이션 변수
const numOfConfetti = 120;
const gravity = 0.5;
const terminalVelocity = 5;
const drag = 0.075;
const colors = [
  { front: "red", back: "darkred" },
  { front: "green", back: "darkgreen" },
  { front: "blue", back: "darkblue" },
  { front: "yellow", back: "darkyellow" },
  { front: "orange", back: "darkorange" },
  { front: "pink", back: "darkpink" },
  { front: "purple", back: "darkpurple" },
  { front: "turquoise", back: "darkturquoise" },
];

const randomRange = (min: number, max: number) => Math.random() * (max - min) + min;

class Confetto {
  frameUtil: FrameUtil;

  color: {
    front: string;
    back: string;
  };
  rect: {
    x: number;
    y: number;
  };
  position: {
    x: number;
    y: number;
  };
  rotation: number;
  scale: {
    x: number;
    y: number;
  };
  velocity: {
    x: number;
    y: number;
  };

  constructor(stageWidth: number, stageHeight: number) {
    this.frameUtil = new FrameUtil(120);

    this.color = colors[Math.floor(randomRange(0, colors.length))];
    this.rect = {
      x: randomRange(10, 20),
      y: randomRange(10, 30),
    };
    this.position = {
      x: randomRange(0, stageWidth),
      y: stageHeight - 1,
    };
    this.rotation = randomRange(0, 2 * Math.PI);
    this.scale = {
      x: 1,
      y: 1,
    };
    this.velocity = {
      x: randomRange(-25, 25),
      y: randomRange(0, -50),
    };
  }

  update(t: number, stageWidth: number) {
    if (!this.frameUtil.isUpdate(t)) {
      return;
    }

    this.velocity.x -= this.velocity.x * drag;
    /**
     * 초기 velocity 는 음수
     * gravity 만큼 계속 더하다가 양수로 전환
     */
    this.velocity.y = Math.min(this.velocity.y + gravity, terminalVelocity);
    // Confetto를 좌우로 흔들기
    this.velocity.x += Math.random() > 0.5 ? Math.random() : -Math.random();

    this.position.x += this.velocity.x;
    this.position.y += this.velocity.y;

    if (this.position.x > stageWidth) {
      this.position.x = 0;
    }
    if (this.position.x < 0) {
      this.position.x = stageWidth;
    }

    // y 값으로 높이를 조절해 3차원으로 회전하는 효과를 줌
    this.scale.y = Math.cos(this.position.y * 0.1);
  }

  draw(ctx: CanvasRenderingContext2D) {
    const width = this.rect.x * this.scale.x;
    const height = this.rect.y * this.scale.y;

    ctx.save();
    ctx.translate(this.position.x, this.position.y);
    ctx.rotate(this.rotation);

    ctx.fillStyle = this.scale.y > 0 ? this.color.front : this.color.back;
    ctx.fillRect(-width / 2, -height / 2, width, height); // 회전의 중심축에서 도형을 그림
    ctx.restore();
  }
}

export class ConfettiAnimation {
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  stageWidth: number;
  stageHeight: number;
  requestId: number;
  resizeHandler: EventListener;

  confetti: Confetto[];

  constructor(canvas: HTMLCanvasElement) {
    this.canvas = canvas;
    this.ctx = canvas.getContext("2d");
    this.resizeHandler = this.resize.bind(this);

    this.confetti = [];

    window.addEventListener("resize", this.resizeHandler);
    this.resize();

    this.requestId = requestAnimationFrame(this.animate.bind(this));
  }

  resize() {
    this.stageWidth = this.canvas.getBoundingClientRect().width;
    this.stageHeight = this.canvas.getBoundingClientRect().height;

    // Retina Display 지원
    // this.canvas.width = this.stageWidth * 2;
    // this.canvas.height = this.stageHeight * 2;
    // this.ctx.scale(2, 2);

    this.canvas.width = this.stageWidth;
    this.canvas.height = this.stageHeight;

    this.confetti = [];

    for (let i = 0; i < numOfConfetti; i++) {
      this.confetti.push(new Confetto(this.stageWidth, this.stageHeight));
    }
  }

  animate(t: number) {
    this.ctx.clearRect(0, 0, this.stageWidth, this.stageHeight);

    for (let i = 0; i < this.confetti.length; i++) {
      this.confetti[i].update(t, this.stageWidth);
      this.confetti[i].draw(this.ctx);

      if (this.confetti[i].position.y >= this.stageHeight) {
        this.confetti.splice(i, 1);
        i--;
      }
    }

    this.requestId = requestAnimationFrame(this.animate.bind(this));
  }

  congrats() {
    for (let i = 0; i < numOfConfetti; i++) {
      this.confetti.push(new Confetto(this.stageWidth, this.stageHeight));
    }
  }

  destroy() {
    window.removeEventListener("resize", this.resizeHandler);
    cancelAnimationFrame(this.requestId);
  }
}
