import animation, { AnimationInitialState } from "./animation";
// import avator, { AvatorInitialState } from "./avator";
import channel, { ChannelInitalState } from "./channel";
import commonReducer, { CommonReducer } from "common/modules/reducers";
import notification, { NotificationInitialState } from "./notification";
import payment, { PaymentInitialState } from "./payment";
import point, { PointInitialState } from "./point";
import popup, { PopupInitialState } from "./popup";
import request, { RequestInitialState } from "./request";
import socket, { SocketInitialState } from "./socket";
import user, { UserInitialState } from "./user";
import { combineReducers } from "redux";
import quest, { QuestInitialState } from "./quest";
import world, { WorldInitialState } from "./world";
import room, { RoomInitialState } from "./room";
import cash, { CashInitialState } from "./cash";

export interface RootReducer extends CommonReducer {
  animationReducer: AnimationInitialState;
  questReducer: QuestInitialState;
  cashReducer: CashInitialState;

  // avatorReducer: AvatorInitialState;
  channelReducer: ChannelInitalState;
  notificationReducer: NotificationInitialState;
  paymentReducer: PaymentInitialState;
  pointReducer: PointInitialState;
  popupReducer: PopupInitialState;
  requestReducer: RequestInitialState;
  socketReducer: SocketInitialState;
  userReducer: UserInitialState;
  worldReducer: WorldInitialState;
  roomReducer: RoomInitialState;
}

const rootReducer = combineReducers({
  animationReducer: animation,
  questReducer: quest,
  cashReducer: cash,
  // avatorReducer: avator,
  channelReducer: channel,
  notificationReducer: notification,
  paymentReducer: payment,
  pointReducer: point,
  popupReducer: popup,
  requestReducer: request,
  socketReducer: socket,
  userReducer: user,
  worldReducer: world,
  roomReducer: room,
  ...commonReducer,
});

export default rootReducer;
