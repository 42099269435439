import i18n from "i18next";
import translationEN from "../public/locales/en/translation.json";
import translationKO from "../public/locales/ko/translation.json";
import { initReactI18next } from "react-i18next";
import { isClient } from "common/utils/common";

/**
 * 다국어 처리
 * i18n Ally 플러그인으로 다국어 작성
 */
const resources = {
  KO: {
    translation: translationKO,
  },
  EN: {
    translation: translationEN,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: isClient() ? localStorage.getItem("language") || "KO" : "KO",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
