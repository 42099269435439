import { ChangeEvent, useEffect, useRef, useState } from "react";
import * as S from "./styles";
import { Modal } from "bootstrap";
import { isClient } from "common/utils/common";
import { getUserLevelColor } from "common/utils/common";
import { css } from "@emotion/react";
import { TextareaInputState } from "../../ui/TextareaInput/TextareaInput";
import { camelToKebab } from "common/utils/format";
import useToast from "common/hooks/useToast";
import { AxiosError } from "axios";
import { v4 } from "uuid";

import useAuth from "../../../hooks/useAuth";
import useAvatorEquippedItem from "../../../../../../common/src/hooks/useAvatorEquippedItem";
import Avator from "../../shop/avator";
import { ResponseEquippedItems } from "../../../api/shop";
import {
  DEFAULT_EQUIPEED_BOTTOM,
  DEFAULT_EQUIPEED_FACE,
  DEFAULT_EQUIPEED_HAIR,
  DEFAULT_EQUIPEED_SKIN,
  DEFAULT_EQUIPEED_TOP,
} from "../../../utils/shop";

import {
  GetJoinedChannelResponse,
  JoinedChannelList,
  getJoinedChannel,
} from "../../../api/channel";
import { GetMyPageInfoResponse, getMyPageInfo, updateIntroduction } from "../../../api/user";
import { useTranslation } from "react-i18next";
import useAvator from "common/hooks/useAvator";
// import { getEquippedItems } from "../../../api/avator";

interface FormState {
  [key: string]: TextareaInputState;
  introduction: TextareaInputState;
}

const FORMAT: FormState = {
  introduction: {
    placeholder: "자기소개를 입력해 주세요.",
    value: "",
    error: "",
  },
};
interface MyDetailProfileProps {
  onClose: () => void;
  userLevel: number;
}

export default function MyDetailProfile({ onClose, userLevel }: MyDetailProfileProps) {
  const auth = useAuth();
  const toast = useToast();
  const avatar = useAvator();

  // const {
  //   topImg,
  //   onePieceImg,
  //   bottomImg,
  //   shoesImg,
  //   hatImg,
  //   earringImg,
  //   hairImg,
  //   faceImg,
  //   accImg,
  //   faceAccImg,
  //   capeImg,
  //   setTopImg,
  //   setOnePieceImg,
  //   setBottomImg,
  //   setShoesImg,
  //   setHairImg,
  //   setFaceImg,
  //   setFaceAccImg,
  //   skinImg,
  //   setSkinImg,
  // } = useAvatorEquippedItem();
  const { t } = useTranslation();

  const modalElRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<Modal>(null);
  const gender = auth.gender === "MAN" ? "MAN" : "WOMAN";
  const id = v4();

  const [form, setForm] = useState<FormState>({ ...FORMAT });
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [selectedChannel, setSelectedChannel] = useState({
    channelId: 0,
    channelName: "",
  });
  const [myChannels, setMyChannels] = useState<JoinedChannelList[]>([]);
  const [isSetEquipped, setIsSetEquipped] = useState(false);

  const clearFormError = () => {
    const newState = { ...form };

    for (const key in newState) {
      if (typeof newState[key].error === "undefined") {
        continue;
      }

      newState[key] = {
        ...newState[key],
        error: "",
      };
    }

    return newState;
  };

  const handleTextarea = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setForm({
      ...form,
      [event.target.name]: {
        ...form[event.target.name],
        value: event.target.value,
        error: "",
      },
    });
  };

  const handleForm = () => {
    let isValid = true;

    const submitForm = { ...clearFormError() };

    for (const key in form) {
      if (!submitForm[key].value) {
        isValid = false;

        submitForm[key] = {
          ...submitForm[key],
          error: `please-enter-${camelToKebab(key)}`,
        };
      }
    }

    setForm(submitForm);

    if (isValid) {
      const payload = {
        introduction: submitForm.introduction.value,
        channelId: selectedChannel.channelId,
      };

      updateIntroduction(payload)
        .then((response: unknown) => {
          toast.notification("success", "자기소개가 수정되었습니다.").show();
        })
        .catch((error: AxiosError) => {});
    }
  };

  useEffect(() => {
    if (isClient()) {
      import("bootstrap").then(({ Modal }) => {
        modalRef.current = new Modal(modalElRef.current);

        modalRef.current.show();
      });
    }

    modalElRef.current.addEventListener("hidden.bs.modal", () => {
      onClose();
    });
  }, []);

  const init = () => {
    getMyPageInfo()
      .then((response: GetMyPageInfoResponse) => {
        setForm({
          ...form,
          introduction: {
            ...form.introduction,
            value: response.introduction,
          },
        });
        setSelectedChannel({
          channelId: response.channelProfile.channelId,
          channelName: response.channelProfile.channelName,
        });
        setIsLoaded(true);
      })
      .catch((error: AxiosError) => {});
  };

  useEffect(() => {
    (async () => {
      // 가입한 채널 정보 가져오기
      const channels: GetJoinedChannelResponse = await getJoinedChannel({ params: { page: 1 } });
      setMyChannels(channels.channels);
    })();
  }, []);

  useEffect(() => {
    if (isLoaded) {
      return;
    }

    init();
  }, [isLoaded]);

  // /**
  //  * @description 장착중인 아이템 목록 함수
  //  */
  // async function fetchEquippedItems() {
  //   try {
  //     setIsSetEquipped(false);

  //     const equippedItems: ResponseEquippedItems = await getEquippedItems();
  //     const equippedItemsMap = equippedItems.equippedUserItemList.reduce((acc, item) => {
  //       return { ...acc, [item.itemCategory]: item };
  //     }, {});
  //     // setTopImg(equippedItemsMap["TOP"]?.spriteStand1 || DEFAULT_EQUIPEED_TOP[gender]);
  //     // const newLocal = equippedItemsMap["ONE_PIECE"]?.spriteStand1 || DEFAULT_EQUIPEED_TOP[gender];
  //     // setOnePieceImg(newLocal);
  //     // setBottomImg(equippedItemsMap["BOTTOM"]?.spriteStand1 || DEFAULT_EQUIPEED_BOTTOM[gender]);
  //     // setShoesImg(equippedItemsMap["SHOES"]?.spriteStand1 || "");
  //     // setHairImg(equippedItemsMap["HAIR"]?.spriteStand1 || DEFAULT_EQUIPEED_HAIR[gender]);
  //     // setFaceImg(equippedItemsMap["FACE"]?.spriteStand1 || DEFAULT_EQUIPEED_FACE[gender]);
  //     // setFaceAccImg(equippedItemsMap["FACE_ACCESSORY"]?.spriteStand1 || "");
  //     // setSkinImg(equippedItemsMap["SKIN"]?.spriteStand1 || DEFAULT_EQUIPEED_SKIN);
  //   } catch {
  //   } finally {
  //     setIsSetEquipped(true);
  //   }
  // }

  // useEffect(() => {
  //   fetchEquippedItems();
  // }, []);

  return (
    <div
      ref={modalElRef}
      css={css`
        z-index: 70 !important;
        .modal-header {
          position: relative;
          padding: 0.75rem 0.75rem 1.5rem 0.75rem;
        }

        .modal-title {
          position: absolute;
          top: 1.25rem;
          left: 50%;
          transform: translateX(-50%);
        }

        .btn-close {
          z-index: 10;
        }

        .modal-body {
          padding: 0.9375rem;
        }

        .modal-footer {
          padding: 1.25rem;
        }
      `}
      className="modal fade"
    >
      <S.ModalWapp className="modal-dialog  modal-dialog-centered modal-dialog-scrollable modal-lg">
        <S.Wrapper className="modal-content shadow">
          <S.Header className="">
            <span />
            <span>유저 정보</span>
            <button type="button" data-bs-dismiss="modal">
              <img
                src="/resources/svg/icons/shop/avatar-user-detail-close.svg"
                alt="add"
                onClick={onClose}
              />
            </button>
          </S.Header>
          <S.Avatar>
            <S.AvatarInner />
            <Avator
              topImage={avatar.equippedItemMap?.TOP?.spriteStand1}
              onePieceImage={avatar.equippedItemMap?.ONE_PIECE?.spriteStand1}
              bottomImage={avatar.equippedItemMap?.BOTTOM?.spriteStand1}
              shoesImage={avatar.equippedItemMap?.SHOES?.spriteStand1}
              earringImage={avatar.equippedItemMap?.EARRING?.spriteStand1}
              hatImage={avatar.equippedItemMap?.HAT?.spriteStand1}
              hairImage={avatar.equippedItemMap?.HAIR?.spriteStand1}
              capeImage={avatar.equippedItemMap?.CAPE?.spriteStand1}
              faceImage={avatar.equippedItemMap?.FACE?.spriteStand1}
              accImage={avatar.equippedItemMap?.ACCESSORY?.spriteStand1}
              faceAccessoryImage={avatar.equippedItemMap?.FACE_ACCESSORY?.spriteStand1}
              handAccessoryImage={avatar.equippedItemMap?.HAND_ACCESSORY?.spriteStand1}
              skinImage={avatar.equippedItemMap?.SKIN?.spriteStand1}
              isSetEquipped={avatar.isAvatarReady}
            />
          </S.Avatar>

          <S.ContentWrap className="modal-body">
            <S.DetailInfo>
              <S.DetailInfoTitle>
                <img
                  src={auth.portrait || "/resources/img/icon_portrait.png"}
                  alt="profile"
                  style={{
                    marginLeft: "10px",
                    width: "30px",
                    borderRadius: "50%",
                  }}
                />
                <h4>{auth?.nickname || "-"}</h4>
                <span
                  className="badge rounded-pill"
                  css={css`
                    background: ${getUserLevelColor(avatar.level)};
                  `}
                >
                  Lv.{avatar.level}
                </span>
              </S.DetailInfoTitle>
              {/* <S.DetailInfoItem>
                <p>전공</p>
                <p>컴퓨터공학</p>
              </S.DetailInfoItem> */}
              {/* <S.DetailInfoItem>
                <p>인기도</p>
                <p>+ 15</p>
              </S.DetailInfoItem> */}
              <S.DetailInfoItem>
                <p>대표채널</p>
                {/* <p>{auth?.channelProfile?.channelName || "-"}</p> */}
                <div css={[S.styles.form, S.styles.select]}>
                  <button
                    type="button"
                    css={S.styles.dropdown}
                    className="btn border-0 bg-light"
                    data-bs-toggle="dropdown"
                  >
                    {selectedChannel.channelName ||
                      (myChannels && myChannels.length === 0
                        ? "가입한 채널이 없습니다 "
                        : "대표채널을 선택해 주세요.")}
                    <i className="bi bi-chevron-down m-0"></i>
                  </button>
                  <ul className="dropdown-menu border-0 p-0 shadow">
                    {myChannels.map((channel) => (
                      <li key={channel.channelId}>
                        <button
                          type="button"
                          className="dropdown-item"
                          onClick={() =>
                            setSelectedChannel({
                              channelId: channel.channelId,
                              channelName: channel.channelName,
                            })
                          }
                        >
                          {channel.channelName}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </S.DetailInfoItem>

              <S.DetailInfoItem>
                <div key={"introduction"} css={S.styles.input_form} className="form-group">
                  <label htmlFor={`eduenv-${id}-${"introduction"}`} className="form-label">
                    {t(camelToKebab("introduction"))}
                  </label>
                  <textarea
                    name={"introduction"}
                    id={`eduenv-${id}-${"introduction"}`}
                    className="form-control"
                    rows={form["introduction"].rows || 3}
                    placeholder={form["introduction"].placeholder}
                    value={form["introduction"].value || ""}
                    onChange={handleTextarea}
                  ></textarea>
                  {form["introduction"].error && (
                    <div css={S.styles.validation} className="form-validation">
                      <i className="bi bi-info-circle-fill text-danger"></i>
                      {t(form["introduction"].error)}
                    </div>
                  )}
                </div>
              </S.DetailInfoItem>
              <div css={S.styles.submit}>
                <button type="submit" className="btn btn-primary" onClick={handleForm}>
                  저장
                </button>
              </div>
              {/* <S.DetailInfoItem>
                <p>랭킹</p>
                <p style={{ color: "#0088ff" }}>12위</p>
              </S.DetailInfoItem> */}
            </S.DetailInfo>

            {/* <S.ButtonGroup>
              <S.PrimaryButton>장비</S.PrimaryButton>
              <S.PrimaryButton>귓속말</S.PrimaryButton>
              <S.ActivateButon>초대하기</S.ActivateButon>
            </S.ButtonGroup> */}
          </S.ContentWrap>
        </S.Wrapper>
      </S.ModalWapp>
    </div>
  );
}
