import usePopup from "../../../hooks/usePopup";
import { css } from "@emotion/react";
import { Fragment, MouseEvent, useEffect, useRef } from "react";
import { getQuestUrl } from "../../../utils/url";
import { getSubDomain } from "../../../utils/domain";
import { GoalList } from "../../../api/quest";
import { isClient } from "common/utils/common";
import { LEVEL_THEME } from "../../../constants/styles/theme";
import { styles } from "./styles";
import { useHistory } from "react-router-dom";

const PopupQuest = () => {
  const history = useHistory();
  const popup = usePopup();

  const subDomain = isClient() ? getSubDomain() : null;

  const containerElRef = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    const newState = [...popup.quest.queue];
    newState.shift();

    popup.quest.setQueue(newState);

    if (newState.length === 0) {
      popup.quest.close();
      return;
    }
  };

  useEffect(() => {
    if (!popup.quest.visible) {
      return;
    }

    containerElRef.current.classList.add("animate__bounceIn");
    containerElRef.current.classList.add("animate__faster");
  }, [popup.quest.visible]);

  return (
    <Fragment>
      {popup.quest.visible && (
        <Fragment>
          <div css={styles.backdrop} onClick={handleClose}></div>
          <div css={styles.wrapper}>
            <div
              ref={containerElRef}
              css={styles.container}
              className="border bg-white rounded-2 shadow-lg animate__animated"
            >
              <div css={styles.character}>
                {popup.quest.queue[0].quest.character === "DOLPHIN" && (
                  <img
                    src="/resources/svg/characters/character_dolphin_sit.svg"
                    css={styles.dolphin}
                  />
                )}
                {popup.quest.queue[0].quest.character === "CRAB" && (
                  <img src="/resources/svg/characters/character_crab_stand.svg" css={styles.crab} />
                )}
                {popup.quest.queue[0].quest.character === "TURTLE" && (
                  <img
                    src="/resources/svg/characters/character_turtle_stand.svg"
                    css={styles.turtle}
                  />
                )}
                {popup.quest.queue[0].quest.character === "NEMO" && (
                  <img src="/resources/svg/characters/character_nemo_stand.svg" css={styles.nemo} />
                )}
                {popup.quest.queue[0].quest.character === "SEAHORSE" && (
                  <img
                    src="/resources/svg/characters/character_seahorse_stand.svg"
                    css={styles.seahorse}
                  />
                )}
                {popup.quest.queue[0].quest.character === "SHARK" && (
                  <img
                    src="/resources/svg/characters/character_shark_stand.svg"
                    css={styles.shark}
                  />
                )}
              </div>
              <h5 css={styles.heading}>
                <span css={styles.badge} className="badge rounded-pill">
                  메인 퀘스트
                </span>
              </h5>
              <h4 css={styles.title}>
                {popup.quest.queue[0].quest.level && (
                  <Fragment>
                    <span
                      css={[
                        styles.level,
                        css`
                          color: ${LEVEL_THEME[popup.quest.queue[0].quest.level].colors.badge
                            .textForeground};
                          background-color: ${LEVEL_THEME[popup.quest.queue[0].quest.level].colors
                            .badge.background};
                        `,
                      ]}
                      className="badge rounded-pill"
                    >
                      LV. {popup.quest.queue[0].quest.level}
                    </span>
                  </Fragment>
                )}
                {popup.quest.queue[0].quest.title}
              </h4>
              <p css={styles.description} className="m-0">
                {popup.quest.queue[0].quest.description}
              </p>
              <div css={styles.content}>
                <ul className="m-0">
                  {popup.quest.queue[0].quest.goalList.map(
                    (goalRow: GoalList, goalIndex: number) => (
                      <li key={goalIndex}>
                        {goalRow.goalName}{" "}
                        <span className="d-inline-block fw-bold">
                          ({goalRow.completedCount} / {goalRow.goalCount})
                        </span>
                      </li>
                    ),
                  )}
                </ul>
              </div>
              <div css={styles.reward} className="bg-light rounded-pill">
                보상{" "}
                {popup.quest.queue[0].quest.rewardType === "POINT" && (
                  <Fragment>
                    <img src="/resources/img/animation_coin.png" />{" "}
                    {popup.quest.queue[0].quest.rewardCount} 포인트
                  </Fragment>
                )}
              </div>
              <div css={styles.footer}>
                <button type="button" className="btn btn-light" onClick={handleClose}>
                  닫기
                </button>
                <button
                  type="button"
                  className="btn btn-primary m-0"
                  onClick={(event: MouseEvent<HTMLButtonElement>) => {
                    // 큐 초기화
                    popup.quest.setQueue([]);
                    popup.quest.close();

                    history.push(getQuestUrl(popup.quest.queue[0].quest.goalList[0]));
                  }}
                  disabled={
                    !!subDomain || getQuestUrl(popup.quest.queue[0].quest.goalList[0]) === "/"
                  }
                >
                  <i className="bi bi-box-arrow-up-right"></i>이동하기
                </button>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default PopupQuest;
