import ErrorContent from "../../components/error/ErrorContent";
import Head from "../../core/Head";
import { Fragment } from "react";

/**
 * PAGE: 오류 페이지
 */
const Error = () => {
  return (
    <Fragment>
      <Head title="오류가 발생했습니다" />
      <ErrorContent />
    </Fragment>
  );
};

export default Error;
