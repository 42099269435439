/**
 * 권한이 포함되어 있는지 검사
 */

const hasPermission = (
  accessibleList: (
    | "ROLE_ADMIN"
    | "ROLE_USER"
    | "ROLE_TEACHER"
    | "ROLE_CHANNEL_MASTER"
    | "ROLE_CHANNEL_MANAGER"
    | "ROLE_CHANNEL_TEACHER"
    | "ROLE_CHANNEL_ADVANCED_LECTURER"
    | "ROLE_CHANNEL_ADVANCED"
    | "ROLE_CHANNEL_LECTURER"
    | "ROLE_CHANNEL_USER"
    | "ROLE_CHANNEL_VISITOR"
    | "ROLE_CHANNEL_PENDING"
    | "ROLE_CHANNEL_SUSPEND"
    | "ROLE_CHANNEL_BEN"
    | "ROLE_CHANNEL_JOIN_REJECTED"
  )[],
  roleList,
) => {
  let isEntered = false;

  if (roleList) {
    for (let i = 0; i < accessibleList.length; i++) {
      if (roleList.includes(accessibleList[i])) {
        isEntered = true;
        break;
      }
    }
  }

  return isEntered;
};

export { hasPermission };
