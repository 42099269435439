import { AnimationCounter } from "../../constants/animations/counter";
import { AnimationWave } from "../../constants/animations/wave";
import { SET_CONFETTI_VISIBLE, SET_COUNTER_STATE, SET_WAVE_STATE } from "../actions/animation";

export interface AnimationInitialState {
  counter: {
    state: AnimationCounter;
  };
  wave: {
    state: AnimationWave;
  };
  confetti: {
    visible: boolean;
  };
}

const initialState: AnimationInitialState = {
  counter: {
    state: "CLOSED",
  },
  wave: {
    state: "CLOSED",
  },
  confetti: {
    visible: false,
  },
};

// Counter 애니메이션 상태 변경
export const actionSetCounterState = (state: AnimationCounter) => ({
  type: SET_COUNTER_STATE,
  state,
});

// Wave 애니메이션 상태 변경
export const actionSetWaveState = (state: AnimationWave) => ({
  type: SET_WAVE_STATE,
  state,
});

export const actionSetConfettiVisible = (visible: boolean) => ({
  type: SET_CONFETTI_VISIBLE,
  visible,
});

const animation = (state = initialState, action) => {
  switch (action.type) {
    case SET_COUNTER_STATE:
      return {
        ...state,
        counter: {
          state: action.state,
        },
      };
    case SET_WAVE_STATE:
      return {
        ...state,
        wave: {
          state: action.state,
        },
      };
    case SET_CONFETTI_VISIBLE:
      return {
        ...state,
        confetti: {
          visible: action.visible,
        },
      };
    default:
      return state;
  }
};

export default animation;
