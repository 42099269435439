import { CommonReducer } from "../modules/reducers";
import { toast as toastify } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  ToastInitialState,
  ToastTheme,
  actionReadyToast,
  actionSetToastNotification,
} from "../modules/reducers/toast";

const useToast = () => {
  const dispatch = useDispatch();

  const { toast, theme, content, isReady }: ToastInitialState = useSelector(
    (state: CommonReducer) => state.toastReducer,
  );

  // Toast 인스턴스를 Redux에 저장
  const init = (toastEl: HTMLDivElement) => {
    if (typeof window !== "undefined") {
      import("bootstrap").then(({ Toast }) => {
        const toast = new Toast(toastEl);
        dispatch(actionReadyToast(toast));
      });
    }
  };

  const notification = (theme: ToastTheme, content: string) => {
    dispatch(actionSetToastNotification(theme, content));

    // dispatch 이후 실행되도록 Callback Queue에 담음
    return {
      show: () => setTimeout(() => show(), 0),
    };
  };

  const message = (content: JSX.Element | string) => {
    return {
      show: (options?: { type?: "info" | "success" | "warn" | "error"; config?: any }) => {
        if (options?.type) {
          toastify[options.type](content, options?.config && options.config);
          return;
        }

        toastify(content, options?.config && options.config);
      },
    };
  };

  const show = () => {
    if (toast) {
      toast.show();
    }
  };

  return {
    toast,
    theme,
    content,
    isReady,
    init: (toastEl: HTMLDivElement) => {
      init(toastEl);
    },
    notification: (theme: ToastTheme, content: string) => {
      return notification(theme, content);
    },
    message: (content: JSX.Element | string) => {
      return message(content);
    },
    show,
  };
};

export default useToast;
