import {
  ResponseCompetitionContent,
  ResponseCompetitionContentList,
  ResponseLeaveRoomMemberInfo,
  ResponseRoomMemberInfo,
  ResponseRoomMemberList,
  RoomCompetitionOptionInfo,
  RoomCompetitionOptionInfoCanJoinDuringCompetition,
  RoomCompetitionOptionInfoLimitTime,
  RoomCompetitionOptionInfoReservationTime,
  RoomCompetitionOptionInfoVisibleScore,
  RoomDetailInfo,
  RoomDetailResponse,
  RoomList,
  RoomMemberState,
} from "../../api/room";
import {
  CLEAR_ROOM,
  JOIN_ROOM_ID,
  REMOVE_ROOM_COMPETITION_CONTENT_LIST,
  SET_IS_DATA_READY,
  // READY_SOCKET,
  SET_IS_READY,
  SET_LEAVE_ROOM_MEMBER,
  SET_NEW_ROOM,
  SET_NEW_ROOM_MEMBER,
  SET_ROOM_COMPETITION_OPTION,
  SET_ROOM_COMPETITION_OPTION_CAN_JOIN_DURING_COMPETITION,
  SET_ROOM_COMPETITION_OPTION_LIMIT_TIME,
  SET_ROOM_COMPETITION_OPTION_RESERVATION_TIME,
  SET_ROOM_COMPETITION_OPTION_VISIBLE_SCORE,
  SET_ROOM_INFO,
  SET_ROOM_LIST,
  SET_ROOM_MEMBER_LIST,
  SET_ROOM_MEMBER_STATE,
  UPDATE_ROOM_COMPETITION_CONTENT_LIST,
} from "../actions/room";

export interface RoomInitialState {
  competitionOption: RoomCompetitionOptionInfo;
  roomInfo: RoomDetailInfo;
  roomList: RoomList[];
  roomMemberList: ResponseRoomMemberInfo[];
  contentList: ResponseCompetitionContent[];
  joinRoomId: string;
  isReady: boolean;
  isDataReady: boolean;
}

const initialState: RoomInitialState = {
  competitionOption: {
    limitTime: 60,
    visibleScore: true,
    reservationTime: null,
    canJoinDuringCompetition: false,
  },
  roomInfo: {
    name: "",
    comment: "",
    roomType: null,
    maximum: null,
    isPublic: true,
    password: null,
    // contentId: null,
  },
  roomList: [],
  roomMemberList: [],
  contentList: [],
  joinRoomId: "",
  isReady: false,
  isDataReady: false,
};

// const MAX_CHAT_SIZE = 100;

// room 접속한 roomId 관리
export const actionSetJoinRoomId = (joinRoomId: number) => ({
  type: JOIN_ROOM_ID,
  joinRoomId: joinRoomId,
});

// room 입장 후 정보 적용
export const actionSetRoomInfoDetail = (roomInfo: RoomDetailInfo) => ({
  type: SET_ROOM_INFO,
  roomInfo: roomInfo,
});

// room 입장 후 정보 적용
export const actionSetCompetitionOption = (competitionOption: RoomCompetitionOptionInfo) => ({
  type: SET_ROOM_COMPETITION_OPTION,
  competitionOption: competitionOption,
});

// room 입장 후 정보 적용
export const actionSetCompetitionOptionLimitTime = (
  competitionOptionLimitTime: RoomCompetitionOptionInfoLimitTime,
) => ({
  type: SET_ROOM_COMPETITION_OPTION_LIMIT_TIME,
  limitTime: competitionOptionLimitTime.limitTime,
});

// room 입장 후 정보 적용
export const actionSetCompetitionOptionVisibleScore = (
  competitionOptionVisibleScore: RoomCompetitionOptionInfoVisibleScore,
) => ({
  type: SET_ROOM_COMPETITION_OPTION_VISIBLE_SCORE,
  visibleScore: competitionOptionVisibleScore.visibleScore,
});

// room 입장 후 정보 적용
export const actionSetCompetitionOptionCanJoinDuringCompetition = (
  canJoinDuringCompetition: RoomCompetitionOptionInfoCanJoinDuringCompetition,
) => ({
  type: SET_ROOM_COMPETITION_OPTION_CAN_JOIN_DURING_COMPETITION,
  canJoinDuringCompetition: canJoinDuringCompetition.canJoinDuringCompetition,
});

// room 입장 후 정보 적용
export const actionSetCompetitionOptionReservationTime = (
  reservationTime: RoomCompetitionOptionInfoReservationTime,
) => ({
  type: SET_ROOM_COMPETITION_OPTION_RESERVATION_TIME,
  reservationTime: reservationTime.reservationTime,
});

// room 입장 후 정보 적용
export const actionSetUpdateRoomMemberState = (roomMemberState: RoomMemberState) => ({
  type: SET_ROOM_MEMBER_STATE,
  roomMemberState: roomMemberState,
});

// room 게임 입장 후 유저리스트
export const actionSetRoomList = (roomList: RoomList[]) => ({
  type: SET_ROOM_LIST,
  roomList: roomList,
});

// room 게임 입장 후 유저리스트
export const actionSetRoomMemberList = (roomMemberList: ResponseRoomMemberInfo[]) => ({
  type: SET_ROOM_MEMBER_LIST,
  roomMemberList: roomMemberList,
});

// room 게임 입장 후 유저리스트
export const actionSetNewRoomMember = (roomMember: ResponseRoomMemberInfo) => ({
  type: SET_NEW_ROOM_MEMBER,
  roomMember: roomMember,
});

// room 게임 입장 후 유저리스트
export const actionSetNewRoom = (room: RoomList) => ({
  type: SET_NEW_ROOM,
  room: room,
});

// room 유저 퇴장
export const actionSetLeaveRoomMember = (roomMember: ResponseLeaveRoomMemberInfo) => ({
  type: SET_LEAVE_ROOM_MEMBER,
  roomMember: roomMember,
});

// room 콘텐츠 삭제
export const actionRemoveCompetitionContentList = (index: number) => ({
  type: REMOVE_ROOM_COMPETITION_CONTENT_LIST,
  index: index,
});

// room 콘텐츠 업데이트
export const actionUpdateCompetitionContentList = (contentList: ResponseCompetitionContent[]) => ({
  type: UPDATE_ROOM_COMPETITION_CONTENT_LIST,
  contentList: contentList,
});

export const actionSetIsReady = (isReady: boolean) => ({
  type: SET_IS_READY,
  isReady,
});

export const actionSetIsDataReady = (isReady: boolean) => ({
  type: SET_IS_DATA_READY,
  isReady,
});

export const actionClearRoom = () => ({
  type: CLEAR_ROOM,
});

// // room 채팅 메세지
// export const actionSetChatMessage = (chatMessage: ReceiveChatMessage) => ({
//   type: CHAT_MESSAGE,
//   chatMessage: chatMessage,
// });

const room = (state = initialState, action) => {
  switch (action.type) {
    case JOIN_ROOM_ID:
      return {
        ...state,
        joinRoomId: action.joinRoomId,
      };
    // case READY_SOCKET:
    //   return {
    //     ...state,
    //     userList: action.userList,
    //   };
    case CLEAR_ROOM:
      return initialState;
    case SET_ROOM_INFO:
      return {
        ...state,
        roomInfo: action.roomInfo,
      };
    case SET_ROOM_LIST:
      return {
        ...state,
        roomList: action.roomList,
      };
    case SET_ROOM_MEMBER_LIST:
      return {
        ...state,
        roomMemberList: action.roomMemberList,
      };
    case SET_ROOM_MEMBER_STATE:
      const newRoomMemberList = structuredClone(
        state.roomMemberList.map((item: ResponseRoomMemberInfo) => {
          if (item.userId === action.roomMemberState.userId) {
            item.memberState = action.roomMemberState.memberState;
          }
        }),
      );

      return {
        ...state,
        roomMemberState: newRoomMemberList,
      };
    case SET_NEW_ROOM:
      const roomList = [action.room, ...state.roomList];

      return {
        ...state,
        roomList: roomList,
      };
    case SET_NEW_ROOM_MEMBER:
      // const newRoomMember = {
      //   type: SET_NEW_ROOM_MEMBER,
      //   roomMember: action.roomMember,
      // };
      const copyRoomMemberList = structuredClone(
        state.roomMemberList.filter((item) => item.userId !== action.roomMember.userId),
      );

      const updatedRoomMember = [...copyRoomMemberList, action.roomMember];

      return {
        ...state,
        roomMemberList: updatedRoomMember,
      };
    case SET_ROOM_COMPETITION_OPTION:
      // const updateRoomCompetitionContentList = state.roomMemberList.filter(
      //   (item) => item.userId !== action.userId,
      // );

      return {
        ...state,
        competitionOption: action.competitionOption,
      };
    case SET_ROOM_COMPETITION_OPTION_RESERVATION_TIME:
      return {
        ...state,
        competitionOption: {
          ...state.competitionOption,
          reservationTime: action.reservationTime,
        },
      };
    case SET_ROOM_COMPETITION_OPTION_LIMIT_TIME:
      // const updateRoomCompetitionContentList = state.roomMemberList.filter(
      //   (item) => item.userId !== action.userId,
      // );

      return {
        ...state,
        competitionOption: {
          ...state.competitionOption,
          limitTime: action.limitTime,
        },
      };
    case SET_ROOM_COMPETITION_OPTION_VISIBLE_SCORE:
      // const updateRoomCompetitionContentList = state.roomMemberList.filter(
      //   (item) => item.userId !== action.userId,
      // );

      return {
        ...state,
        competitionOption: {
          ...state.competitionOption,
          visibleScore: action.visibleScore,
        },
      };
    case SET_ROOM_COMPETITION_OPTION_CAN_JOIN_DURING_COMPETITION:
      // const updateRoomCompetitionContentList = state.roomMemberList.filter(
      //   (item) => item.userId !== action.userId,
      // );

      return {
        ...state,
        competitionOption: {
          ...state.competitionOption,
          canJoinDuringCompetition: action.canJoinDuringCompetition,
        },
      };
    case SET_LEAVE_ROOM_MEMBER:
      const leavedRoomMemberList = state.roomMemberList.filter(
        (item) => item.userId !== action.roomMember.userId,
      );

      return {
        ...state,
        roomMemberList: leavedRoomMemberList,
      };
    case UPDATE_ROOM_COMPETITION_CONTENT_LIST:
      // const updateRoomCompetitionContentList = state.roomMemberList.filter(
      //   (item) => item.userId !== action.userId,
      // );

      return {
        ...state,
        contentList: action.contentList,
      };
    case REMOVE_ROOM_COMPETITION_CONTENT_LIST:
      const removedRoomCompetitionContentList = state.contentList.filter(
        (item, index) => !(index === action.index),
      );

      return {
        ...state,
        contentList: removedRoomCompetitionContentList,
      };

    case SET_IS_READY:
      return {
        ...state,
        isReady: action.isReady,
      };
    // case CHAT_MESSAGE:
    //   const newMessage = {
    //     type: CHAT_MESSAGE,
    //     content: action.chatMessage.content,
    //   };

    //   const copyChatMessages = structuredClone(state.chatMessage);
    //   const updatedChatMessages = [...copyChatMessages, newMessage];

    //   if (updatedChatMessages.length > MAX_CHAT_SIZE) {
    //     updatedChatMessages.shift();
    //   }
    //   return {
    //     ...state,
    //     chatMessage: updatedChatMessages,
    //   };

    default:
      return state;
  }
};

export default room;
