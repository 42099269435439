import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "../shared/request";

export interface GetNotificationRequest {
  params: { page: number };
}

export interface GetNotificationResponse {
  notificationList: NotificationList[];
  pageCount: number;
  totalCount: number;
}

export interface NotificationList {
  notificationId: string;
  title: string;
  link: string;
  message: string;
  checked: boolean;
  createdDate: string;
  type: "NOTIFICATION_ALL" | "NOTIFICATION" | "REWARD" | "QUEST" | "LEVEL_UP";
}

/**
 * API:
 * 알림
 */
export const getNotification = (params: GetNotificationRequest) => {
  return axiosInstance
    .get<GetNotificationResponse>("/v1/users/notifications", params)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 알림 정보
 */
export const getNotificationInfo = (notificationId: string) => {
  return axiosInstance
    .get<NotificationList>(`/v1/users/notifications/${notificationId}`)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 해당 알림 읽음 처리
 */
export const readNotification = (notificationId: string) => {
  return axiosInstance
    .put<unknown>(`/v1/users/notifications/${notificationId}/check`)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 해당 알림 삭제
 */
export const deleteNotification = (notificationId: string) => {
  return axiosInstance
    .delete<unknown>(`/v1/users/notifications/${notificationId}`)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};
