const BREAK_POINTS = {
  min: {
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    xxl: "1400px",
  },
  max: {
    sm: "575.98px",
    md: "767.98px",
    lg: "991.98px",
    xl: "1199.98px",
    xxl: "1399.98px",
  },
};

const MEDIA_QUERIES = {
  min: {
    sm: `@media (min-width: ${BREAK_POINTS.min.sm})`,
    md: `@media (min-width: ${BREAK_POINTS.min.md})`,
    lg: `@media (min-width: ${BREAK_POINTS.min.lg})`,
    xl: `@media (min-width: ${BREAK_POINTS.min.xl})`,
    xxl: `@media (min-width: ${BREAK_POINTS.min.xxl})`,
  },
  max: {
    sm: `@media (max-width: ${BREAK_POINTS.max.sm})`,
    md: `@media (max-width: ${BREAK_POINTS.max.md})`,
    lg: `@media (max-width: ${BREAK_POINTS.max.lg})`,
    xl: `@media (max-width: ${BREAK_POINTS.max.xl})`,
    xxl: `@media (max-width: ${BREAK_POINTS.max.xxl})`,
  },
  only: {
    // xs는 MEDIA_QUERIES.max.sm 이용
    sm: `@media (min-width: ${BREAK_POINTS.min.sm}) and (max-width: ${BREAK_POINTS.max.md})`,
    md: `@media (min-width: ${BREAK_POINTS.min.md}) and (max-width: ${BREAK_POINTS.max.lg})`,
    lg: `@media (min-width: ${BREAK_POINTS.min.lg}) and (max-width: ${BREAK_POINTS.max.xl})`,
    xl: `@media (min-width: ${BREAK_POINTS.min.xl}) and (max-width: ${BREAK_POINTS.max.xxl})`,
    // xxl은 MEDIA_QUERIES.min.xxl 이용
  },
};

const getBreakpoint = () => {
  if (window.matchMedia(`(min-width: ${BREAK_POINTS.min.xxl})`).matches) {
    return "xxl";
  }

  if (
    window.matchMedia(
      `(min-width: ${BREAK_POINTS.min.xl}) and (max-width: ${BREAK_POINTS.max.xxl})`,
    ).matches
  ) {
    return "xl";
  }

  if (
    window.matchMedia(`(min-width: ${BREAK_POINTS.min.lg}) and (max-width: ${BREAK_POINTS.max.xl})`)
      .matches
  ) {
    return "lg";
  }

  if (
    window.matchMedia(`(min-width: ${BREAK_POINTS.min.md}) and (max-width: ${BREAK_POINTS.max.lg})`)
      .matches
  ) {
    return "md";
  }

  if (
    window.matchMedia(`(min-width: ${BREAK_POINTS.min.sm}) and (max-width: ${BREAK_POINTS.max.md})`)
      .matches
  ) {
    return "sm";
  }

  return "xs";
};

export { BREAK_POINTS, MEDIA_QUERIES, getBreakpoint };
