import { SET_IS_PROGRESS } from "../actions/payment";

export interface PaymentInitialState {
  isProgress: boolean;
}

const initialState: PaymentInitialState = {
  isProgress: false,
};

export const actionSetIsProgress = (isProgress: boolean) => ({
  type: SET_IS_PROGRESS,
  isProgress,
});

const payment = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_PROGRESS:
      return {
        isProgress: action.isProgress,
      };
    default:
      return state;
  }
};

export default payment;
