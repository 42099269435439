import { READY_TOAST, SET_TOAST_NOTIFICATION } from "../actions/toast";
import { Toast } from "bootstrap";

export type ToastTheme = "primary" | "secondary" | "success" | "danger" | "warning" | "info";

export interface ToastInitialState {
  toast: Toast;
  theme: ToastTheme;
  content: string;
  isReady: boolean;
}

const initialState: ToastInitialState = {
  toast: null,
  theme: "primary",
  content: "",
  isReady: false,
};

// Toast 설정
export const actionReadyToast = (toast: Toast) => ({
  type: READY_TOAST,
  toast,
});

// Toast 테마, 메시지 설정
export const actionSetToastNotification = (theme: ToastTheme, content: string) => ({
  type: SET_TOAST_NOTIFICATION,
  theme,
  content,
});

const toast = (state = initialState, action: any) => {
  switch (action.type) {
    case READY_TOAST:
      return {
        ...state,
        toast: action.toast,
        isReady: true,
      };
    case SET_TOAST_NOTIFICATION:
      return {
        ...state,
        theme: action.theme,
        content: action.content,
      };
    default:
      return state;
  }
};

export default toast;
