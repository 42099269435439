import useAuth from "../../hooks/useAuth";
// import useAvator from "../../hooks/useAvator";
import { EquippedItems, useAvator } from "../../../../../common/src/hooks/useAvator";

import { AxiosError } from "axios";
import {
  getAvatorLevelExperience,
  GetAvatorLevelExperienceResponse,
  getEquippedItems,
  ResponseEquippedItems,
} from "../../api/avator";
import { useEffect } from "react";

const AvatorFetcher = () => {
  const auth = useAuth();
  const avatar = useAvator();

  useEffect(() => {
    if (!auth.isReady || avatar.isReady) {
      return;
    }

    if (auth.state !== "VALID") {
      return;
    }

    getAvatorLevelExperience()
      .then((response: GetAvatorLevelExperienceResponse) => {
        avatar.init(response.level, response.levelExp, response.requiredLevelPoint);
      })
      .catch((error: AxiosError) => {});
  }, [auth.isReady, avatar.isReady]);

  useEffect(() => {
    if (!auth.isReady || !avatar.isReady || avatar.isAvatarReady) {
      return;
    }

    if (auth.state !== "VALID") {
      return;
    }

    getEquippedItems()
      .then((response: ResponseEquippedItems) => {
        console.log("equipped : ", response);

        const equippedItemsMap = response.equippedUserItemList.reduce((acc, item) => {
          return { ...acc, [item.itemCategory]: item };
        }, {});

        avatar.updateEquippedItems({
          HAT: equippedItemsMap["HAT"],
          TOP: equippedItemsMap["TOP"],
          BOTTOM: equippedItemsMap["BOTTOM"],
          ONE_PIECE: equippedItemsMap["ONE_PIECE"],
          CAPE: equippedItemsMap["CAPE"],
          SHOES: equippedItemsMap["SHOES"],
          ACCESSORY: equippedItemsMap["ACCESSORY"],
          SKIN: equippedItemsMap["SKIN"],
          NECKLACE: equippedItemsMap["NECKLACE"],
          EARRING: equippedItemsMap["EARRING"],
          HAIR: equippedItemsMap["HAIR"],
          FACE: equippedItemsMap["FACE"],
          FACE_ACCESSORY: equippedItemsMap["FACE_ACCESSORY"],
        } as EquippedItems);
      })
      .catch((error: AxiosError) => {});
  }, [auth.isReady, avatar.isReady]);

  return null;
};

export default AvatorFetcher;
