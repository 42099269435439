import { css } from "@emotion/react";
import { MEDIA_QUERIES } from "common/constants/styles/responsive";

const styles = {
  wrapper: css`
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    z-index: 100;
    width: 100%;
    height: 100%;
  `,
  container: css`
    display: flex;
    align-items: center;
    background-color: #303030;
    padding: 1.875rem;
    width: 23.75rem;

    ${MEDIA_QUERIES.max.sm} {
      margin-right: 1rem;
      margin-left: 1rem;
      width: 100%;
    }
  `,
  title: css`
    margin-bottom: 0.625rem;
    font-size: 1.5625rem;
  `,
  description: css`
    color: rgba(255, 255, 255, 0.5);
    font-size: 0.9375rem;
  `,
  coin: css`
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(/resources/img/animation_shiny.png);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: auto 11.625rem;
    width: 11.625rem;
    height: 11.625rem;
    animation: rotate-animation 15s linear infinite;

    img {
      width: 5.625rem;
      height: 5.625rem;
    }

    @keyframes rotate-animation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  `,
  benefit: css`
    margin-top: -2.125rem;

    span {
      color: #3ba9ff;
      font-size: 0.875rem;
    }
  `,
  button: css`
    margin-top: 1.875rem;
    width: 12.5rem;
  `,
};

export { styles };
