import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "../shared/request";
import { ChannelMemberRole } from "../constants/channels";

export type Gender = "NOT_SELECTED" | "MAN" | "WOMAN";
export interface GetUserInfoResponse {
  email: string;
  level: number;
  nickname: string;
  name: string;
  userId: string;
  profile: string;
  state: UserState;
  verifiedState: VerifiedState;
  subscribeList: SubscriptionList[];
  userRoleList: RoleList[];
  signupPath: SignupPath;
  gender: Gender;
  channelProfile: ChannelDto | null;
}

export type ChannelDto = {
  channelDomain: string;
  channelId: number | null;
  channelLogoUrl: string;
  channelName: string;
};

export type UserState = "VALID" | "PENDING" | "INVALID";

export type VerifiedState = "VERIFIED" | "UNVERIFIED";

export type SubscriptionList = "DOLPHIN_PRO";

export type RoleList = "ROLE_USER" | "ROLE_ADMIN" | "ROLE_TEACHER";

export type SignupPath = "DEFAULT" | "GOOGLE" | "KAKAO" | "NAVER";

export interface GetUserChannelRoleResponse {
  channelNickname: string;
  channelMemberRole: ChannelMemberRole;
}

export interface SignoutRequest {
  refreshId: string;
}

export interface SigninRequest {
  email: string;
  password: string;
}

export interface SigninResponse {
  headers: {
    access_token?: string;
    refresh_id?: string;
    dolphin_refresh_id?: string;
  };
  data: {
    verificationId: number;
    email?: string;
  };
}

export interface SignupRequest {
  email: string;
  nickname: string;
  name: string;
  password: string;
}
export interface ChannelSignupRequest {
  channelEmailId: string;
  channelCode: string;
  nickname: string;
  name: string;
  password: string;
}

export interface VerifyCodeRequest {
  email: string;
  verificationCode: string;
}

export interface VerifyCodeResponse {
  [key: string]: string;
  access_token?: string;
  refresh_id?: string;
  dolphin_refresh_id?: string;
}

export interface SignupSocialRequest {
  verificationId: number;
  email: string;
  nickname: string;
  name: string;
}

export interface SignupSocialResponse {
  [key: string]: string;
  access_token?: string;
  refresh_id?: string;
  dolphin_refresh_id?: string;
}

export interface VerifyFindPasswordRequest {
  email: string;
}

export interface FindPasswordRequest {
  email: string;
  verificationCode: string;
  password: string;
}

export interface ChangePasswordRequest {
  password: string;
  newPassword: string;
}

export interface VerifyWithdrawRequest {
  password: string;
}

export interface WithdrawRequest {
  verificationCode: string;
}

export interface IdentifyResponse {
  token_version_id: string;
  enc_data: string;
  integrity_value: string;
}

/**
 * API:
 * 사용자 정보
 */
export const getUserInfo = () => {
  return axiosInstance
    .get<GetUserInfoResponse>("/v1/users/info")
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 채널 > 사용자 등급 가져오기
 */
export const getUserChannelRole = (channelId: number) => {
  return axiosInstance
    .get<GetUserChannelRoleResponse>(`/v1/channels/${channelId}/members/roles`)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 로그아웃
 */
export const signout = (payload: SignoutRequest) => {
  return axiosInstance
    .post<unknown>("/v1/users/logout", payload)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 이메일 로그인
 */
export const signin = (payload: SigninRequest) => {
  return axiosInstance
    .post<SigninResponse>("/v1/users/login", payload)
    .then((response: AxiosResponse) => {
      return { headers: response.headers, data: response.data };
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 소셜 로그인 (구글)
 */
export const signinGoogle = (code: string) => {
  return axiosInstance
    .get<SigninResponse>("/v1/verifications/socials/google", { params: { code } })
    .then((response: AxiosResponse) => {
      return { headers: response.headers, data: response.data };
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 소셜 로그인 (카카오)
 */
export const signinKakao = (code: string) => {
  return axiosInstance
    .get<SigninResponse>("/v1/verifications/socials/kakao", { params: { code } })
    .then((response: AxiosResponse) => {
      return { headers: response.headers, data: response.data };
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 소셜 로그인 (네이버)
 */
export const signinNaver = (code: string) => {
  return axiosInstance
    .get<SigninResponse>("/v1/verifications/socials/naver", { params: { code } })
    .then((response: AxiosResponse) => {
      return { headers: response.headers, data: response.data };
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 이메일 회원가입
 */
export const signup = (payload: SignupRequest) => {
  return axiosInstance
    .post<unknown>("/v1/verifications/users", payload)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 이메일 회원가입
 */
export const channelSignup = (payload: ChannelSignupRequest) => {
  return axiosInstance
    .post<unknown>("/v1/verifications/users/channels", payload)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 이메일 회원가입 > 인증코드 검사 (최종)
 */
export const verifyCode = (payload: VerifyCodeRequest) => {
  return axiosInstance
    .post<VerifyCodeResponse>("/v1/verifications/signup", payload)
    .then((response: AxiosResponse) => {
      return response.headers;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 소셜 회원가입
 */
export const signupSocial = (payload: SignupSocialRequest) => {
  return axiosInstance
    .put<SignupSocialResponse>("/v1/verifications/socials/users", payload)
    .then((response: AxiosResponse) => {
      return response.headers;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 비밀번호 찾기 > 인증 코드 발송
 */
export const verifyFindPassword = (payload: VerifyFindPasswordRequest) => {
  return axiosInstance
    .post<unknown>("/v1/verifications/passwords/reset", payload)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 비밀번호 찾기 > 완료
 */
export const findPassword = (payload: FindPasswordRequest) => {
  return axiosInstance
    .put<unknown>("/v1/verifications/passwords/reset", payload)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 비밀번호 변경
 */
export const changePassword = (payload: ChangePasswordRequest) => {
  return axiosInstance
    .put<unknown>("/v1/users/password", payload)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 회원 탈퇴 > 인증 코드 발송
 */
export const verifyWithdraw = (payload: VerifyWithdrawRequest) => {
  return axiosInstance
    .post<string>("/v1/users/verifications/withdraw", payload)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 회원 탈퇴 > 완료
 */
export const withdraw = (payload: WithdrawRequest) => {
  return axiosInstance
    .put<unknown>("/v1/users/withdraw", payload)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 본인인증
 */
export const identify = () => {
  return axiosInstance
    .post<IdentifyResponse>("/v1/users/verifications/phone")
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};
