import { Helmet } from "react-helmet-async";

export interface HeadProps {
  title: string;
  description?: string;
  image?: string;
}

const Head = (props: HeadProps) => {
  const { title, description, image } = props;

  return (
    <Helmet>
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="EduEnv" />
      <meta property="og:locale" content="ko-KR" />
      <meta property="og:title" content={title} />
      <meta
        property="og:description"
        content={description || "자유롭게 성장할 수 있는 온라인 교육환경을 만듭니다."}
      />
      <meta
        property="og:image"
        content={image || "https://eduenv.com/resources/img/eduenv_brand.jpg"}
      />
      <title>{title}</title>
    </Helmet>
  );
};

export default Head;
