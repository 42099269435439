import AnimationHandler from "../AnimationHandler";
import AvatorFetcher from "../AvatorFetcher";
import NotificationFetcher from "../NotificationFetcher";
import PointFetcher from "../PointFetcher";
import PopupPremiumReqired from "../../components/ui/PopupPremiumReqired";
import ReactDOMServer from "react-dom/server";
import Swal, { SweetAlertResult } from "sweetalert2";
import ToastNotification from "../../components/ui/ToastNotification";
import QuestFetcher from "../QuestFetcher";

import useAuth from "../../hooks/useAuth";
import useChannel from "../../hooks/useChannel";
import useNotification from "../../hooks/useNotification";
import useRequest from "../../hooks/useRequest";
import { API_URL, CLIENT_URL } from "../../constants/urls";
import { AxiosError } from "axios";
import { Fragment, useEffect } from "react";
import { getSubDomain } from "../../utils/domain";
import { isClient } from "common/utils/common";
import { ServerError } from "../../constants/errors";
import { setAxiosInstance } from "../../utils/request";
import {
  GetChannelInfoResponse,
  GetChannelInternalInfoResponse,
  GetChannelMenuResponse,
  getChannelInfo,
  getChannelInternalInfo,
  getChannelMenu,
} from "../../api/channel";
import PopupReward from "../../components/ui/PopupReward";
import PopupQuest from "../../components/ui/PopupQuest";
import PopupUserClickProfile from "../../components/ui/PopupUserClickProfile";
import PopupUserDetailProfile from "../../components/ui/PopupUserDetailProfile";
import { GetSavedChannelPluginResponse, getChannelPlugin } from "../../api/channel-setting";

const Ready = () => {
  const auth = useAuth();
  const channel = useChannel();
  const notification = useNotification();
  const request = useRequest();

  const subDomain = isClient() ? getSubDomain() : null;

  const displayError = (message?: string) => {
    Swal.close();
    Swal.fire({
      title: "알림",
      html: ReactDOMServer.renderToString(
        <Fragment>
          {message || (
            <Fragment>
              예기치 못한 오류가 발생했습니다.
              <br />
              궁금하신 사항은 관리자에게 문의해 주세요.
              <br />
              (team@eduenv.com)
            </Fragment>
          )}
        </Fragment>,
      ),
      showCloseButton: true,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: "닫기",
      customClass: "swal2-sm swal2-warning",
    }).then((result: SweetAlertResult) => {
      setTimeout(() => {
        window.location.href = CLIENT_URL;
      }, 150);
    });
  };

  const initChannel = async () => {
    let channelId = null;

    /**
     * Step 1. 채널 내부 정보 가져오기 (ID, Role)
     */
    await getChannelInternalInfo(subDomain)
      .then((response: GetChannelInternalInfoResponse) => {
        setAxiosInstance({
          channelId: String(response.channelId),
        });

        channel.setRole(response.channelRole);

        channelId = response.channelId;
      })
      .catch((error: AxiosError<ServerError>) => {
        switch (error.response.data.errorCode) {
          case "CHANNEL_NOT_FOUND":
            displayError(error.response.data.message);
            break;
          default:
            displayError();
        }
        return Promise.reject(error);
      });

    /**
     * Step 2. 채널 정보 가져오기 (채널 이름, 로고, 배너 등)
     */
    await getChannelInfo(channelId)
      .then((response: GetChannelInfoResponse) => {
        channel.setInfo(response);
      })
      .catch((error: AxiosError) => {
        displayError();
        return Promise.reject(error);
      });

    /**
     * Step 3. 채널 게시판 목록 가져오기
     */
    await getChannelMenu(channelId)
      .then((response: GetChannelMenuResponse) => {
        channel.setMenu(response.boardItemList);
      })
      .catch((error: AxiosError) => {
        displayError();
        return Promise.reject(error);
      });

    /**
     * Step 4. 채널 플러그인 목록 가져오기
     */
    await getChannelPlugin(channelId)
      .then((response: GetSavedChannelPluginResponse) => {
        channel.setChannelPlugin(response.pluginList);
      })
      .catch((error: AxiosError) => {
        displayError();
        return Promise.reject(error);
      });

    channel.setIsReady(true);
    request.init();
  };

  // useEffect(() => {
  //   if (!channel.isReady) {
  //     return;
  //   }

  //   if (auth.state === "VALID") {
  //     enquiry.boot(channel.pluginMap?.CHANNEL_TALK.pluginKey, {
  //       profile: {
  //         userId: auth.userId,
  //         name: auth.name,
  //         nickname: auth.nickname,
  //         email: auth.email,
  //       },
  //     });
  //   } else {
  //     enquiry.boot(channel.pluginMap?.CHANNEL_TALK.pluginKey, {});
  //   }

  //   return () => {
  //     enquiry.shutdown();
  //   };
  // }, [channel.isReady]);

  useEffect(() => {
    // 로그아웃 시 소켓 연결 방지
    if (window.location.pathname.includes("/signout")) {
      return;
    }

    if (!request.isReady) {
      setAxiosInstance({
        baseURL: API_URL,
      });

      if (subDomain) {
        initChannel();
        return;
      }

      request.init();
      return;
    }

    if (auth.isReady) {
      if (auth.state === "VALID") {
        // 실시간 알림 연결
        notification.connect();
      } else {
        // 실시간 알림 연결 해제
        notification.disconnect();
      }

      return;
    }

    auth.init();
  }, [request.isReady, auth.isReady]);

  return (
    <Fragment>
      {auth.isReady && (
        <Fragment>
          <ToastNotification />
          <AnimationHandler />
          <AvatorFetcher />
          <PointFetcher />

          <NotificationFetcher />
          <QuestFetcher />
          <PopupUserDetailProfile />
          <PopupUserClickProfile />
          <PopupPremiumReqired />
          <PopupReward />
          <PopupQuest />
        </Fragment>
      )}
    </Fragment>
  );
};

export default Ready;
