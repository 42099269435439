/**
 * 프레임 유틸리티
 */
export class FrameUtil {
  fps: number;
  frame: number;
  time: number;

  constructor(fps: number) {
    this.fps = fps;
    this.frame = 0;
  }

  isUpdate(t: number) {
    if (!this.time) {
      this.time = t;
    }

    if (1000 / this.fps >= t - this.time) {
      return false;
    }
    this.time = t;

    return true;
  }

  getFrame(t: number) {
    if (this.isUpdate(t)) {
      this.frame += 1;
    }

    return this.frame;
  }
}

/**
 * 박스 크기에 맞게 텍스트를 자른 배열 가져오기
 */
export const getTextArrayForFitWidth = (
  ctx: CanvasRenderingContext2D,
  text: string,
  fontSize: number,
  fitWidth: number,
) => {
  let result = [];

  const measure = (row: number, subText: string) => {
    for (let i = 1; i <= subText.length; i++) {
      const str = subText.substring(0, i);
      const fontWidth = ctx.measureText(str).width;

      if (fontWidth > fitWidth) {
        result.push(subText.substring(0, i - 1));
        measure(row + 1, subText.substring(i - 1));
        return;
      }
    }

    result.push(subText);
  };

  ctx.save();
  ctx.font = `${fontSize}px Nanum Gothic`;

  measure(0, text);

  ctx.restore();

  return result;
};

/**
 * 두점 사이 거리 가져오기
 */
export const getDistance = (x1: number, y1: number, x2: number, y2: number) =>
  Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
