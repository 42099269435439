import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "../shared/request";

export interface GetPointResponse {
  point: number;
}

/**
 * API:
 * 포인트
 */
export const getPoint = () => {
  return axiosInstance
    .get<GetPointResponse>("/v1/users/points")
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};
