import { CHANGE_LANGUAGE } from "../actions/language";
import { UILanguage } from "../../constants/languages";

export interface LanguageInitialState {
  language: UILanguage;
}

const initialState: LanguageInitialState = {
  language: "KO",
};

// 언어 변경
export const actionChangeLanguage = (language: UILanguage) => ({
  type: CHANGE_LANGUAGE,
  language,
});

const language = (state = initialState, action: any) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.language,
      };
    default:
      return state;
  }
};

export default language;
