import { Client } from "@stomp/stompjs";
import { upperSnakeToCamel } from "common/utils/format";
import {
  READY_SOCKET,
  SET_CLIENT,
  SOCKET_CONNECTED,
  SOCKET_CONNECTING,
  SOCKET_DISCONNECTED,
  SOCKET_PENDING,
} from "../actions/socket";

export interface SocketInitialState {
  notification: {
    client: Client;
    token: string;
    state: SocketState;
    attemptCount: number;
  };
  room: {
    client: Client;
    token: string;
    state: SocketState;
    attemptCount: number;
  };
  world: {
    client: Client;
    token: string;
    state: SocketState;
    attemptCount: number;
  };
}

type SocketState = "PENDING" | "CONNECTING" | "CONNECTED" | "DISCONNECTED" | "RECONNECT";

export type SocketTarget = "NOTIFICATION" | "WORLD" | "ROOM";

const initialState: SocketInitialState = {
  notification: {
    client: null,
    token: "",
    state: "PENDING",
    attemptCount: 0,
  },
  room: {
    client: null,
    token: "",
    state: "PENDING",
    attemptCount: 0,
  },
  world: {
    client: null,
    token: "",
    state: "PENDING",
    attemptCount: 0,
  },
};

export const actionSetClient = ({ target, client }: { target: SocketTarget; client: any }) => ({
  type: SET_CLIENT,
  target,
  client,
});

export const actionReadySocket = ({ target }: { target: SocketTarget }) => ({
  type: READY_SOCKET,
  target,
});

export const actionSocketPending = ({ target }: { target: SocketTarget }) => ({
  type: SOCKET_PENDING,
  target,
});

export const actionSocketConnecting = ({
  target,
  token,
}: {
  target: SocketTarget;
  token: string;
}) => ({
  type: SOCKET_CONNECTING,
  target,
  token,
});

export const actionSocketConnected = ({ target }: { target: SocketTarget }) => ({
  type: SOCKET_CONNECTED,
  target,
});

export const actionSocketDisconnect = ({ target }: { target: SocketTarget }) => ({
  type: SOCKET_DISCONNECTED,
  target,
});

const socket = (state = initialState, action) => {
  switch (action.type) {
    case SET_CLIENT:
      return {
        ...state,
        [upperSnakeToCamel(action.target)]: {
          ...state[upperSnakeToCamel(action.target)],
          client: action.client,
        },
      };
    case READY_SOCKET:
      return {
        ...state,
        [upperSnakeToCamel(action.target)]: initialState[upperSnakeToCamel(action.target)],
      };
    case SOCKET_PENDING:
      return {
        ...state,
        [upperSnakeToCamel(action.target)]: {
          ...initialState,
          state: "PENDING",
        },
      };
    case SOCKET_CONNECTING:
      return {
        ...state,
        [upperSnakeToCamel(action.target)]: {
          client: null,
          token: action.token,
          state: "CONNECTING",
          attemptCount: 0,
        },
      };
    case SOCKET_CONNECTED:
      return {
        ...state,
        [upperSnakeToCamel(action.target)]: {
          ...state[upperSnakeToCamel(action.target)],
          state: "CONNECTED",
          attemptCount: 0,
        },
      };
    case SOCKET_DISCONNECTED:
      return {
        ...state,
        [upperSnakeToCamel(action.target)]: {
          ...initialState,
          state: "DISCONNECTED",
        },
      };
    default:
      return state;
  }
};

export default socket;
