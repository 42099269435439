export type Character = "DOLPHIN" | "CRAB" | "TURTLE" | "NEMO" | "SEAHORSE" | "SHARK";

const CHARACTER_THUMBNAIL = {
  DOLPHIN: {
    src: "/resources/img/characters/character_thumbnail_dolphin.png",
  },
  CRAB: {
    src: "/resources/img/characters/character_thumbnail_crab.png",
  },
  TURTLE: {
    src: "/resources/img/characters/character_thumbnail_turtle.png",
  },
  NEMO: {
    src: "/resources/img/characters/character_thumbnail_nemo.png",
  },
  SEAHORSE: {
    src: "/resources/img/characters/character_thumbnail_seahorse.png",
  },
  SHARK: {
    src: "/resources/img/characters/character_thumbnail_shark.png",
  },
};

const LEVEL_THEME = {
  1: {
    colors: {
      badge: {
        textForeground: "#ff4376",
        background: "#ffe2ea",
      },
    },
  },
  2: {
    colors: {
      badge: {
        textForeground: "#ff4376",
        background: "#ffe2ea",
      },
    },
  },
  3: {
    colors: {
      badge: {
        textForeground: "#ffa304",
        background: "#ffefcb",
      },
    },
  },
  4: {
    colors: {
      badge: {
        textForeground: "#ffa304",
        background: "#ffefcb",
      },
    },
  },
  5: {
    colors: {
      badge: {
        textForeground: "#38aa10",
        background: "#dff8d9",
      },
    },
  },
  6: {
    colors: {
      badge: {
        textForeground: "#38aa10",
        background: "#dff8d9",
      },
    },
  },
  7: {
    colors: {
      badge: {
        textForeground: "#44adff",
        background: "#e9f3ff",
      },
    },
  },
  8: {
    colors: {
      badge: {
        textForeground: "#44adff",
        background: "#e9f3ff",
      },
    },
  },
  9: {
    colors: {
      badge: {
        textForeground: "#7e3bb8",
        background: "#e5d5fe",
      },
    },
  },
  10: {
    colors: {
      badge: {
        textForeground: "#7e3bb8",
        background: "#e5d5fe",
      },
    },
  },
  11: {
    colors: {
      badge: {
        textForeground: "#ff4376",
        background: "#ffe2ea",
      },
    },
  },
  12: {
    colors: {
      badge: {
        textForeground: "#ff4376",
        background: "#ffe2ea",
      },
    },
  },
  13: {
    colors: {
      badge: {
        textForeground: "#ffa304",
        background: "#ffefcb",
      },
    },
  },
  14: {
    colors: {
      badge: {
        textForeground: "#ffa304",
        background: "#ffefcb",
      },
    },
  },
  15: {
    colors: {
      badge: {
        textForeground: "#38aa10",
        background: "#dff8d9",
      },
    },
  },
};

export { CHARACTER_THUMBNAIL, LEVEL_THEME };
