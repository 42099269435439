import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "../shared/request";
import { ChannelDto } from "./auth";
import { EquippedItem } from "common/hooks/useAvator";

export type EquippedItemForProfile = Pick<
  EquippedItem,
  "itemCategory" | "sprite" | "spriteStand1" | "thumbnail" | "name"
  // | "equippedUserItemId" | "itemId" | "name" |
>;

export const DEFAULT_USER_PROFILE_IMAGE = "/resources/img/icon_portrait.png";
export interface GetUserProfileInfoResponse {
  introduction: string;
  profile: string;
  channelProfile: ChannelDto;
  nickname: string;
  level: number;
  gender: "MAN" | "WOMAN";
  equippedUserItemList: EquippedItemForProfile[];
}

export interface GetUserInfoByNickname {
  userId: string;

  profile: string;

  nickname: string;
}

export type UserMusicState = "PLAY" | "STOP";

export interface UpdateUserMusicStateRequest {
  musicState: UserMusicState;
}

export interface UpdatePortraitResponse {
  url: string;
}

export type MyChannelList = {
  channelDomain: string;
  channelId: number;
  channelLogoUrl: string;
  channelName: string;
};

export interface GetMyPageInfoResponse {
  channelProfile: MyChannelList;
  introduction: string;
}

export interface UpdateIntroductionRequest {
  introduction: string;
}

/**
 * API:
 * 사용자의 음악 재생 상태 가져오기
 */
export const getUserMusicState = () => {
  return axiosInstance
    .get<UserMusicState>("/v1/users/musicstates")
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 사용자의 음악 재생 상태 업데이트
 */
export const updateUserMusicState = (payload: UpdateUserMusicStateRequest) => {
  return axiosInstance
    .put<unknown>("/v1/users/musicstates", payload)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 프로필 사진 업로드
 */
export const updatePortrait = (formData) => {
  return axiosInstance
    .post<UpdatePortraitResponse>("/v1/users/profiles/image", formData, {
      headers: { "Content-Type": "multipart/form-data" },
      timeout: 0,
    })
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 자기소개 + 대표채널 정보 가져오기
 */
export const getMyPageInfo = () => {
  return axiosInstance
    .get<GetMyPageInfoResponse>("/v1/users/profiles")
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 자기소개 + 대표채널 정보 가져오기
 */
export const getUserProfilePageInfo = (userId: string) => {
  return axiosInstance
    .get<GetUserProfileInfoResponse>(`/v1/users/${userId}/profiles`)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 닉네임으로 유저 검색
 */
export const getUserInfoByNickname = (nickname: string) => {
  return axiosInstance
    .get<GetUserInfoByNickname>(`/v1/users/nicknames/${nickname}/profiles`)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 자기소개 등록
 */
export const updateIntroduction = (payload: UpdateIntroductionRequest) => {
  return axiosInstance
    .post<unknown>("/v1/users/profiles", payload)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};
