import useAuth from "../../hooks/useAuth";
import { CLIENT_URL, cleanRedirectUrl } from "../../constants/urls";
import { Fragment } from "react";
import { Route, RouteProps } from "react-router-dom";

const PrivateRoute = (props: RouteProps) => {
  const { render, ...rest } = props;

  const auth = useAuth();

  return (
    <Fragment>
      {auth.isReady && (
        <Fragment>
          {auth.state !== "INVALID" ? (
            <Route {...rest} {...(render && { render })} />
          ) : (
            <Route
              {...rest}
              {...(render && {
                render: () => {
                  window.location.replace(
                    `${CLIENT_URL}/signin?redirect=${
                      auth.state === "INVALID"
                        ? encodeURIComponent(cleanRedirectUrl(window.location.href))
                        : encodeURIComponent(CLIENT_URL)
                    }`,
                  );
                  return null;
                },
              })}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default PrivateRoute;
