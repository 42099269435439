import { CLEAR_CASH, SET_CASH } from "../actions/cash";

export interface CashInitialState {
  amount: number;
  isReady: boolean;
}

const initialState: CashInitialState = {
  amount: null,
  isReady: false,
};

export const actionSetCash = (amount: number) => ({
  type: SET_CASH,
  amount,
});

export const actionClearCash = () => ({
  type: CLEAR_CASH,
});

const cash = (state = initialState, action) => {
  switch (action.type) {
    case SET_CASH:
      return {
        amount: action.amount,
        isReady: true,
      };
    case CLEAR_CASH:
      return initialState;
    default:
      return state;
  }
};

export default cash;
