import {
  actionClearAvator,
  actionSetAvator,
  actionSetEquippedItems,
  actionSetInventoryOpen,
  AvatorInitialState,
} from "../modules/reducers/avator";
import { CommonReducer } from "../modules/reducers";
import { useDispatch, useSelector } from "react-redux";

export type InventoryType = "EQUIPMENT" | "FACE" | "HAIR" | "SKIN" | "CONSUMPTION" | "ETC" | "NULL";

export type ItemCategory =
  | "HAT"
  | "TOP"
  | "BOTTOM"
  | "ONE_PIECE"
  | "SHOES"
  | "CAPE"
  | "ACCESSORY"
  | "HAND_ACCESSORY"
  | "EARRING"
  | "SKIN"
  | "NECKLACE"
  | "HAIR"
  // | "EARING"
  | "FACE"
  | "FACE_ACCESSORY"
  | "NULL";

export const ITEM_CATEGORY_LIST: ItemCategory[] = [
  "HAT",
  "TOP",
  "BOTTOM",
  "ONE_PIECE",
  "SHOES",
  "EARRING",
  "ACCESSORY",
  "CAPE",
  "SKIN",
  "NECKLACE",
  "HAIR",
  // "EARING", // 이 줄은 주석 처리되어 있으므로 포함하지 않았습니다.
  "FACE",
  "FACE_ACCESSORY",
  "HAND_ACCESSORY",
  "NULL",
];

export const ITEM_INVENTORY_MAPPER = {
  HAT: "EQUIPMENT",
  CAPE: "EQUIPMENT",
  TOP: "EQUIPMENT",
  BOTTOM: "EQUIPMENT",
  ONE_PIECE: "EQUIPMENT",
  SHOES: "EQUIPMENT",
  ACCESSORY: "EQUIPMENT",
  SKIN: "SKIN",
  NECKLACE: "EQUIPMENT",
  HAIR: "HAIR",
  FACE: "FACE",
  FACE_ACCESSORY: "EQUIPMENT",
  HAND_ACCESSORY: "EQUIPMENT",
  FANULLCE: "NULL",
};

export type Item = {
  itemId: number;
  name: string;
  thumbnail: string;
  sprite: string;
  spriteStand1: string;
  itemCategory: ItemCategory;
};
/**
 * API: Inventory
 * 사용자가 보유하고 있는 아이템 리스트
 */
export type MyItems = {
  inventoryId: number;
  itemId: Item["itemId"];
  name: Item["name"];
  itemCategory: Item["itemCategory"];
  inventoryCategory: InventoryType;
  sprite: Item["sprite"];
  spriteStand1: Item["spriteStand1"];
  thumbnail: Item["thumbnail"];
  description: string;
};

export type EquippedItem = {
  equippedUserItemId: number;
  itemId: Item["itemId"];
  name: Item["name"];
  sprite: string;
  spriteStand1: string;
  itemCategory: Item["itemCategory"];
  thumbnail: Item["thumbnail"];
};

export type EquippedItems = {
  HAT: MyItems;
  TOP: MyItems;
  BOTTOM: MyItems;
  ONE_PIECE: MyItems;
  CAPE: MyItems;
  SHOES: MyItems;
  ACCESSORY: MyItems;
  SKIN: MyItems;
  NECKLACE: MyItems;
  EARRING: MyItems;
  HAIR: MyItems;
  FACE: MyItems;
  FACE_ACCESSORY: MyItems;
  HAND_ACCESSORY: MyItems;

  // container1: MyItems[];
  // container2: MyItems[];
  // container3: MyItems[];
};

export const useAvator = () => {
  const dispatch = useDispatch();

  const {
    level,
    levelExp,
    requiredLevelPoint,
    equippedItemMap,
    isReady,
    isAvatarReady,
  }: AvatorInitialState = useSelector((state: CommonReducer) => state.avatorReducer);

  const init = (level: number, levelExp: number, requiredLevelPoint: number) => {
    dispatch(actionSetAvator(level, levelExp, requiredLevelPoint));
  };

  const clear = () => {
    dispatch(actionClearAvator());
  };

  const updateEquippedItems = (equipmentMap: EquippedItems) => {
    dispatch(actionSetEquippedItems(equipmentMap));
  };

  const openInventory = (isOpen: boolean) => {
    dispatch(actionSetInventoryOpen(isOpen));
  };

  const updateEquippedItem = (itemCategory: ItemCategory, item: MyItems) => {
    switch (itemCategory) {
      case "TOP":
        updateEquippedItems({
          ...equippedItemMap,
          TOP: item,
          ONE_PIECE: null,
        } as EquippedItems);

        // setOnePieceImg("");
        // setTopImg(equippedItemsMap["TOP"].spriteStand1);
        break;
      case "BOTTOM":
        // setOnePieceImg("");
        // setBottomImg(equippedItemsMap["BOTTOM"]?.spriteStand1);

        updateEquippedItems({
          ...equippedItemMap,
          BOTTOM: item,
          ONE_PIECE: null,
        } as EquippedItems);

        break;
      case "ONE_PIECE":
        updateEquippedItems({
          ...equippedItemMap,
          TOP: null,
          BOTTOM: null,
          ONE_PIECE: item,
        } as EquippedItems);

        // setTopImg("");
        // setBottomImg("");
        // setOnePieceImg(equippedItemsMap["ONE_PIECE"]?.spriteStand1);
        break;

      // case "HAT":

      // updateEquippedItems({
      //   ...equippedItemMap,
      //   itemCategory: item,
      // } as EquippedItems);

      //   // setHatImg(equippedItemsMap["HAT"]?.spriteStand1);

      //   break;

      // case "SHOES":
      //   setShoesImg(equippedItemsMap["SHOES"]?.spriteStand1);
      //   break;

      // case "FACE":
      //   setFaceImg(equippedItemsMap["FACE"]?.spriteStand1);

      //   break;

      // case "HAIR":
      //   setHairImg(equippedItemsMap["HAIR"]?.spriteStand1);

      //   break;

      // case "CAPE":
      //   setCapeImg(equippedItemsMap["CAPE"]?.spriteStand1);

      //   break;

      // case "SKIN":
      //   setSkinImg(equippedItemsMap["SKIN"]?.spriteStand1);

      //   break;

      default:
        updateEquippedItems({
          ...equippedItemMap,
          itemCategory: item,
        } as EquippedItems);
        break;

      // return;
    }
  };

  return {
    level,
    levelExp,
    requiredLevelPoint,
    equippedItemMap,
    updateEquippedItems,
    updateEquippedItem,
    openInventory,
    isReady,
    isAvatarReady,
    init: (level: number, levelExp: number, requiredLevelPoint: number) => {
      init(level, levelExp, requiredLevelPoint);
    },
    clear,
  };
};

export default useAvator;
