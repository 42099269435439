import language, { LanguageInitialState } from "./language";
import music, { MusicInitialState } from "./music";
import sound, { SoundInitialState } from "./sound";
import toast, { ToastInitialState } from "./toast";
import avator, { AvatorInitialState } from "./avator";

export interface CommonReducer {
  languageReducer: LanguageInitialState;
  avatorReducer: AvatorInitialState;

  musicReducer: MusicInitialState;
  soundReducer: SoundInitialState;
  toastReducer: ToastInitialState;
}

const commonReducer = {
  languageReducer: language,
  musicReducer: music,
  soundReducer: sound,
  toastReducer: toast,
  avatorReducer: avator,
};

export default commonReducer;
