import { actionClearCash, actionSetCash, CashInitialState } from "../modules/reducers/cash";
import { formatAmount } from "common/utils/format";
import { RootReducer } from "../modules/reducers";
import { useDispatch, useSelector } from "react-redux";

const useCash = () => {
  const dispatch = useDispatch();

  const { amount, isReady }: CashInitialState = useSelector(
    (state: RootReducer) => state.cashReducer,
  );

  const init = (cash: number) => {
    dispatch(actionSetCash(cash));
  };

  const clear = () => {
    dispatch(actionClearCash());
  };

  return {
    amount,
    isReady,
    displayAmount: isReady ? String(formatAmount(amount)) : "-",
    init: (cash: number) => {
      init(cash);
    },
    clear,
  };
};

export default useCash;
