import { RESOURCE_URL } from "../urls";

const SOUND_DATA = {
  notification: {
    src: `${RESOURCE_URL}/resources/audio/notification.mp3`,
  },
  purchase: {
    src: `${RESOURCE_URL}/resources/audio/purchase.mp3`,
  },
  splash: {
    src: `${RESOURCE_URL}/resources/audio/splash.mp3`,
  },
};

export { SOUND_DATA };
