import { CLEAR_USER, SET_PORTRAIT, SET_USER, SET_VERIFIED_STATE } from "../actions/user";
import {
  ChannelDto,
  Gender,
  RoleList,
  SignupPath,
  SubscriptionList,
  UserState,
  VerifiedState,
} from "../../api/auth";

export interface UserInitialState {
  userId: string;
  level: number;
  roleList: RoleList[];
  subscriptionList: SubscriptionList[];
  name: string;
  nickname: string;
  channelNickname: string;
  email: string;
  portrait: string;
  verifiedState: VerifiedState;
  state: UserState;
  signupPath: SignupPath;
  isReady: boolean;
  gender: Gender;
  channelProfile: ChannelDto | null;
}

const initialState: UserInitialState = {
  userId: "",
  level: 1,
  roleList: [],
  subscriptionList: [],
  name: "",
  nickname: "",
  channelNickname: "",
  email: "",
  portrait: "",
  verifiedState: "UNVERIFIED",
  state: "INVALID",
  signupPath: null,
  isReady: false,
  gender: "NOT_SELECTED",
  channelProfile: null,
};

// 사용자 정보 설정
export const actionSetUser = (
  userId: string,
  level: number,
  roleList: RoleList[],
  subscriptionList: SubscriptionList[],
  name: string,
  nickname: string,
  channelNickname: string,
  email: string,
  portrait: string,
  verifiedState: VerifiedState,
  state: UserState,
  signupPath: SignupPath,
  gender: Gender,
  channelProfile: ChannelDto,
) => ({
  type: SET_USER,
  userId,
  level,
  roleList,
  subscriptionList,
  name,
  nickname,
  channelNickname,
  email,
  portrait,
  verifiedState,
  state,
  signupPath,
  gender,
  channelProfile,
});

// 사용자 프로필 이미지 변경
export const actionSetPortrait = (portrait: string) => ({
  type: SET_PORTRAIT,
  portrait,
});

// 본인인증 상태 변경
export const actionSetVerifiedState = (verifiedState: VerifiedState) => ({
  type: SET_VERIFIED_STATE,
  verifiedState,
});

// 사용자 정보 초기화
export const actionClearUser = () => ({
  type: CLEAR_USER,
});

const user = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        userId: action.userId,
        level: action.level,
        roleList: action.roleList,
        subscriptionList: action.subscriptionList,
        name: action.name,
        nickname: action.nickname,
        channelNickname: action.channelNickname,
        email: action.email,
        portrait: action.portrait,
        verifiedState: action.verifiedState,
        state: action.state,
        signupPath: action.signupPath,
        isReady: true,
        gender: action.gender,
        channelProfile: action.channelProfile,
      };
    case SET_PORTRAIT:
      return {
        ...state,
        portrait: action.portrait,
      };
    case SET_VERIFIED_STATE:
      return {
        ...state,
        verifiedState: action.verifiedState,
      };
    case CLEAR_USER:
      return initialState;
    default:
      return state;
  }
};

export default user;
