import Cookies from "js-cookie";
import useAuth from "./useAuth";
import useToast from "common/hooks/useToast";
import { AxiosError } from "axios";
import { Client } from "@stomp/stompjs";
import { getSocketToken, GetSocketTokenResponse } from "../api/socket";
import { NOTIFY_BROKER_URL, ROOM_BROKER_URL, WORLD_BROKER_URL } from "../constants/urls";
import { RootReducer } from "../modules/reducers";
import { upperSnakeToCamel } from "common/utils/format";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  SocketInitialState,
  SocketTarget,
  actionReadySocket,
  actionSetClient,
  actionSocketConnected,
  actionSocketConnecting,
  actionSocketDisconnect,
  actionSocketPending,
} from "../modules/reducers/socket";
import { actionSetJoinWorldId } from "../modules/reducers/world";

const useSocket = () => {
  const dispatch = useDispatch();

  const auth = useAuth();
  const toast = useToast();
  const { t } = useTranslation();

  const socketState: SocketInitialState = useSelector((state: RootReducer) => state.socketReducer);

  // 소켓 연결 대기
  const pending = (options: { target: SocketTarget }) => {
    if (socketState[upperSnakeToCamel(options.target)].client) {
      socketState[upperSnakeToCamel(options.target)].client.deactivate();
    }

    localStorage.removeItem(upperSnakeToCamel(options.target));
    dispatch(actionSocketPending(options));
  };

  /**
   * 소켓 연결 트랜젝션
   *
   * 1. 소켓 연결 토큰 가져오기
   * 2. Stomp Client로 연결
   */
  const connect = (options: { target: SocketTarget }) => {
    if (socketState[upperSnakeToCamel(options.target)].client) {
      return;
    }

    dispatch(actionReadySocket(options));

    switch (options.target) {
      case "NOTIFICATION":
        getTokenAndConnect(options);
        break;
      case "ROOM":
        getTokenAndConnect(options);
        break;
      case "WORLD":
        getTokenAndConnect(options);
        break;
      default:
    }
  };

  // 소켓 연결 종료
  const disconnect = (options: { target: SocketTarget }) => {
    if (socketState[upperSnakeToCamel(options.target)].client) {
      socketState[upperSnakeToCamel(options.target)].client.deactivate();
    }

    localStorage.removeItem(upperSnakeToCamel(options.target));
    dispatch(actionSocketDisconnect(options));
  };

  // 소켓 토큰 가져오기
  const getTokenAndConnect = (options: { target: SocketTarget }) => {
    switch (options.target) {
      case "NOTIFICATION":
        getSocketToken("notify")
          .then((response: GetSocketTokenResponse) => {
            dispatch(
              actionSocketConnecting({
                target: options.target,
                token: response.token,
              }),
            );

            // if (response.roomId !== null && response.roomId !== "") {
            dispatch(actionSetJoinWorldId(response.worldId));
            // window.sessionStorage.setItem("roomId", response.roomId);
            // }

            connectSocket({
              target: options.target,
              token: response.token,
            });
          })
          .catch((error: AxiosError) => {
            localStorage.removeItem(upperSnakeToCamel(options.target));
            dispatch(actionSocketDisconnect({ target: options.target }));
          });
        break;
      case "ROOM":
        getSocketToken("rooms")
          .then((response: GetSocketTokenResponse) => {
            dispatch(
              actionSocketConnecting({
                target: options.target,
                token: response.token,
              }),
            );
            connectSocket({
              target: options.target,
              token: response.token,
            });
            window.sessionStorage.setItem("roomToken", response.token);
          })
          .catch((error: AxiosError) => {
            localStorage.removeItem(upperSnakeToCamel(options.target));
            dispatch(actionSocketDisconnect({ target: options.target }));
          });
        break;
      case "WORLD":
        getSocketToken("worlds")
          .then((response: GetSocketTokenResponse) => {
            dispatch(
              actionSocketConnecting({
                target: options.target,
                token: response.token,
              }),
            );
            connectSocket({
              target: options.target,
              token: response.token,
            });
            window.sessionStorage.setItem("worldToken", response.token);
          })
          .catch((error: AxiosError) => {
            localStorage.removeItem(upperSnakeToCamel(options.target));
            dispatch(actionSocketDisconnect({ target: options.target }));
          });
        break;
      default:
    }
  };

  const baseUrlMapping = (target: SocketTarget) => {
    switch (target) {
      case "NOTIFICATION":
        return NOTIFY_BROKER_URL;
      case "ROOM":
        return ROOM_BROKER_URL;
      case "WORLD":
        return WORLD_BROKER_URL;
      default:
        return "";
    }
  };

  const connectSocket = (options: { target: SocketTarget; token: string }) => {
    const client = new Client({
      brokerURL: baseUrlMapping(options.target),

      connectHeaders: {
        login: auth.userId,
        token: Cookies.get("accessToken"),
        websocketToken: options.token,
      },
      reconnectDelay: 0, // 재연결 옵션 해제
      heartbeatIncoming: 30000,
      heartbeatOutgoing: 30000,
    });

    // Stomp Client 설정
    dispatch(
      actionSetClient({
        target: options.target,
        client,
      }),
    );

    // Stomp 연결 활성화
    client.activate();

    client.onConnect = () => {
      dispatch(actionSocketConnected({ target: options.target }));
    };

    // Error from API
    client.onStompError = (frame: any) => {
      const message = JSON.parse(frame.headers.message.replaceAll("\\c", ":"));

      if (message.errorCode === "UNAUTHORIZED") {
        toast.notification("danger", t("connection-refused")).show();

        client.deactivate();

        localStorage.removeItem(upperSnakeToCamel(options.target));
        dispatch(actionSocketDisconnect({ target: options.target }));
      }
    };
  };

  return {
    notification: socketState.notification,
    room: socketState.room,
    world: socketState.world,
    pending: (options: { target: SocketTarget }) => {
      pending(options);
    },
    connect: (options: { target: SocketTarget }) => {
      connect(options);
    },
    disconnect: (options: { target: SocketTarget }) => {
      disconnect(options);
    },
  };
};

export default useSocket;
