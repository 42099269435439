import { actionClearPoint, actionSetPoint, PointInitialState } from "../modules/reducers/point";
import { formatAmount } from "common/utils/format";
import { RootReducer } from "../modules/reducers";
import { useDispatch, useSelector } from "react-redux";

const usePoint = () => {
  const dispatch = useDispatch();

  const { amount, isReady }: PointInitialState = useSelector(
    (state: RootReducer) => state.pointReducer,
  );

  const init = (point: number) => {
    dispatch(actionSetPoint(point));
  };

  const clear = () => {
    dispatch(actionClearPoint());
  };

  return {
    amount,
    isReady,
    displayAmount: isReady ? String(formatAmount(amount)) : "-",
    init: (point: number) => {
      init(point);
    },
    clear,
  };
};

export default usePoint;
