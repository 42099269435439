import Cookies from "js-cookie";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import ReactDOMServer from "react-dom/server";
import Swal, { SweetAlertResult } from "sweetalert2";
import useAuth from "./useAuth";
import { actionSetIsReady, RequestInitialState } from "../modules/reducers/request";
import { ALLOW_REDIRECT } from "common/config";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { axiosInstance } from "../shared/request";
import { CLIENT_URL } from "../constants/urls";
import { Fragment, useRef } from "react";
import { RootReducer } from "../modules/reducers";
import { setAbortSignalToAxiosInstance } from "../utils/request";
import { useDispatch, useSelector } from "react-redux";

export interface ServerErrorCode {
  errorCode: ErrorCode;
}

export type ErrorCode =
  | "EXPIRED_TOKEN"
  | "MEMBER_NOT_FOUND"
  | "LOGIN_FAIL"
  | "EDUENV_UNAUTHORIZED_MEMBER"
  | "SIGNUP_VALIDATION_FAIL"
  | "EMAIL_DUPLICATE_MEMBER"
  | "CHANNEL_NOT_FOUND"
  | "MULTIPLE_FIELD_ERRORS"
  | "TOO_MANY_USER_CREATED_CHANNEL_COUNT"
  | "CHANNEL_CONTENT_UNAUTHORIZED"
  | "NOT_HAS_ROLE"
  | "TOO_MANY_REPORT_FILE"
  | "HAS_ASSIGNMENT"
  | "IS_NOT_ADVANCED_CHANNEL_USER"
  | "INVALID_AUTH_TOKEN"
  | "ALREADY_RESET"
  | "ALREADY_FRIEND_USER"
  | "DUPLICATE_PRODUCT"
  | "DUPLICATE_WISH_ITEM"
  | "NOT_FOUND_INVENTORY"
  | "NOT_ENOUGH_POINT"
  | "NOT_FOUND_PRODUCT"
  | "CHANNEL_CODE_NOT_FOUND"
  | "MISMATCH_ROOM_PASSWORD"
  | "ROOM_NOT_FOUND"
  | "ALREADY_ROOM_MEMBER_USER"
  | "NOT_ACCEPT_ROOM"
  | "TOO_MANY_ROOM_MEMBER"
  | "USER_NICKNAME_NOT_FOUND"
  | "ALREADY_ANOTHER_ROOM_MEMBER_USER";

const useRequest = () => {
  const dispatch = useDispatch();

  const auth = useAuth();

  const abortControllerRef = useRef<AbortController>(null);
  const errorRef = useRef<Boolean>(false);

  const { isReady }: RequestInitialState = useSelector(
    (state: RootReducer) => state.requestReducer,
  );

  const setAbortSignal = () => {
    abortControllerRef.current = setAbortSignalToAxiosInstance();
  };

  // accessToken 갱신 후 API 재요청
  const refreshAuth = (request: any) => {
    // refreshId가 없다면 재발급 없이 로그아웃
    if (!Cookies.get("refreshId")) {
      window.location.replace(`${CLIENT_URL}/signout?redirect=${encodeURIComponent(CLIENT_URL)}`);
      return null;
    }

    return axiosInstance
      .post("/v1/tokens/refresh", {
        refreshId: Cookies.get("refreshId"),
      })
      .then((response: AxiosResponse) => {
        const { access_token, refresh_id } = response.headers;

        auth.setAccessToken(access_token);
        auth.setRefreshId(refresh_id);

        request.response.config.headers["Authorization"] = `Bearer ${access_token}`;

        return Promise.resolve();
      })
      .catch((error: AxiosError) => {
        window.location.replace(`${CLIENT_URL}/signout?redirect=${encodeURIComponent(CLIENT_URL)}`);
      });
  };

  const init = () => {
    setAbortSignal();

    axiosInstance.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        const accessToken = Cookies.get("accessToken");

        // Authorization 헤더 추가
        if (accessToken) {
          config.headers["Authorization"] = `Bearer ${accessToken}`;
        }

        return config;
      },
      (error: AxiosError) => {
        return Promise.reject(error);
      },
    );

    axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      (error: AxiosError<ServerErrorCode>) => {
        // 네트워크 요청 취소
        if (error.code === "ERR_CANCELED") {
          // Pass
        }

        // 네트워크 연결 오류
        else if (error.code === "ERR_NETWORK" || error.code === "ECONNABORTED") {
          if (!errorRef.current) {
            errorRef.current = true;

            Swal.close();
            Swal.fire({
              title: "알림",
              text: "네트워크 연결 상태를 확인해 주세요.",
              showCloseButton: true,
              showConfirmButton: false,
              showCancelButton: true,
              cancelButtonText: "닫기",
              customClass: "swal2-sm swal2-warning",
            }).then((result: SweetAlertResult) => {
              errorRef.current = false;
            });
          }

          abortControllerRef.current.abort();
          setAbortSignal();
        }

        // Access Token 만료
        else if (error.response.data.errorCode === "EXPIRED_TOKEN") {
          // Pass
        }

        // 가입된 사용자 정보가 없을 때
        else if (error.response.data.errorCode === "MEMBER_NOT_FOUND") {
          // Pass
        }

        // 로그인 오류
        else if (error.response.data.errorCode === "LOGIN_FAIL") {
          // Pass
        }

        // 이메일, 인증코드 검증 실패 시
        else if (error.response.data.errorCode === "EDUENV_UNAUTHORIZED_MEMBER") {
          // Pass
        }

        // 회원가입 오류
        else if (error.response.data.errorCode === "SIGNUP_VALIDATION_FAIL") {
          // Pass
        }

        // 회원가입 > 소셜 로그인 이메일 오류
        else if (error.response.data.errorCode === "EMAIL_DUPLICATE_MEMBER") {
          // Pass
        }

        // 채널 정보가 없을 때
        else if (error.response.data.errorCode === "CHANNEL_NOT_FOUND") {
          // Pass
        }

        // 채널 만들기 > 유효성 검증 오류
        else if (error.response.data.errorCode === "MULTIPLE_FIELD_ERRORS") {
          // Pass
        }

        // 채널 만들기 > 생성 가능 채널 수 초과
        else if (error.response.data.errorCode === "TOO_MANY_USER_CREATED_CHANNEL_COUNT") {
          // Pass
        }

        // 채널 게시글 접근 권한 오류
        else if (error.response.data.errorCode === "CHANNEL_CONTENT_UNAUTHORIZED") {
          // Pass
        }

        // shop > 이미 중복된 아이템 구매일때
        else if (error.response.data.errorCode === "DUPLICATE_PRODUCT") {
          // Pass
        }

        // shop > 이미 중복된 아이템 찜 할때
        else if (error.response.data.errorCode === "DUPLICATE_WISH_ITEM") {
          // Pass
        }

        // shop > 소유하고있는 아이템이 아닐때 장착시
        else if (error.response.data.errorCode === "NOT_FOUND_INVENTORY") {
          // Pass
        }

        // shop > 포인트 부족시
        else if (error.response.data.errorCode === "NOT_ENOUGH_POINT") {
          // Pass
        }

        // shop > 상품이 없을때
        else if (error.response.data.errorCode === "NOT_FOUND_PRODUCT") {
          // Pass
        }

        // 채널 회원가입  > 채널 코드가 유효하지 않을 때
        else if (error.response.data.errorCode === "CHANNEL_CODE_NOT_FOUND") {
          // Pass
        }

        // world 방입장시 비번 다를때
        else if (error.response.data.errorCode === "MISMATCH_ROOM_PASSWORD") {
          // Pass
        }

        // world 방입장시 방이 없을때
        else if (error.response.data.errorCode === "ROOM_NOT_FOUND") {
          // Pass
        }

        // world 방입장시 이미 방에 있는 유저일때
        else if (error.response.data.errorCode === "ALREADY_ROOM_MEMBER_USER") {
          // Pass
        }

        // world 방입장시 방을 수락하지 않았을때
        else if (error.response.data.errorCode === "NOT_ACCEPT_ROOM") {
          // Pass
        }

        // world 방입장시 방인원 초과일때
        else if (error.response.data.errorCode === "TOO_MANY_ROOM_MEMBER") {
          // Pass
        }

        // 이미 다른방에 가입되어있을때
        else if (error.response.data.errorCode === "ALREADY_ANOTHER_ROOM_MEMBER_USER") {
          // Pass
        }

        // 이미 친구 상태임
        else if (error.response.data.errorCode === "ALREADY_FRIEND_USER") {
          // Pass
        }
        // 유저를 찾을 수 없음
        else if (error.response.data.errorCode === "USER_NICKNAME_NOT_FOUND") {
          // Pass
          Swal.close();
          Swal.fire({
            title: "알림",
            text: "해당 닉네임의 유저를 찾을 수가 없어요!",
            showCloseButton: true,
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: "닫기",
            customClass: "swal2-sm",
          });
        }

        // 채널 게시글 접근 권한 오류
        else if (error.response.data.errorCode === "TOO_MANY_REPORT_FILE") {
          // Pass
          Swal.close();
          Swal.fire({
            title: "알림",
            text: "최대 5개까지 업로드 가능합니다.",
            showCloseButton: true,
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: "닫기",
            customClass: "swal2-sm",
          });
        }

        // 채널 관리 > 권한 오류
        else if (error.response.data.errorCode === "NOT_HAS_ROLE") {
          // Pass
        }

        // 채널 관리 > 그룹 삭제
        else if (error.response.data.errorCode === "HAS_ASSIGNMENT") {
          Swal.close();
          Swal.fire({
            title: "알림",
            text: "발급된 숙제가 존재합니다.",
            showCloseButton: true,
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: "닫기",
            customClass: "swal2-sm",
          });
        }

        // 숙제관리 > 이미 초기화 된 문제 재 초기화시
        else if (error.response.data.errorCode === "ALREADY_RESET") {
          Swal.close();
          Swal.fire({
            title: "알림",
            text: "이미 초기화된 문제 입니다.",
            showCloseButton: true,
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: "닫기",
            customClass: "swal2-sm",
          });
        }

        // LMS > 숙제 > 권한 오류
        else if (error.response.data.errorCode === "IS_NOT_ADVANCED_CHANNEL_USER") {
          // Pass
        }

        // accessToken이 유효하지 않을 때
        else if (error.response.data.errorCode === "INVALID_AUTH_TOKEN") {
          if (ALLOW_REDIRECT) {
            window.location.replace(
              `${CLIENT_URL}/signout?redirect=${encodeURIComponent(CLIENT_URL)}`,
            );
          }
        }

        // 그 외 서버 오류
        else {
          if (!errorRef.current) {
            errorRef.current = true;

            Swal.close();
            Swal.fire({
              title: "알림",
              html: ReactDOMServer.renderToString(
                <Fragment>
                  예기치 못한 오류가 발생했습니다.
                  <br />
                  궁금하신 사항은 관리자에게 문의해 주세요.
                  <br />
                  (team@eduenv.com)
                </Fragment>,
              ),
              showCloseButton: true,
              showConfirmButton: false,
              showCancelButton: true,
              cancelButtonText: "닫기",
              customClass: "swal2-sm swal2-warning",
            }).then((result: SweetAlertResult) => {
              errorRef.current = false;
            });
          }
        }

        return Promise.reject(error);
      },
    );

    // accessToken 재발급
    createAuthRefreshInterceptor(axiosInstance, refreshAuth, {
      shouldRefresh: (error: AxiosError<ServerErrorCode>) => {
        return error?.response?.data?.errorCode === "EXPIRED_TOKEN";
      },
    });

    dispatch(actionSetIsReady(true));
  };

  return {
    isReady,
    init,
  };
};

export default useRequest;
