import { css } from "@emotion/react";
import { MEDIA_QUERIES } from "common/constants/styles/responsive";

const styles = {
  wrapper: css`
    position: relative;
    height: 100vh;
  `,
  container: css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  `,
  content: css`
    max-width: 27.625rem;
    text-align: center;

    img {
      height: 9.375rem;
    }

    h3 {
      font-size: 1.5625rem;
    }

    p {
      margin-bottom: 1.875rem;
      color: rgba(0, 0, 0, 0.5);
      font-size: 0.9375rem;
    }

    .btn {
      width: 23.4375rem;
    }

    ${MEDIA_QUERIES.max.sm} {
      .btn {
        width: auto;
      }
    }
  `,
};

export { styles };
