/**
 * Camel 형식을 Kebab 형식으로 변환하여 반환
 *
 * @example
 * doSomething -> do-something
 */
export const camelToKebab = (str: string) => {
  let result = [];

  for (let i = 0; i < str.length; i++) {
    if (str[i] === str[i].toUpperCase()) {
      result.push(`-${str[i].toLowerCase()}`);
      continue;
    }

    result.push(str[i]);
  }

  return result.join("");
};

/**
 * 대문자 Snake 형식을 Kebab 형식으로 변환하여 반환
 *
 * @example
 * DO_SOMETHING -> do-something
 */
export const upperSnakeToKebab = (str: string) => {
  let result = [];

  for (let i = 0; i < str.length; i++) {
    if (str[i] === "_") {
      result.push("-");
      continue;
    }

    result.push(str[i].toLowerCase());
  }

  return result.join("");
};

/**
 * 대문자 Snake 형식을 Camel 형식으로 변환하여 반환
 *
 * @example
 * DO_SOMETHING -> doSomething
 */
export const upperSnakeToCamel = (str: string) => {
  let result = [];
  let isUpper = false;

  for (let i = 0; i < str.length; i++) {
    if (str[i] === "_") {
      isUpper = true;
      continue;
    }

    if (!isUpper) {
      result.push(str[i].toLowerCase());
    }

    if (isUpper) {
      result.push(str[i].toUpperCase());
    }

    isUpper = false;
  }

  return result.join("");
};

/**
 * Bytes 단위 변환
 */
export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) {
    return "0 Bytes";
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

/**
 * 초를 분 형식으로 변환
 *
 * @example
 * 60 -> 01:00
 */
export const formatSeconds = (seconds: number) => {
  const duration = Math.floor(seconds);

  const min = Math.floor(duration / 60).toString();
  const sec = Math.floor(duration % 60).toString();

  return `${min.padStart(2, "0")}:${sec.padStart(2, "0")}`;
};

/**
 * 배열 형식의 날짜를 String으로 반환 (시, 분 포함)
 *
 * @example
 * [2023, 1, 1, 0, 0, 0] -> 2023-01-01 00:00
 */
export const formatArrayFullDate = (dateArr: Array<number>) => {
  return (
    dateArr[0] +
    "-" +
    String(dateArr[1]).padStart(2, "0") +
    "-" +
    String(dateArr[2]).padStart(2, "0") +
    " " +
    String(dateArr[3]).padStart(2, "0") +
    ":" +
    String(dateArr[4]).padStart(2, "0")
  );
};

/**
 * 배열 형식의 날짜를 String으로 반환
 *
 * @example
 * [2023, 1, 1, 0, 0, 0] -> 2023-01-01
 */
export const formatArrayDate = (dateArr: Array<number>) => {
  return (
    dateArr[0] +
    "-" +
    String(dateArr[1]).padStart(2, "0") +
    "-" +
    String(dateArr[2]).padStart(2, "0")
  );
};

/**
 * ISO 형식의 날짜를 String으로 반환 (시, 분 포함)
 *
 * @example
 * 2023-01-01 00:00:00 -> 2023-01-01 00:00
 * 2023-01-01T00:00:00.00000 -> 2023-01-01 00:00
 */
export const formatISOFullDate = (isoDate: string) => {
  let time;

  if (isoDate.includes("T")) {
    time = new Date(isoDate);
  } else {
    time = new Date(isoDate.replace(/-/g, "/"));
  }

  return (
    time.getFullYear() +
    "-" +
    String(time.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(time.getDate()).padStart(2, "0") +
    " " +
    String(time.getHours()).padStart(2, "0") +
    ":" +
    String(time.getMinutes()).padStart(2, "0")
  );
};

/**
 * ISO 형식의 날짜를 String으로 반환
 *
 * @example
 * 2023-01-01 00:00:00 -> 2023-01-01
 * 2023-01-01T00:00:00.00000 -> 2023-01-01
 */
export const formatISODate = (isoDate: string) => {
  let time;

  if (isoDate.includes("T")) {
    time = new Date(isoDate);
  } else {
    time = new Date(isoDate.replace(/-/g, "/"));
  }

  return (
    time.getFullYear() +
    "-" +
    String(time.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(time.getDate()).padStart(2, "0")
  );
};

/**
 * ISO 형식의 날짜의 경과 시간을 반환
 *
 * @example
 * 2023-01-01 00:00:00 -> 1분 전
 * 2023-01-01T00:00:00.00000 -> 1분 전
 */
export const elapsedTime = (isoDate: string) => {
  let time;
  const today = new Date();

  if (isoDate.includes("T")) {
    time = new Date(isoDate);
  } else {
    time = new Date(isoDate.replace(/-/g, "/"));
  }

  const betweenTime = Math.floor((today.getTime() - time.getTime()) / 1000 / 60);

  if (betweenTime < 1) return "방금 전";

  if (betweenTime < 60) {
    return `${betweenTime}분 전`;
  }

  const betweenTimeHour = Math.floor(betweenTime / 60);
  if (betweenTimeHour < 24) {
    return `${betweenTimeHour}시간 전`;
  }

  const betweenTimeDay = Math.floor(betweenTime / 60 / 24);
  if (betweenTimeDay < 30) {
    return `${betweenTimeDay}일 전`;
  }

  return formatISODate(isoDate);
};

/**
 * 퍼센트로 변환
 */
export const formatPercent = (numerator: number, denominator: number) => {
  if (typeof numerator !== "number" || typeof denominator !== "number") {
    return "0%";
  }

  return ((numerator / denominator) * 100).toFixed(1) + "%";
};

/**
 * 천단위 마다 (,) 추가
 */
export const formatAmount = (amount: number, unit?: "KRW") => {
  if (typeof amount !== "number") {
    return "-";
  }

  if (unit) {
    switch (unit) {
      case "KRW":
        return `${amount.toLocaleString()}원`;
      default:
    }
  }

  return amount.toLocaleString();
};
