import { SET_IS_READY } from "../actions/request";

export interface RequestInitialState {
  isReady: boolean;
}

const initialState: RequestInitialState = {
  isReady: false,
};

export const actionSetIsReady = (isReady: boolean) => ({
  type: SET_IS_READY,
  isReady,
});

const request = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_READY:
      return {
        ...state,
        isReady: action.isReady,
      };
    default:
      return state;
  }
};

export default request;
