import { BoardItemList, ChannelPluginMap, GetChannelInfoResponse } from "../../api/channel";
import {
  GetAcceptedChannelBusinessResponse,
  PluginList,
  PluginType,
} from "../../api/channel-setting";
import {
  SET_CHANNEL_BUSINESS_INFO,
  SET_CHANNEL_INFO,
  SET_CHANNEL_MENU,
  SET_CHANNEL_PLUGIN,
  SET_CHANNEL_ROLE,
  SET_IS_READY,
  SET_LMS_ACCESS_TOKEN,
  SET_LMS_REFRESH_TOKEN,
} from "../actions/channel";
import Cookies from "js-cookie";

export interface ChannelInitalState {
  info: GetChannelInfoResponse;
  pluginMap: ChannelPluginMap;
  menu: BoardItemList[];
  role: ChannelRole;
  isReady: boolean;
  businessInfo: GetAcceptedChannelBusinessResponse;
  accessToken: string;
  refreshToken: string;
}

export type ChannelRole = "ROLE_CHANNEL_NORMAL" | "ROLE_CHANNEL_PREMIUM";

const initialState: ChannelInitalState = {
  info: null,
  pluginMap: null,
  menu: [],
  role: null,
  isReady: false,
  businessInfo: null,
  accessToken: Cookies.get("accessToken") || null,
  refreshToken: Cookies.get("refreshId") || null,
};

export const actionSetChannelPlugin = (pluginMap: ChannelPluginMap) => ({
  type: SET_CHANNEL_PLUGIN,
  pluginMap,
});

export const actionSetChannelInfo = (info: GetChannelInfoResponse) => ({
  type: SET_CHANNEL_INFO,
  info,
});

export const actionSetChannelMenu = (menu: BoardItemList[]) => ({
  type: SET_CHANNEL_MENU,
  menu,
});

export const actionSetChannelRole = (role: ChannelRole) => ({
  type: SET_CHANNEL_ROLE,
  role,
});

export const actionSetIsReady = (isReady: boolean) => ({
  type: SET_IS_READY,
  isReady,
});

export const actionSetBusinessInfo = (businessInfo: GetAcceptedChannelBusinessResponse) => ({
  type: SET_CHANNEL_BUSINESS_INFO,
  businessInfo,
});

export const actionSetLmsAccessToken = (accessToken: string) => ({
  type: SET_LMS_ACCESS_TOKEN,
  accessToken,
});

export const actionSetLmsRefreshToken = (refreshToken: string) => ({
  type: SET_LMS_REFRESH_TOKEN,
  refreshToken,
});

const channel = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHANNEL_PLUGIN:
      return {
        ...state,
        pluginMap: action.pluginMap,
      };
    case SET_CHANNEL_INFO:
      return {
        ...state,
        info: action.info,
      };
    case SET_CHANNEL_MENU:
      return {
        ...state,
        menu: action.menu,
      };
    case SET_CHANNEL_ROLE:
      return {
        ...state,
        role: action.role,
      };
    case SET_IS_READY:
      return {
        ...state,
        isReady: action.isReady,
      };
    case SET_CHANNEL_BUSINESS_INFO:
      return {
        ...state,
        businessInfo: action.businessInfo,
      };
    case SET_LMS_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.accessToken,
      };
    case SET_LMS_REFRESH_TOKEN:
      return {
        ...state,
        refreshToken: action.refreshToken,
      };
    default:
      return state;
  }
};

export default channel;
