import { EquippedItemForWorld } from "../../../../apps/eduenv-client/src/components/game/GameMain/game/avatar/MatterUserAvatar";
import { EquippedItem, EquippedItems, ItemCategory } from "../../hooks/useAvator";
import {
  CLEAR_AVATOR,
  SET_AVATOR,
  SET_AVATOR_ITEM,
  SET_EQUIPPED_ITEMS,
  SET_INVENTORY_OPEN,
} from "../actions/avator";

/** Inventory Types */

// export type ItemCategory =
//   | "HAT"
//   | "TOP"
//   | "CAPE"
//   | "BOTTOM"
//   | "ONE_PIECE"
//   | "SHOES"
//   | "ACCESSORY"
//   | "SKIN"
//   | "CAPE"
//   | "NECKLACE"
//   | "HAIR"
//   | "EARRING"
//   | "FACE"
//   | "FACE_ACCESSORY"
//   | "NULL";

// export const ITEM_CATEGORY_LIST = [
//   "HAT",
//   "TOP",
//   "CAPE",
//   "BOTTOM",
//   "ONE_PIECE",
//   "SHOES",
//   "ACCESSORY",
//   "EARRING",
//   "SKIN",
//   "NECKLACE",
//   "HAIR",
//   // "EARING", // 이 줄은 주석 처리되어 있으므로 포함하지 않았습니다.
//   "FACE",
//   "FACE_ACCESSORY",
//   "NULL",
// ];

// export type InventoryType = "EQUIPMENT" | "FACE" | "HAIR" | "SKIN" | "CONSUMPTION" | "ETC" | "NULL";
// export type Item = {
//   itemId: number;
//   name: string;
//   thumbnail: string;
//   sprite: string;
//   spriteStand1: string;
//   itemCategory: ItemCategory;
// };

// export type EquippedItem = {
//   equippedUserItemId: number;
//   itemId: Item["itemId"];
//   name: Item["name"];
//   sprite: string;
//   spriteStand1: string;
//   itemCategory: Item["itemCategory"];
//   thumbnail: Item["thumbnail"];
// };

export interface AvatorInitialState {
  level: number;
  levelExp: number;
  requiredLevelPoint: number;
  isReady: boolean;
  isAvatarReady: boolean;
  isEquippedUpdate: boolean;
  isInventoryOpen: boolean;
  equippedItemMap: EquippedItems;
}

const initialState: AvatorInitialState = {
  level: null,
  levelExp: null,
  requiredLevelPoint: null,
  isReady: false,
  isAvatarReady: false,
  isEquippedUpdate: false,
  isInventoryOpen: false,
  equippedItemMap: {} as EquippedItems,
};

export const actionSetAvator = (level: number, levelExp: number, requiredLevelPoint: number) => ({
  type: SET_AVATOR,
  level,
  levelExp,
  requiredLevelPoint,
});
export const actionSetEquippedItems = (equippedItemMap: EquippedItems) => ({
  type: SET_EQUIPPED_ITEMS,
  equippedItemMap,
});

export const actionClearAvator = () => ({
  type: CLEAR_AVATOR,
});

export const actionSetAvatorItem = (isEquippedUpdate: boolean) => ({
  type: SET_AVATOR_ITEM,
  isEquippedUpdate,
});

export const actionSetInventoryOpen = (isOpen: boolean) => ({
  type: SET_INVENTORY_OPEN,
  isOpen,
});

const avator = (state = initialState, action) => {
  switch (action.type) {
    case SET_AVATOR:
      return {
        level: action.level,
        levelExp: action.levelExp,
        requiredLevelPoint: action.requiredLevelPoint,
        isReady: true,
      };
    case CLEAR_AVATOR:
      return initialState;

    case SET_EQUIPPED_ITEMS:
      return {
        ...state,
        equippedItemMap: action.equippedItemMap,
        isAvatarReady: true,
      };

    case SET_AVATOR_ITEM:
      return {
        ...state,
        isEquippedUpdate: action.isEquippedUpdate,
      };
    case SET_INVENTORY_OPEN:
      return {
        ...state,
        isInventoryOpen: action.isOpen,
      };
    default:
      return state;
  }
};

export default avator;
