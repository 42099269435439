import styled from "@emotion/styled";

import { css } from "@emotion/react";
import { MEDIA_QUERIES } from "common/constants/styles/responsive";

const styles = {
  card: css`
    margin-left: 6.25rem;
    width: 37.25rem;

    .card-body {
      padding: 1.875rem 2.5rem;
    }

    h3 {
      margin-bottom: 1.25rem;
      text-align: center;
      font-size: 1.5625rem;
    }

    ${MEDIA_QUERIES.max.xl} {
      margin-left: 0;
    }

    ${MEDIA_QUERIES.max.lg} {
      width: 100%;
    }
  `,
  content: css`
    .form-group {
      margin-bottom: 1.25rem;
    }

    .form-group:last-of-type {
      margin-bottom: 0;
    }

    .form-label {
      margin-bottom: 0.625rem;
      font-size: 0.75rem;
    }

    .form-control {
      border: none;
      background-color: #f8f9fa;
      padding: 0.9375rem;
      font-size: 0.875rem;
    }

    .form-validation {
      font-size: 0.75rem;
    }
  `,
  submit: css`
    display: flex;
    justify-content: center;

    .btn {
      width: 19.875rem;
      background-color: #3c99ff;
      border-color: #3c99ff;
    }

    ${MEDIA_QUERIES.max.md} {
      .btn {
        width: 100%;
      }
    }
  `,
  input_form: css`
    margin-bottom: 1rem;
    position: relative;
    flex: 1;
    min-width: 0;
    margin-top: 1rem;
  `,
  validation: css`
    display: flex;
    justify-content: center;
    padding: 0.625rem;
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.875rem;
  `,
  row: css`
    display: flex;
    padding: 0.9375rem 0;
    margin-top: 20px;

    ${MEDIA_QUERIES.max.lg} {
      display: block;
    }
  `,
  label: css`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    margin-right: 1.25rem;
    margin-bottom: 1rem;
    width: 9.375rem;
    font-size: 12px;

    ${MEDIA_QUERIES.max.lg} {
      margin-bottom: 0.625rem;
      width: 100%;
    }
  `,
  form: css`
    position: relative;
    flex: 1;
    min-width: 0;
  `,
  select: css`
    .dropdown-menu {
      width: 70%;
      max-height: 13.125rem;
      overflow-y: auto;
    }
  `,
  dropdown: css`
    display: flex;
    justify-content: space-between;
    padding: 0.9375rem;
    width: 100%;
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.875rem;
  `,
};

export { styles };

export const ModalWapp = styled.div``;

export const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
`;

export const Header = styled.div`
  button {
    background: none;
    border: none;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 15px;
  background-color: #3c99ff;
  color: #fff;
`;

export const Avatar = styled.div`
  margin: 30px auto;
  width: 290px;
  height: 230px;
`;

export const AvatarInner = styled.div`
  width: 230px;
  height: 230px;
  position: absolute;
  left: 20%;
  border: solid 1px #e5e8f0;
  background-color: #eef1f8;
  border-radius: 10px;
`;

export const ContentWrap = styled.div`
  padding: 0 30px;
`;

export const DetailInfo = styled.div`
  margin-bottom: 30px;
  padding: 27px 15px 15px 15px;
  border-radius: 10px;
  border: solid 1px #e5e8f0;
`;

export const DetailInfoTitle = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 20px;

  h4 {
    margin: 0 5px;
    font-size: 20px;
    font-weight: bold;
  }

  p {
    margin: 0;
  }
`;

export const DetailInfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-size: 13px;
    font-weight: 600;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 3rem;
    &:first-of-type {
      color: #a8b2cc;
    }
  }

  label {
    font-size: 13px;
    font-weight: 600;
    margin-top: auto;
    margin-bottom: 0.5rem;
    margin-right: 3rem;
    &:first-of-type {
      color: #a8b2cc;
    }
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin: 0 0 30px 0;
`;

export const PrimaryButton = styled.button`
  width: 106px;
  padding: 8px 0;
  border-radius: 6px;
  border: solid 1px #b8becb;
  font-size: 14px;
  font-weight: 500;
  background-image: linear-gradient(to bottom, #f4f5f5, #e1e3eb);
`;

export const ActivateButon = styled(PrimaryButton)`
  color: #fff;
  border: solid 1px #0e2a60;
  background-image: linear-gradient(to bottom, #84aefe, #527ac8);
`;
