import useAuth from "../../hooks/useAuth";
import { ChannelMemberRole } from "../../constants/channels";
import { CLIENT_URL } from "../../constants/urls";
import { Fragment } from "react";
import { hasPermission } from "common/utils/permission";
import { RoleList } from "../../api/auth";
import { Route, RouteProps } from "react-router-dom";

interface RoleRouteProps extends RouteProps {
  role: RoleList[] | ChannelMemberRole[];
}

const RoleRoute = (props: RoleRouteProps) => {
  const { role, render, ...rest } = props;

  const auth = useAuth();

  return (
    <Fragment>
      {auth.isReady && (
        <Fragment>
          {auth.state === "VALID" && hasPermission(role as any, auth.roleList) ? (
            <Route {...rest} {...(render && { render })} />
          ) : (
            <Route
              {...rest}
              {...(render && {
                render: () => {
                  window.location.replace(CLIENT_URL);
                  return null;
                },
              })}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default RoleRoute;
