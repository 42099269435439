import { CHAT_MESSAGE, JOIN_WORLD_ID, READY_SOCKET } from "../actions/world";

type ChatMessage = {
  userId: string;
  nickname: string;
  content: string;
};

type ReceiveChatMessage = {
  type: string;
  content: ChatMessage;
};

export interface WorldInitialState {
  userList: string[];
  joinRoomId: string;
  chatMessage: ChatMessage[];
}

const initialState: WorldInitialState = {
  userList: [],
  joinRoomId: "",
  chatMessage: [],
};

const MAX_CHAT_SIZE = 100;

// world 접속한 roomId 관리
export const actionSetJoinWorldId = (joinWorldServerId: number) => ({
  type: JOIN_WORLD_ID,
  joinWorldServerId: joinWorldServerId,
});

// world 게임 입장 후 유저리스트
export const actionSetWorldUserList = (userList: string[]) => ({
  type: READY_SOCKET,
  userList: userList,
});

// world 채팅 메세지
export const actionSetChatMessage = (chatMessage: ChatMessage) => ({
  type: CHAT_MESSAGE,
  chatMessage: chatMessage,
});

const world = (state = initialState, action) => {
  switch (action.type) {
    case JOIN_WORLD_ID:
      return {
        ...state,
        joinRoomId: action.joinRoomId,
      };
    case READY_SOCKET:
      return {
        ...state,
        userList: action.userList,
      };
    case CHAT_MESSAGE:
      const newMessage = {
        type: CHAT_MESSAGE,
        content: action.chatMessage.content,
      };

      const copyChatMessages = structuredClone(state.chatMessage);
      const updatedChatMessages = [...copyChatMessages, newMessage];

      if (updatedChatMessages.length > MAX_CHAT_SIZE) {
        updatedChatMessages.shift();
      }
      return {
        ...state,
        chatMessage: updatedChatMessages,
      };

    default:
      return state;
  }
};

export default world;
