export const SET_IS_READY = "room/SET_IS_READY";
export const SET_IS_DATA_READY = "room/SET_IS_DATA_READY";

export const CLEAR_ROOM = "room/CLEAR_ROOM";
// export const READY_SOCKET = "room/RECEVIED_USER_LIST";

export const SET_NEW_ROOM = "room/SET_NEW_ROOM";
export const SET_REMOVE_ROOM = "room/SET_REMOVE_ROOM";
export const SET_NEW_ROOM_MEMBER = "room/SET_NEW_ROOM_MEMBER";

export const SET_LEAVE_ROOM_MEMBER = "room/SET_LEAVE_ROOM_MEMBER";
export const SET_ROOM_INFO = "room/SET_ROOM_INFO";
export const SET_ROOM_LIST = "room/SET_ROOM_LIST";
export const SET_ROOM_COMPETITION_LIST = "room/SET_ROOM_COMPETITION_LIST";
export const SET_ROOM_NORMAL_LIST = "room/SET_ROOM_NORMAL_LIST";

export const SET_ROOM_COMPETITION_OPTION_LIMIT_TIME = "room/SET_ROOM_COMPETITION_OPTION_LIMIT_TIME";
export const SET_ROOM_COMPETITION_OPTION_CAN_JOIN_DURING_COMPETITION =
  "room/SET_ROOM_COMPETITION_OPTION_CAN_JOIN_DURING_COMPETITION";
export const SET_ROOM_COMPETITION_OPTION_RESERVATION_TIME =
  "room/SET_ROOM_COMPETITION_OPTION_RESERVATION_TIME";

export const SET_ROOM_COMPETITION_OPTION_VISIBLE_SCORE =
  "room/SET_ROOM_COMPETITION_OPTION_VISIBLE_SCORE";
export const SET_ROOM_COMPETITION_OPTION = "room/SET_ROOM_COMPETITION_OPTION";

export const SET_ROOM_MEMBER_LIST = "room/SET_ROOM_MEMBER_LIST";
export const SET_ROOM_MEMBER_STATE = "room/SET_ROOM_MEMBER_STATE";

export const UPDATE_ROOM_COMPETITION_CONTENT_LIST = "room/UPDATE_ROOM_COMPETITION_CONTENT_LIST";
export const REMOVE_ROOM_COMPETITION_CONTENT_LIST = "room/REMOVE_ROOM_COMPETITION_CONTENT_LIST";
export const JOIN_ROOM_ID = "room/JOIN_ROOM_ID";
export const CHAT_MESSAGE = "room/CHAT_MESSAGE";
