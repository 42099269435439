import { GoalList, QuestList } from "../api/quest";
import { RootReducer } from "../modules/reducers";
import { useDispatch, useSelector } from "react-redux";
import {
  QuestInitialState,
  QuestPagination,
  QuestType,
  actionClearQuest,
  actionSetIsReady,
  actionSetMainPagination,
  actionSetMainQuest,
  actionSetSubPagination,
  actionSetSubQuest,
} from "../modules/reducers/quest";

const useQuest = () => {
  const dispatch = useDispatch();

  const { main, sub, isReady }: QuestInitialState = useSelector(
    (state: RootReducer) => state.questReducer,
  );

  const appendData = (target: QuestType, questList: QuestList[]) => {
    switch (target) {
      case "MAIN":
        dispatch(actionSetMainQuest([...main.questList, ...questList]));
        break;
      case "SUB":
        dispatch(actionSetSubQuest([...sub.questList, ...questList]));
        break;
      default:
    }
  };

  const setPagination = (target: QuestType, pagination: QuestPagination) => {
    switch (target) {
      case "MAIN":
        dispatch(actionSetMainPagination(pagination));
        break;
      case "SUB":
        dispatch(actionSetSubPagination(pagination));
        break;
      default:
    }
  };

  const containsQuest = (action: string) => {
    if (!main.questList || !sub.questList) {
      return false;
    }

    const questList = [...main.questList, ...sub.questList];

    return (
      questList.filter(
        (questRow: QuestList) =>
          questRow.state === "PERFORMING" &&
          !questRow.quest.clear &&
          questRow.quest.goalList.filter((goalRow: GoalList) => goalRow.action === action).length >
            0,
      ).length > 0
    );
  };

  const getReward = (action: string) => {
    if (!main.questList || !sub.questList) {
      return {
        rewardType: null,
        rewardCount: null,
      };
    }

    const questList = [...main.questList, ...sub.questList];

    const findedItem = questList.find(
      (questRow: QuestList) =>
        questRow.quest.goalList.filter((goalRow: GoalList) => goalRow.action === action).length > 0,
    );

    if (findedItem) {
      return {
        rewardType: findedItem.quest.rewardType,
        rewardCount: findedItem.quest.rewardCount,
      };
    }

    return {
      rewardType: null,
      rewardCount: null,
    };
  };

  const containsQuestReward = () => {
    if (!main.questList || !sub.questList) {
      return false;
    }

    const questList = [...main.questList, ...sub.questList];
    return (
      questList &&
      !!questList.find((row: QuestList) => row.state === "PERFORMING" && row.quest.clear)
    );
  };

  const ready = () => {
    dispatch(actionSetIsReady(true));
  };

  const clear = () => {
    dispatch(actionClearQuest());
  };

  return {
    main,
    sub,
    isReady,
    appendData: (target: QuestType, questList: QuestList[]) => {
      appendData(target, questList);
    },
    setPagination: (target: QuestType, pagination: QuestPagination) => {
      setPagination(target, pagination);
    },
    containsQuest: (action: string) => {
      return containsQuest(action);
    },
    getReward: (action: string) => {
      return getReward(action);
    },
    ready,
    clear,
    containsQuestReward,
  };
};

export default useQuest;
