import { axiosInstance } from "../shared/request";

/**
 * Axios Base URL 지정
 */
export const setAxiosInstance = (options: { baseURL?: string; channelId?: string }) => {
  if (options.baseURL) {
    axiosInstance.defaults.baseURL = options.baseURL;
  }

  if (options.channelId) {
    axiosInstance.defaults.headers["Channel-Id"] = options.channelId;
  }
};

/**
 * Abort Signal 매핑
 */
export const setAbortSignalToAxiosInstance = () => {
  const abortController = new AbortController();
  axiosInstance.defaults.signal = abortController.signal;
  return abortController;
};
