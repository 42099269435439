import { NotificationList } from "../../api/notification";
import { NOTIFICATION_CHAT_TYPE } from "../../constants/notifications";
import {
  CHAT_MESSAGE,
  SET_CHAT_INFO,
  SET_IS_DATA_READY,
  SET_IS_READY,
  SET_NOTIFICATION_LIST,
  SET_PAGINATION,
} from "../actions/notification";

export interface NotificationPagination {
  itemsPerPage: number;
  total: number;
  currentPage: number;
}

export type ChatMessage = {
  chatType: NOTIFICATION_CHAT_TYPE;
  userId: string;
  nickname: string;
  content: string;
};
export type NotificationChatInfoState = {
  type: NOTIFICATION_CHAT_TYPE;
  roomCode: string;
  channelId: number;
  userId: string;
  nickname: string;
};

type ReceiveChatMessage = {
  type: string;
  content: ChatMessage;
};
const MAX_CHAT_SIZE = 100;
export const CHAT_COLOR_MAP = {
  CHANNEL: "#DE9BFF",
  USER: "#8EFFAC",
  LOBBY: "#ffffff",
  ROOM: "#ffffff",
};

export interface NotificationInitialState {
  notificationList: NotificationList[];
  chatMessage: ChatMessage[];
  chatInfo: NotificationChatInfoState;
  pagination: NotificationPagination;
  isDataReady: boolean;
  isReady: boolean;
}

const initialState: NotificationInitialState = {
  notificationList: [],
  chatMessage: [],
  chatInfo: {
    type: "CHANNEL",
    roomCode: null,
    channelId: null,
    userId: null,
  } as NotificationChatInfoState,

  pagination: {
    itemsPerPage: 0,
    total: 0,
    currentPage: 1,
  },
  isDataReady: false,
  isReady: false,
};

export const actionSetNotificationList = (notificationList: NotificationList[]) => ({
  type: SET_NOTIFICATION_LIST,
  notificationList,
});

export const actionSetPagination = (pagination: NotificationPagination) => ({
  type: SET_PAGINATION,
  pagination,
});

export const actionSetIsDataReady = (isDataReady: boolean) => ({
  type: SET_IS_DATA_READY,
  isDataReady,
});

export const actionSetChatInfoState = (chatInfo: NotificationChatInfoState) => ({
  type: SET_CHAT_INFO,
  chatInfo,
});

export const actionSetIsReady = (isReady: boolean) => ({
  type: SET_IS_READY,
  isReady,
});

// room 채팅 메세지
export const actionSetChatMessage = (chatMessage: ChatMessage) => ({
  type: CHAT_MESSAGE,
  chatMessage: chatMessage,
});

const notification = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATION_LIST:
      return {
        ...state,
        notificationList: action.notificationList,
      };
    case SET_PAGINATION:
      return {
        ...state,
        pagination: action.pagination,
      };
    case SET_IS_DATA_READY:
      return {
        ...state,
        isDataReady: action.isDataReady,
      };
    case SET_IS_READY:
      return {
        ...state,
        isReady: action.isReady,
      };
    case CHAT_MESSAGE:
      // const newMessage = {
      //   type: CHAT_MESSAGE,
      //   content: action.chatMessage,
      // };
      console.log("1", state.chatMessage);
      console.log("2", action.chatMessage);

      const copyChatMessages = structuredClone(state.chatMessage);
      const updatedChatMessages = [...copyChatMessages, action.chatMessage];

      if (updatedChatMessages.length > MAX_CHAT_SIZE) {
        updatedChatMessages.shift();
      }
      return {
        ...state,
        chatMessage: updatedChatMessages,
      };

    case SET_CHAT_INFO:
      return {
        ...state,
        chatInfo: action.chatInfo,
      };

    default:
      return state;
  }
};

export default notification;
