import Error from "./pages/Error";
import loadable from "@loadable/component";
import PrivateRoute from "./core/PrivateRoute";
import Ready from "./core/Ready";
import RoleRoute from "./core/RoleRoute";
import RouteChangeTracker from "./core/RouteChangeTracker";
import useEnquiry from "common/hooks/useEnquiry";
import usePayment from "./hooks/usePayment";
import { ErrorBoundary } from "react-error-boundary";
import { getSubDomain } from "./utils/domain";
import { isClient } from "common/utils/common";
import { manageStorage } from "common/utils/storage";
import { Route, Switch, useLocation } from "react-router-dom";
import { useEffect, useLayoutEffect } from "react";
import Cookies from "js-cookie";
import useAuth from "./hooks/useAuth";
import { useDispatch } from "react-redux";
import { actionSetLmsAccessToken, actionSetLmsRefreshToken } from "./modules/reducers/channel";

const handleChunkError = () => {
  window.location.reload();
  return null;
};

const FindPassword = loadable(() =>
  import("./pages/FindPassword").catch((error) => handleChunkError),
);
const ChannelAdmin = loadable(() =>
  import("./pages/ChannelAdmin").catch((error) => handleChunkError),
);
const ChannelBoard = loadable(() =>
  import("./pages/ChannelBoard").catch((error) => handleChunkError),
);
const ChannelEdit = loadable(() =>
  import("./pages/ChannelEdit").catch((error) => handleChunkError),
);
const ChannelHome = loadable(() =>
  import("./pages/ChannelHome").catch((error) => handleChunkError),
);
const ChannelInvite = loadable(() =>
  import("./pages/ChannelInvite").catch((error) => handleChunkError),
);
const Lms = loadable(() => import("./pages/Lms").catch((error) => handleChunkError));
const ChannelMain = loadable(() =>
  import("./pages/ChannelMain").catch((error) => handleChunkError),
);
const ChannelPost = loadable(() =>
  import("./pages/ChannelPost").catch((error) => handleChunkError),
);
const ChannelPostEdit = loadable(() =>
  import("./pages/ChannelPostEdit").catch((error) => handleChunkError),
);
const Landing = loadable(() => import("./pages/Landing").catch((error) => handleChunkError));
const DolphinLanding = loadable(() =>
  import("./pages/DolphinLanding").catch((error) => handleChunkError),
);
const Main = loadable(() => import("./pages/Main").catch((error) => handleChunkError));
const MyPage = loadable(() => import("./pages/MyPage").catch((error) => handleChunkError));
const NotFound = loadable(() => import("./pages/NotFound").catch((error) => handleChunkError));
const Purchase = loadable(() => import("./pages/Purchase").catch((error) => handleChunkError));
const PurchaseResult = loadable(() =>
  import("./pages/PurchaseResult").catch((error) => handleChunkError),
);
const Refresh = loadable(() => import("./pages/Refresh").catch((error) => handleChunkError));
const Signin = loadable(() => import("./pages/Signin").catch((error) => handleChunkError));
const SigninWelcome = loadable(() =>
  import("./pages/SigninWelcome").catch((error) => handleChunkError),
);
const Signout = loadable(() => import("./pages/Signout").catch((error) => handleChunkError));
const Signup = loadable(() => import("./pages/Signup").catch((error) => handleChunkError));
const Support = loadable(() => import("./pages/Support").catch((error) => handleChunkError));

const Shop = loadable(() => import("./pages/Shop").catch((error) => handleChunkError));
const RoomLobby = loadable(() => import("./pages/RoomLobby").catch((error) => handleChunkError));
const Room = loadable(() => import("./pages/Room").catch((error) => handleChunkError));
const RoomBattleScoreboard = loadable(() =>
  import("./pages/RoomBattleScoreboard").catch((error) => handleChunkError),
);
const Game = loadable(() => import("./pages/Game").catch((error) => handleChunkError));

const App = () => {
  // 로컬 스토리지
  manageStorage(process.env.CLIENT_VERSION);

  const enquiry = useEnquiry();
  const location = useLocation();
  const payment = usePayment();
  const auth = useAuth();
  const dispatch = useDispatch();

  // 서브 도메인 (채널 ID)
  const subDomain = isClient() ? getSubDomain() : null;

  // 모바일 Safari vh 대응
  const bindVh = () => {
    document.documentElement.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);
  };

  useEffect(() => {
    bindVh();
    window.addEventListener("resize", bindVh);
  }, []);

  useEffect(() => {
    // 채널톡 Init.
    enquiry.init();

    // 포트원 결제 Init.
    payment.init();
  }, []);

  useEffect(() => {
    if (location.hash) {
      return;
    }

    window.scrollTo({ top: 0, left: 0 });
  }, [location.key]);

  useEffect(() => {
    let domain;

    domain = "." + process.env.CLIENT_HOST;

    window.addEventListener("message", (e) => {
      const data = e.data;
      if (data.type && data.message) {
        switch (data.type) {
          case "ACCESSTOKEN":
            dispatch(actionSetLmsAccessToken(data.message));
            Cookies.remove("accessToken");
            Cookies.set("accessToken", data.message, { domain });
            break;
          case "REFRESH":
            dispatch(actionSetLmsRefreshToken(data.message));
            Cookies.remove("refreshId");
            Cookies.set("refreshId", data.message, { domain });

            break;
          default:
            return;
        }
      }
    });
  }, []);

  return (
    <ErrorBoundary fallback={<Error />}>
      {/* Google Analytics */}
      <RouteChangeTracker />
      <Ready />
      <Switch>
        {/* Private Pages */}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/admin/dashboard"
            render={() => <ChannelAdmin key={location.key} where="DASHBOARD" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/admin/member"
            render={() => <ChannelAdmin key={location.key} where="MEMBER" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/admin/member/pending"
            render={() => <ChannelAdmin key={location.key} where="MEMBER/PENDING" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/admin/account"
            render={() => <ChannelAdmin key={location.key} where="ACCOUNT" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/admin/account/pending"
            render={() => <ChannelAdmin key={location.key} where="ACCOUNT/PENDING" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER"]}
            exact
            path="/admin/member/staff"
            render={() => <ChannelAdmin key={location.key} where="MEMBER/STAFF" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/admin/member/suspend"
            render={() => <ChannelAdmin key={location.key} where="MEMBER/SUSPEND" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/admin/member/ben"
            render={() => <ChannelAdmin key={location.key} where="MEMBER/BEN" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/admin/group"
            render={() => <ChannelAdmin key={location.key} where="GROUP" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/admin/group/:groupId/member"
            render={() => <ChannelAdmin key={location.key} where="GROUP/MEMBER" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/admin/menu"
            render={() => <ChannelAdmin key={location.key} where="MENU" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/admin/marketing"
            render={() => <ChannelAdmin where="MARKETING/SEO" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/admin/marketing/inquiry"
            render={() => <ChannelAdmin where="MARKETING/INQUIRY" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/admin/marketing/popup"
            render={() => <ChannelAdmin where="MARKETING/POPUP" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/admin/setting"
            render={() => <ChannelAdmin where="SETTING" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/admin/todaysentence"
            render={() => <ChannelAdmin where="TODAY_SENTENCE" />}
          />
        )}
        {
          <PrivateRoute
            exact
            path="/channel/create"
            render={() => <ChannelEdit where="CREATE" />}
          />
        }
        {!subDomain && (
          <PrivateRoute
            exact
            path="/channel/update/:channelId"
            render={() => <ChannelEdit where="UPDATE" />}
          />
        )}
        {
          <PrivateRoute
            exact
            path="/channel/joined"
            render={() => <ChannelHome key={location.key} where="JOINED" />}
          />
        }
        {subDomain && (
          <RoleRoute
            role={[
              "ROLE_CHANNEL_MASTER",
              "ROLE_CHANNEL_MANAGER",
              "ROLE_CHANNEL_TEACHER",
              "ROLE_CHANNEL_ADVANCED_LECTURER",
              "ROLE_CHANNEL_ADVANCED",
              "ROLE_CHANNEL_LECTURER",
              "ROLE_CHANNEL_USER",
            ]}
            exact
            path="/invite"
            render={() => <ChannelInvite />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={[
              "ROLE_CHANNEL_MASTER",
              "ROLE_CHANNEL_MANAGER",
              "ROLE_CHANNEL_TEACHER",
              "ROLE_CHANNEL_ADVANCED_LECTURER",
              "ROLE_CHANNEL_ADVANCED",
              "ROLE_CHANNEL_LECTURER",
              "ROLE_CHANNEL_USER",
            ]}
            exact
            path="/post"
            render={() => <ChannelPostEdit where="WRITE" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={[
              "ROLE_CHANNEL_MASTER",
              "ROLE_CHANNEL_MANAGER",
              "ROLE_CHANNEL_TEACHER",
              "ROLE_CHANNEL_ADVANCED_LECTURER",
              "ROLE_CHANNEL_ADVANCED",
              "ROLE_CHANNEL_LECTURER",
              "ROLE_CHANNEL_USER",
            ]}
            exact
            path="/post/:postId/edit"
            render={() => <ChannelPostEdit where="EDIT" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/lms/membergroup"
            render={() => <Lms key={location.key} where="MEMBERGROUP" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/lms/membergroup/:memberGroupId/member"
            render={() => <Lms key={location.key} where="MEMBERGROUP/MEMBER" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/lms/membergroup/:memberGroupId/member"
            render={() => <Lms key={location.key} where="MEMBERGROUP/MEMBER" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/lms/member/:channelMemberId/study"
            render={() => <Lms key={location.key} where="MEMBERGROUP/MEMBER/STUDY/ASSIGNMENT" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/lms/member/:channelMemberId/course/:courseId/lesson/study"
            render={() => <Lms key={location.key} where="MEMBERGROUP/MEMBER/STUDY/COURSE/LESSON" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/lms/member/:channelMemberId/worksheet/:worksheetId/chapter/study"
            render={() => (
              <Lms key={location.key} where="MEMBERGROUP/MEMBER/STUDY/WORKSHEET/CHAPTER" />
            )}
          />
        )}

        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/lms/competition"
            render={() => <Lms key={location.key} where="COMPETITION" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/lms/competition/:competitionId/member"
            render={() => <Lms key={location.key} where="COMPETITION/MEMBER" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/lms/competition/:competitionId/member/:memberId"
            render={() => <Lms key={location.key} where="COMPETITION/MEMBER/VIEW" />}
          />
        )}

        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/lms/competition/:competitionId/member/:memberId/pdf"
            render={() => <Lms key={location.key} where="COMPETITION/MEMBER/VIEW/PDF" />}
          />
        )}

        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/lms/problem"
            render={() => <Lms key={location.key} where="PROBLEM" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/lms/problem/:problemId/member"
            render={() => <Lms key={location.key} where="PROBLEM/MEMBER" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/lms/problem/:problemId/member/:memberId"
            render={() => <Lms key={location.key} where="PROBLEM/MEMBER/VIEW" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/lms/worksheet"
            render={() => <Lms key={location.key} where="WORKSHEET" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/lms/worksheet/:worksheetId/chapter"
            render={() => <Lms key={location.key} where="WORKSHEET/CHAPTER" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/lms/worksheet/:worksheetId/chapter/:chapterId/problem/:problemId/member"
            render={() => <Lms key={location.key} where="WORKSHEET/CHAPTER/PROBLEM/MEMBER" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/lms/worksheet/:worksheetId/chapter/:chapterId/problem/:problemId/member/:memberId"
            render={() => <Lms key={location.key} where="WORKSHEET/CHAPTER/PROBLEM/MEMBER/VIEW" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/lms/worksheet/:worksheetId/chapter/:chapterId/block/:blockCodingId/member"
            render={() => <Lms key={location.key} where="WORKSHEET/CHAPTER/BLOCK/MEMBER" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/lms/worksheet/:worksheetId/chapter/:chapterId/block/:blockCodingId/member/:memberId"
            render={() => <Lms key={location.key} where="WORKSHEET/CHAPTER/BLOCK/MEMBER/VIEW" />}
          />
        )}

        {/* block coding */}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/lms/blockCoding"
            render={() => <Lms key={location.key} where="BLOCKCODING" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/lms/blockCoding/:blockCodingId/member"
            render={() => <Lms key={location.key} where="BLOCKCODING/MEMBER" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/lms/blockCoding/:blockCodingId/member/:memberId"
            render={() => <Lms key={location.key} where="BLOCKCODING/MEMBER/VIEW" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/lms/video"
            render={() => <Lms key={location.key} where="VIDEO" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/lms/video/:videoId/member"
            render={() => <Lms key={location.key} where="VIDEO/MEMBER" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/lms/course"
            render={() => <Lms key={location.key} where="COURSE" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/lms/course/:courseId/lesson"
            render={() => <Lms key={location.key} where="COURSE/LESSON" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/lms/course/:courseId/lesson/:lessonId/problem/:problemId/member"
            render={() => <Lms key={location.key} where="COURSE/LESSON/PROBLEM/MEMBER" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/lms/course/:courseId/lesson/:lessonId/problem/:problemId/member/:memberId"
            render={() => <Lms key={location.key} where="COURSE/LESSON/PROBLEM/MEMBER/VIEW" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/lms/course/:courseId/lesson/:lessonId/video/:videoId/member"
            render={() => <Lms key={location.key} where="COURSE/LESSON/VIDEO/MEMBER" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/lms/homework"
            render={() => <Lms key={location.key} where="HOMEWORK" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/lms/homework/assignment"
            render={() => <Lms key={location.key} where="HOMEWORK/ASSIGNMENT" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/lms/homework/:homeworkId/assignment/:assignmentId/member"
            render={() => <Lms key={location.key} where="HOMEWORK/ASSIGNMENT/MEMBER" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/lms/homework/:homeworkId/assignment/:assignmentId/member/:memberId/task"
            render={() => <Lms key={location.key} where="HOMEWORK/ASSIGNMENT/MEMBER/TASK" />}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/lms/homework/:homeworkId/assignment/:assignmentId/member/:memberId/task/:taskId/problem/:problemId"
            render={() => (
              <Lms key={location.key} where="HOMEWORK/ASSIGNMENT/MEMBER/TASK/PROBLEM/VIEW" />
            )}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/lms/homework/:homeworkId/assignment/:assignmentId/member/:memberId/task/:taskId/block/:blockCodingId"
            render={() => (
              <Lms key={location.key} where="HOMEWORK/ASSIGNMENT/MEMBER/TASK/BLOCK/VIEW" />
            )}
          />
        )}
        {subDomain && (
          <RoleRoute
            role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/lms/homework/:homeworkId/assignment/:assignmentId/member/:memberId/task/:taskId/video/:videoId"
            render={() => (
              <Lms key={location.key} where="HOMEWORK/ASSIGNMENT/MEMBER/TASK/VIDEO/VIEW" />
            )}
          />
        )}
        {
          <PrivateRoute
            exact
            path="/my-page/setting/profile"
            render={() => <MyPage where="SETTING/PROFILE" />}
          />
        }
        {
          <PrivateRoute
            exact
            path="/my-page/setting/password"
            render={() => <MyPage where="SETTING/PASSWORD" />}
          />
        }
        {
          <PrivateRoute
            exact
            path="/purchase/history/payment"
            render={() => <Purchase key={location.key} where="HISTORY/PAYMENT" />}
          />
        }
        {
          <PrivateRoute
            exact
            path="/purchase/history/subscription"
            render={() => <Purchase key={location.key} where="HISTORY/SUBSCRIPTION" />}
          />
        }

        {/* Public Pages */}
        {!subDomain && <Route exact path="/find-password" render={() => <FindPassword />} />}
        {subDomain && (
          <Route exact path="/board/:boardId" render={() => <ChannelBoard key={location.key} />} />
        )}
        {
          <Route
            exact
            path="/channel"
            render={() => <ChannelHome key={location.key} where="HOME" />}
          />
        }
        {subDomain ? (
          <Route exact path="/" render={() => <ChannelMain />} />
        ) : (
          <Route exact path="/" render={() => <DolphinLanding />} />
        )}
        {subDomain && <Route exact path="/post/:postId" render={() => <ChannelPost />} />}
        {!subDomain && <Route exact path="/company/landing" render={() => <Landing />} />}
        {!subDomain && <Route exact path="/landing" render={() => <DolphinLanding />} />}

        {<Route exact path="/purchase/product" render={() => <Purchase where="PRODUCT" />} />}
        {!subDomain && <Route exact path="/purchase/result" render={() => <PurchaseResult />} />}
        <Route exact path="/refresh" render={() => <Refresh />} />
        {!subDomain && <Route exact path="/signin" render={() => <Signin where="EMAIL" />} />}
        <Route exact path="/signin/welcome" render={() => <SigninWelcome />} />
        {!subDomain && (
          <Route exact path="/signin/google" render={() => <Signin where="GOOGLE" />} />
        )}
        {!subDomain && <Route exact path="/signin/kakao" render={() => <Signin where="KAKAO" />} />}
        {!subDomain && <Route exact path="/signin/naver" render={() => <Signin where="NAVER" />} />}
        {!subDomain && <Route exact path="/signout" render={() => <Signout />} />}
        {!subDomain && <Route exact path="/signup" render={() => <Signup where="SELECTION" />} />}
        {!subDomain && <Route exact path="/signup/email" render={() => <Signup where="EMAIL" />} />}
        {!subDomain && (
          <Route exact path="/signup/channel" render={() => <Signup where="CHANNEL" />} />
        )}
        {!subDomain && <Route exact path="/shop" render={() => <Shop />} />}

        {subDomain && (
          <RoleRoute
            role={[
              "ROLE_CHANNEL_MASTER",
              "ROLE_CHANNEL_MANAGER",
              "ROLE_CHANNEL_TEACHER",
              "ROLE_CHANNEL_ADVANCED_LECTURER",
              "ROLE_CHANNEL_ADVANCED",
              "ROLE_CHANNEL_LECTURER",
              "ROLE_CHANNEL_USER",
            ]}
            exact
            path="/shop"
            render={() => <Shop />}
          />
        )}

        {subDomain && (
          <Route
            // role={["ROLE_CHANNEL_MASTER", "ROLE_CHANNEL_MANAGER"]}
            exact
            path="/room/lobby"
            render={() => <RoomLobby key={location.key} where={"LOBBY"} />}
          />
        )}

        {!subDomain && (
          <RoleRoute
            role={["ROLE_ADMIN"]}
            exact
            path="/room/lobby"
            render={() => <RoomLobby key={location.key} where={"LOBBY"} />}
          />
        )}
        {subDomain && (
          <Route
            // role={["ROLE_ADMIN"]}
            exact
            path="/room/:roomCode"
            render={() => <Room where={"ROOM"} />}
          />
        )}
        {!subDomain && (
          <RoleRoute
            role={["ROLE_ADMIN"]}
            exact
            path="/room/:roomCode"
            render={() => <Room where={"ROOM"} />}
          />
        )}
        {subDomain && (
          <Route
            exact
            path="/room/:roomCode/competition/:competitionId/scoreboard"
            render={() => <RoomBattleScoreboard where={"ROOM/SCOREBOARD"} />}
          />
        )}
        {!subDomain && (
          <RoleRoute
            exact
            role={["ROLE_ADMIN"]}
            path="/room/:roomCode/competition/:competitionId/scoreboard"
            render={() => <RoomBattleScoreboard where={"ROOM/SCOREBOARD"} />}
          />
        )}

        {/* {!subDomain && <Route exact path="/world/room" render={() => <RoomLobby />} />} */}
        {/* {!subDomain && (
          <Route exact path="/world/room/:roomCode" render={() => <Room where={"ROOM"} />} />
        )} */}
        {!subDomain && (
          <RoleRoute
            role={["ROLE_ADMIN"]}
            exact
            path="/world/:worldId"
            render={() => <Game where={"METAVERSE"} />}
          />
        )}
        {!subDomain && (
          <Route
            exact
            path="/signup/google/:verificationId"
            render={() => <Signup where="GOOGLE" />}
          />
        )}
        {!subDomain && (
          <Route
            exact
            path="/signup/kakao/:verificationId"
            render={() => <Signup where="KAKAO" />}
          />
        )}
        {!subDomain && (
          <Route
            exact
            path="/signup/naver/:verificationId"
            render={() => <Signup where="NAVER" />}
          />
        )}
        {!subDomain && <Route exact path="/support/faq" render={() => <Support where="FAQ" />} />}
        {!subDomain && (
          <Route
            exact
            path="/support/terms-of-service"
            render={() => <Support where="TERMS_OF_SERVICE" />}
          />
        )}
        {!subDomain && (
          <Route exact path="/support/privacy" render={() => <Support where="PRIVACY" />} />
        )}
        {isClient() && <Route path="*" render={() => <NotFound />} />}
      </Switch>
    </ErrorBoundary>
  );
};

export default App;
