import { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { ItemCategory } from "common/hooks/useAvator";

type SpriteAnimatorType = ItemCategory | "BODY";

interface SpriteAnimatorProps {
  step: number;
  type: SpriteAnimatorType;
  imageUrl: string;
  size?: number;
}

const SpriteAnimator = ({ step, type, imageUrl, size }: SpriteAnimatorProps) => {
  function mapingTypeToZIndex(type: SpriteAnimatorType) {
    switch (type) {
      case "TOP":
        return 1;
      case "BOTTOM":
        return 1;
      case "SHOES":
        return 1;
      case "HAIR":
        return 1;

      default:
        return 1;
    }
  }
  return (
    <>
      <div
        css={css`
          background-image: url(${imageUrl});
          background-position: -${step * (size ? size : 299)}px 0;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: ${mapingTypeToZIndex(type)};
        `}
      />
    </>
  );
};

export default SpriteAnimator;
