import { END_SOUND, PLAY_SOUND } from "../actions/sound";
import { SOUND_DATA } from "../../constants/sounds";
import { upperSnakeToCamel } from "../../utils/format";

export interface SoundInitialState {
  notification: {
    src: string;
    state: "PLAY" | "STOP";
  };
  purchase: {
    src: string;
    state: "PLAY" | "STOP";
  };
  splash: {
    src: string;
    state: "PLAY" | "STOP";
  };
}

const initialState: SoundInitialState = {
  notification: {
    src: SOUND_DATA.notification.src,
    state: "STOP",
  },
  purchase: {
    src: SOUND_DATA.purchase.src,
    state: "STOP",
  },
  splash: {
    src: SOUND_DATA.splash.src,
    state: "STOP",
  },
};

export type SoundTarget = "NOTIFICATION" | "PURCHASE" | "SPLASH";

export const actionPlaySound = ({ target }: { target: SoundTarget }) => ({
  type: PLAY_SOUND,
  target,
});

export const actionEndSound = ({ target }: { target: SoundTarget }) => ({
  type: END_SOUND,
  target,
});

const sound = (state = initialState, action: any) => {
  switch (action.type) {
    case PLAY_SOUND:
      return {
        ...state,
        [upperSnakeToCamel(action.target)]: {
          ...state[upperSnakeToCamel(action.target)],
          state: "PLAY",
        },
      };
    case END_SOUND:
      return {
        ...state,
        [upperSnakeToCamel(action.target)]: {
          ...state[upperSnakeToCamel(action.target)],
          state: "STOP",
        },
      };
    default:
      return state;
  }
};

export default sound;
