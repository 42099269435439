import { css } from "@emotion/react";
import { MEDIA_QUERIES } from "common/constants/styles/responsive";

const styles = {
  backdrop: css`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
  `,
  wrapper: css`
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    flex-direction: column;
    transform: translate(-50%, -50%);
    z-index: 1060;

    ${MEDIA_QUERIES.max.sm} {
      left: 0;
      transform: translate(0, -50%);
      width: calc(100% - 2rem);
    }
  `,
  container: css`
    position: relative;
    opacity: 0;
    padding: 1.875rem;
    width: 26.875rem;

    ${MEDIA_QUERIES.max.sm} {
      margin-right: 1rem;
      margin-left: 1rem;
      padding: 1.875rem 1.25rem 1.25rem 1.25rem;
      width: 100%;
    }
  `,
  character: css`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, calc(-100% + 50px));
  `,
  dolphin: css`
    transform: translate(-40px, 10px);
    width: 12.3125rem;
  `,
  crab: css`
    width: 11.125rem;
  `,
  turtle: css`
    width: 6.6875rem;
  `,
  nemo: css`
    width: 9rem;
  `,
  seahorse: css`
    width: 8.4375rem;
  `,
  shark: css`
    width: 7.6875rem;
  `,
  heading: css`
    margin-top: 1.875rem;
    text-align: center;
    font-size: 1.125rem;
  `,
  badge: css`
    background-color: #e9f3ff;
    color: #44adff;
  `,
  title: css`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.5625rem;
  `,
  level: css`
    margin-right: 0.625rem;
    font-size: 0.875rem;
  `,
  description: css`
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.875rem;
  `,
  content: css`
    margin-top: 1.25rem;
    margin-bottom: 2.5rem;
    max-height: 20vh;
    overflow-y: auto;
    font-size: 1.125rem;

    li::marker {
      color: #3ba9ff;
      font-weight: 700;
      content: ">  ";
    }
  `,
  reward: css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.625rem;
    padding: 0.6875rem 0.9375rem;
    font-weight: 700;

    img {
      margin-right: 0.3125rem;
      margin-left: 0.625rem;
      width: 1.125rem;
      height: 1.125rem;
    }
  `,
  footer: css`
    display: flex;

    .btn {
      flex: 1;
      margin-right: 0.625rem;
    }

    ${MEDIA_QUERIES.max.sm} {
      .btn {
        font-size: 0.75rem;
      }
    }
  `,
};

export { styles };
