import { useEffect, useRef } from "react";
import useNotification from "../../../hooks/useNotification";
import usePopup from "../../../hooks/usePopup";
import { styles } from "./styles";
import { isClient } from "common/utils/common";
import useAuth from "../../../hooks/useAuth";
import useToast from "common/hooks/useToast";

const PopupUserClickProfile = () => {
  const auth = useAuth();
  const notification = useNotification();
  const toast = useToast();

  const popup = usePopup();
  const modalElRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalElRef.current && !modalElRef.current.contains(event.target)) {
        popup.clickUser.close();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popup.clickUser.visible]);

  return (
    auth.userId &&
    popup.clickUser.visible && (
      <div
        ref={modalElRef}
        css={auth.userId === popup.clickUser.userId ? styles.popup1 : styles.popup2}
        style={{
          left: popup.clickUser.left,
          top: popup.clickUser.top,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {auth.userId !== popup.clickUser.userId && (
          <button
            css={styles.popupButton}
            onClick={() => {
              popup.clickUser.close();

              notification.setChatInfo({
                type: "USER",
                channelId: null,
                roomCode: null,
                userId: popup.clickUser.userId,
                nickname: popup.clickUser.nickname,
              });
            }}
          >
            귓속말하기
          </button>
        )}
        <button
          css={styles.popupButton}
          onClick={() => {
            console.log("clickedUser 1", popup.clickUser.userId);
            const userId = popup.clickUser.userId;
            popup.userDetail.show(userId);
            popup.clickUser.close();
            console.log("clickedUser 2", popup.clickUser.userId);
          }}
        >
          유저 상세
        </button>
      </div>
    )
  );
};

export default PopupUserClickProfile;
