export const SET_NOTICE_ONCE = "popup/SET_NOTICE_ONCE";
export const SET_NOTICE_VISIBLE = "popup/SET_NOTICE_VISIBLE";
export const SET_NOTICE_CONTENT = "popup/SET_NOTICE_CONTENT";
export const SET_PREMIUM_REQIRED_VISIBLE = "popup/SET_PREMIUM_REQIRED_VISIBLE";
export const SET_TUTORIAL_VISIBLE = "popup/SET_TUTORIAL_VISIBLE";

export const SET_USER_DETAIL_VISIBLE = "popup/SET_USER_DETAIL_VISIBLE";
export const SET_FRIENDSHIP_VISIBLE = "popup/SET_FRIENDSHIP_VISIBLE";
export const SET_CLICK_USER_VISIBLE = "popup/SET_CLICK_USER_VISIBLE";

export const SET_SIGNUP_REQIRED_VISIBLE = "popup/SET_SIGNUP_REQIRED_VISIBLE";
export const SET_REWARD_VISIBLE = "popup/SET_REWARD_VISIBLE";
export const SET_REWARD_CONTENT = "popup/SET_REWARD_CONTENT";
export const SET_RECOMMEND_QUEST_ONCE = "popup/SET_RECOMMEND_QUEST_ONCE";
export const SET_RECOMMEND_QUEST_VISIBLE = "popup/SET_RECOMMEND_QUEST_VISIBLE";
export const SET_QUEST_QUEUE = "popup/SET_QUEST_QUEUE";
export const SET_QUEST_VISIBLE = "popup/SET_QUEST_VISIBLE";
export const SET_HEADER_QUEST_POPUP = "popup/SET_HEADER_QUEST_POPUP";
