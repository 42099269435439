/**
 * 서브 도메인 가져오기
 */
const getSubDomain = () => {
  const subDomain = window.location.hostname.replace(process.env.CLIENT_HOST, "").split(".");

  return window.location.hostname.includes(process.env.CLIENT_HOST) && subDomain.length > 0
    ? subDomain[0]
    : null;
};

export { getSubDomain };
