import App from "./App";
import rootReducer from "./modules/reducers";
import { BrowserRouter } from "react-router-dom";
import { CacheProvider } from "@emotion/react";
import { createEmotionCache } from "./utils/emotion";
import { HelmetProvider } from "react-helmet-async";
import { hydrateRoot } from "react-dom/client";
import { legacy_createStore as createStore } from "redux";
import { Provider } from "react-redux";
import TagManager from "react-gtm-module";

import "./i18n";
import "animate.css";
import "core-js/stable";
import "prism-themes/themes/prism-vsc-dark-plus.css";
import "rc-tooltip/assets/bootstrap.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-lazy-load-image-component/src/effects/opacity.css";

const tagManagerArgs = {
  gtmId: process.env.GOOGLE_TAG_MANAGER,
};

TagManager.initialize(tagManagerArgs);

const store = createStore(rootReducer);
const cache = createEmotionCache();

const helmetContext = { helmet: null };
const domNode = document.getElementById("root");

const reactNode = (
  <BrowserRouter basename="/">
    <CacheProvider value={cache}>
      <HelmetProvider context={helmetContext}>
        <Provider store={store}>
          <App />
        </Provider>
      </HelmetProvider>
    </CacheProvider>
  </BrowserRouter>
);

hydrateRoot(domNode, reactNode);
