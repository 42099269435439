import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "../shared/request";
import { ChannelMemberRole } from "../constants/channels";
import { ChannelRole } from "../modules/reducers/channel";
import { ChannelMemberList } from "./channel-admin";

/**
 * POST REPORT FILE전용
 *
 * **/

export type POST_TYPE = "DEFAULT" | "NOTICE";

export interface GetSavedChannelReportFileResponse {
  reportFileList: ReportFile[];
}

export interface ReportFile {
  reportFileId: number;
  filename: string;
  urlPath: string;
}

const INPUT_FORM_TYPE_VIEW = {
  TEXT: "텍스트",
  TEXTAREA: "긴글 텍스트",
  IMAGE: "이미지",
  FILE: "첨부 파일",
};

type INPUT_FORM_TYPE = "TEXT" | "TEXTAREA" | "IMAGE" | "FILE";

const INPUT_FORM_TYPE_LIST = ["TEXT", "TEXTAREA", "IMAGE", "FILE"];

export { INPUT_FORM_TYPE_VIEW, INPUT_FORM_TYPE, INPUT_FORM_TYPE_LIST };
/**
 * API:
 * 학습자료 생성 > 첨부파일 저장
 */
export const uploadChannelReportFile = (
  postId: number,
  formData,
  handleProgress: (ratio: number) => void,
) => {
  return axiosInstance
    .post<unknown>(`/v1/users/channels/posts/${postId}/report-files`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
      timeout: 0,
      onUploadProgress: (event: ProgressEvent) => {
        const { loaded, total } = event;
        const ratio = loaded / total;

        handleProgress(ratio);
      },
    })
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 학습자료 생성 > 임시 첨부파일 저장
 */
export const uploadChannelTempReportFile = (formData, handleProgress: (ratio: number) => void) => {
  return axiosInstance
    .post<unknown>(`/v1/users/channels/posts/temps/report-files`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
      timeout: 0,
      onUploadProgress: (event: ProgressEvent) => {
        const { loaded, total } = event;
        const ratio = loaded / total;

        handleProgress(ratio);
      },
    })
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 학습자료 생성 > 첨부파일
 */
export const getSavedChannelReportFile = (postId: number) => {
  return axiosInstance
    .get<GetSavedChannelReportFileResponse>(`/v1/channels/posts/${postId}/report-files`)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 학습자료 생성 > 첨부파일
 */
export const getSavedChannelTempReportFile = () => {
  return axiosInstance
    .get<GetSavedChannelReportFileResponse>(`/v1/users/channels/posts/temps/report-files`)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 학습자료 생성 > 첨부파일 > 삭제
 */
export const deleteChannelReportFile = (postId: number, reportFileId: number) => {
  return axiosInstance
    .delete<unknown>(`/v1/users/channels/posts/${postId}/report-files/${reportFileId}`)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 학습자료 생성 > 첨부파일
 */
export const getChannelProxyApi = (encodedUrl) => {
  return axiosInstance
    .get<AxiosResponse>(`/v1/channels/proxies/proxy.json?url=${encodedUrl}`)
    .then((response: AxiosResponse) => {
      return response;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};
/**
 * API:
 * 학습자료 생성 > 첨부파일 > 삭제
 */
export const deleteChannelTempReportFile = (reportFileId: number) => {
  return axiosInstance
    .delete<unknown>(`/v1/users/channels/posts/temps/report-files/${reportFileId}`)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * POST REPORT FILE전용
 *
 * **/

export interface GetChannelRequest {
  params: { page: number; keyword?: string };
  paramsSerializer: (params: string[]) => string;
}

export interface GetChannelResponse {
  channelList: ChannelList[];
  pageCount: number;
  totalCount: number;
}

export interface ChannelList {
  channelId: number;
  channelDomain: string;
  channelName: string;
  description: string;
  channelRole: ChannelRole;
  channelLogoUrl: string;
  channelMemberCount: number;
  createdAt: string;
}

export interface GetJoinedChannelRequest {
  params: { page: number };
}

export interface GetJoinedChannelResponse {
  channels: JoinedChannelList[];
  pageCount: number;
  totalCount: number;
}

export interface JoinedChannelList {
  channelId: number;
  channelDomain: string;
  channelName: string;
  description: string;
  channelRole: ChannelRole;
  channelLogoUrl: string;
  channelMemberCount: number;
  createdAt: string;
  isMadeByMe: boolean;
}

export interface GetCreatedChannelRequest {
  params: { page: number };
}

export interface AutocompleteChannelRequest {
  params: { keyword: string };
  paramsSerializer: (params: string[]) => string;
}

export interface AutocompleteChannelResponse {
  channelNames: ChannelNameList[];
}

export interface ChannelNameList {
  id: number;
  name: string;
  domain: string;
  logo: string;
}

export interface GetChannelInternalInfoResponse {
  channelId: number;
  channelRole: ChannelRole;
}

export interface GetChannelInfoResponse {
  id: number;
  role: ChannelRole;
  name: string;
  logoUrl: string;
  bannerUrl: string;
  createdAt: string;
  joinMethod: "IMMEDIATELY" | "MANAGER_APPROVAL";
  description: string;
  memberCount: number;
  whiteLogoUrl: string;
}

export interface ChannelPluginMap {
  GOOGLE_ANALYTICS_4: {
    isUsed: boolean;
    pluginKey: string;
  };
  NAVER_SEARCH_ADVISOR: {
    isUsed: boolean;
    pluginKey: string;
  };
  CHANNEL_TALK: {
    isUsed: boolean;
    pluginKey: string;
  };
  KAKAO_CHANNEL: {
    isUsed: boolean;
    pluginKey: string;
  };
}

export interface GetChannelMemberInfoResponse {
  channelMemberRoleCountMap: ChannelMemberRoleCountMap;
}

export interface ChannelMemberRoleCountMap {
  ROLE_CHANNEL_ADVANCED: number;
  ROLE_CHANNEL_ADVANCED_LECTURER: number;
  ROLE_CHANNEL_TEACHER: number;
  ROLE_CHANNEL_MASTER: number;
  ROLE_CHANNEL_MANAGER: number;
}

export interface CreateChannelRequest {
  channelName: string;
  channelDomain: string;
  channelJoinMethod: "IMMEDIATELY" | "MANAGER_APPROVAL";
  description: string;
}

export interface CreateChannelResponse {
  channelId: number;
}

export interface GetSavedChannelResponse {
  id: number;
  channelDomain: string;
  creator: string;
  channelName: string;
  description: string;
  channelJoinMethod: "IMMEDIATELY" | "MANAGER_APPROVAL";
  channelRole: string;
  channelLogo: string;
  channelBanner: string;
  channelWhiteLogo: string;
}

export interface JoinChannelRequest {
  nickname: string;
  introduction: string;
}

export interface GetChannelRecommendCourseResponse {
  courseList: CourseList[];
  pageCount: number;
  totalCount: number;
}

export interface CourseList {
  courseId: number;
  title: string;
  comment: string;
  level: string;
  courseCategory: string;
  channelId: number;
  teacher: Teacher;
  enrollState: EnrollState;
  enrollCount: number;
  thumbnail: string;
  videoCount: number;
  reviewCount: number;
  rating: number;
  paymentType: PaymentType;
  cost: number;
}

export interface Teacher {
  nickname: string;
  userId: string;
  profile: string;
  introduction: string;
}

export type EnrollState = "ENROLLED" | "NOT_ENROLLED";

export type PaymentType = "POINT" | "CASH";

export interface GetChannelMenuResponse {
  boardItemList: BoardItemList[];
}

export interface BoardItemList {
  itemType: "BOARD" | "BOARD_GROUP";
  board: Board;
  boardGroup: BoardGroup;
}

export interface Board {
  boardId: number;
  number: number;
  boardCategory: "NOTICE" | "QNA" | "FREE" | "DEFAULT" | "REPORT" | "CHANNEL_LIBRARY";
  title: string;
  inputFormId: number;
  description: string;
  accessMemberRole: ChannelMemberRole;
  writeMemberRole: ChannelMemberRole;
  postListViewRole: "ANY" | "ONLY_CREATOR_AND_MANAGER_ACCESS";
}

export interface BoardGroup {
  boardGroupId: number;
  number: number;
  title: string;
  description: string;
  boardList: Board[];
}

export interface SaveChannelMenuReqeust {
  boardItemList: BoardItemList[];
}

export interface GetChannelPostRequest {
  params: { board_id: number; page: number; keyword?: string };
  paramsSerializer: (params: string[]) => string;
}

export interface GetChannelBoardResponse {
  posts: PostList[];
  totalCount: number;
  pageCount: number;
}

export interface PostList {
  id: number;
  title: string;
  authorUuid: string;
  authorNickname: string;
  createdAt: string;
  viewCount: number;
  accessChannelMemberList: ChannelMemberList[];
}

export interface GetChannelPostInfoResponse {
  id: number;
  boardId: number;
  boardCategory: string;
  postCategory: string;
  postType: string;
  title: string;
  content: string;
  authorUuid: string;
  authorNickname: string;
  authorProfile: string;
  createdAt: string;
  viewCount: number;
}

export interface SaveChannelPostRequest {
  boardId: number;
  accessChannelMemberIdList: number[];
  title: string;
  content: string;
}

export interface GetSavedChannelPostResponse {
  id: number;
  boardId: number;
  // channelMemberId: number;
  postType: POST_TYPE;
  boardCategory: string;
  postCategory: "NORMAL" | "PDF_VIEWER" | "SPREADSHEET";
  title: string;
  content: string;
  accessChannelMemberList: ChannelMemberList[];
  createdAt: string;
  viewCount: number;
}

export interface GetChannelPostCommentRequest {
  params: { page: number };
}

export interface GetChannelPostCommentResponse {
  comments: CommentList[];
  pageCount: number;
  totalCount: number;
}

export interface CommentList {
  id: number;
  content: string;
  authorUuid: string;
  authorNickname: string;
  authorProfile: string;
  replyCount: number;
  createdAt: string;
}

export interface SaveChannelPostCommentRequest {
  content: string;
}

export interface GetChannelPostReplyRequest {
  params: { page: number };
}

export interface GetChannelPostReplyResponse {
  comments: ReplyList[];
  pageCount: number;
  totalCount: number;
}
export interface ReplyList {
  id: number;
  content: string;
  authorUuid: string;
  authorNickname: string;
  authorProfile: string;
  createdAt: string;
}

export interface SaveChannelPostReplyRequest {
  content: string;
}

export interface GetChannelPopupResponse {
  popupList: PopupList[];
  pageCount: number;
  totalCount: number;
}

export interface PopupList {
  channelPopupId: number;
  userProfile: UserProfile;
  title: string;
  content: string;
  link: string;
  popupShowingMethod: "ALL" | "CHANNEL_HOME" | "CHANNEL_DOLPHIN_HOME";
  startDate: string;
  endDate: string;
  isUsed: boolean;
}

export interface UserProfile {
  nickname: string;
  userId: string;
  profile: string;
  introduction: string;
}

/**
 * API:
 * 채널 목록
 */
export const getChannel = (params: GetChannelRequest) => {
  return axiosInstance
    .get<GetChannelResponse>("/v1/channels", params)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 내가 가입한 채널 목록
 */
export const getJoinedChannel = (params: GetJoinedChannelRequest) => {
  return axiosInstance
    .get<GetJoinedChannelResponse>("/v1/channels/me/joined", params)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 내가 만든 채널 목록
 */
export const getCreatedChannel = (params: GetCreatedChannelRequest) => {
  return axiosInstance
    .get<GetJoinedChannelResponse>("/v1/channels/me/created", params)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 채널 검색 자동완성
 */
export const autocompleteChannel = (params: AutocompleteChannelRequest) => {
  return axiosInstance
    .get<AutocompleteChannelResponse>("/v1/channels/auto-complete", params)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 채널 내부 정보
 */
export const getChannelInternalInfo = (channelDomain: string) => {
  return axiosInstance
    .get<GetChannelInternalInfoResponse>(`/v1/channels/info/${channelDomain}`)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 채널 정보
 */
export const getChannelInfo = (channelId: number) => {
  return axiosInstance
    .get<GetChannelInfoResponse>(`/v1/channels/profile/${channelId}`)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 채널 멤버 정보
 */
export const getChannelMemberInfo = (channelId: number) => {
  return axiosInstance
    .get<GetChannelMemberInfoResponse>(`/v1/users/channels/${channelId}/members/roles/counts`)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 채널 만들기
 */
export const createChannel = (payload: CreateChannelRequest) => {
  return axiosInstance
    .post<CreateChannelResponse>("/v1/channels", payload)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 저장된 채널
 */
export const getSavedChannel = (channelId: number) => {
  return axiosInstance
    .get<GetSavedChannelResponse>(`/v1/channels/${channelId}`)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 채널 변경하기
 */
export const updateChannel = (channelId: number, payload: CreateChannelRequest) => {
  return axiosInstance
    .put<unknown>(`/v1/channels/${channelId}`, payload)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 채널 로고 업로드
 */
export const uploadChannelLogo = (channelId: number, formData) => {
  return axiosInstance
    .put<unknown>(`/v1/channels/${channelId}/logos`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
      timeout: 0,
    })
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 채널 하단 로고 업로드
 */
export const uploadChannelFooterLogo = (channelId: number, formData) => {
  return axiosInstance
    .put<unknown>(`/v1/channels/${channelId}/logos/whites`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
      timeout: 0,
    })
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 채널 배너 업로드
 */
export const uploadChannelBanner = (channelId: number, formData) => {
  return axiosInstance
    .put<unknown>(`/v1/channels/${channelId}/banners`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
      timeout: 0,
    })
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 채널 가입
 */
export const joinChannel = (channelId: number, payload: JoinChannelRequest) => {
  return axiosInstance
    .post<unknown>(`/v1/channels/${channelId}/members`, payload)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 채널 멤버 정보 수정
 */
export const updateChannelMemberInfomation = (channelId: number, payload: JoinChannelRequest) => {
  return axiosInstance
    .put<unknown>(`/v1/users/channels/${channelId}/members`, payload)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 추천 강의 목록
 */
export const getChannelRecommendCourse = (channelId: number) => {
  return axiosInstance
    .get<GetChannelRecommendCourseResponse>(`/v1/channels/${channelId}/recommendations/courses`)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 게시판 목록
 */
export const getChannelMenu = (channelId: number) => {
  return axiosInstance
    .get<GetChannelMenuResponse>(`/v1/channels/${channelId}/boards`)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 게시판 메뉴 저장
 */
export const saveChannelMenu = (channelId: number, payload: SaveChannelMenuReqeust) => {
  return axiosInstance
    .post<unknown>(`/v1/users/channels/${channelId}/boards`, payload)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 게시판 > 게시글 목록
 */
export const getChannelBoard = (params: GetChannelPostRequest) => {
  return axiosInstance
    .get<GetChannelBoardResponse>(`/v1/channels/posts`, params)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 게시판 > 공지 게시글 목록
 */
export const getChannelNoticePostList = (params) => {
  return axiosInstance
    .get<GetChannelBoardResponse>(`/v1/channels/posts/notices`, params)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 게시판 > 공지 게시글 목록
 */
export const getChannelRecentPostList = (params) => {
  return axiosInstance
    .get<GetChannelBoardResponse>(`/v1/channels/posts/recent`, params)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 게시판 > 게시글 목록
 */
export const getChannelBoardForNotice = (params: GetChannelPostRequest) => {
  return axiosInstance
    .get<GetChannelBoardResponse>(`/v1/channels/posts/notices`, params)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 게시판 > 게시글 정보
 */
export const getChannelPostInfo = (postId: number) => {
  return axiosInstance
    .get<GetChannelPostInfoResponse>(`/v1/channels/posts/${postId}`)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 게시판 > 게시글 등록
 */
export const saveChannelPost = (
  boardId: number,
  postType: "DEFAULT" | "NOTICE",
  payload: SaveChannelPostRequest,
) => {
  return axiosInstance
    .post<unknown>(`/v1/users/channels/posts?post_type=${postType}&board_id=${boardId}`, payload)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 게시판 > 게시글 등록
 */
export const saveTempChannelPost = (boardId: number, payload: SaveChannelPostRequest) => {
  return axiosInstance
    .post<unknown>(`/v1/users/channels/posts/temps?board_id=${boardId}`, payload)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 게시판 > 게시글 수정
 */
export const updateChannelPost = (
  channelId: number,
  postId: number,
  boardId: number,
  postType: POST_TYPE,
  payload: SaveChannelPostRequest,
) => {
  return axiosInstance
    .put<unknown>(
      `/v1/users/channels/posts/${postId}?post_type=${postType}&board_id=${boardId}`,
      payload,
    )
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 게시판 > 게시글 삭제
 */
export const deleteChannelPost = (channelId: number, postId: number) => {
  return axiosInstance
    .delete<unknown>(`/v1/users/channels/posts/${postId}`)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 저장된 게시글
 */
export const getSavedChannelPost = (channelId: number, postId: number) => {
  return axiosInstance
    .get<GetSavedChannelPostResponse>(`/v1/users/channels/posts/${postId}`)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 저장된 임시 게시글
 */
export const getSavedChannelTempPost = (channelId: number, postType: string) => {
  return axiosInstance
    .get<GetSavedChannelPostResponse>(`/v1/users/channels/posts/temps`)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 댓글 목록
 */
export const getChannelPostComment = (
  channelId: number,
  postId: number,
  params: GetChannelPostCommentRequest,
) => {
  return axiosInstance
    .get<GetChannelPostCommentResponse>(`/v1/channels/posts/${postId}/comments`, params)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 댓글 등록
 */
export const saveChannelPostComment = (
  channelId: number,
  postId: number,
  payload: SaveChannelPostCommentRequest,
) => {
  return axiosInstance
    .post<unknown>(`/v1/users/channels/posts/${postId}/comments`, payload)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 댓글 수정
 */
export const updateChannelPostComment = (
  channelId: number,
  postId: number,
  commentId: number,
  payload: SaveChannelPostCommentRequest,
) => {
  return axiosInstance
    .put<unknown>(`/v1/users/channels/posts/${postId}/comments/${commentId}`, payload)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 댓글 삭제
 */
export const deleteChannelPostComment = (channelId: number, postId: number, commentId: number) => {
  return axiosInstance
    .delete<unknown>(`/v1/users/channels/posts/${postId}/comments/${commentId}`)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 답글 목록
 */
export const getChannelPostReply = (
  channelId: number,
  postId: number,
  commentId: number,
  params: GetChannelPostReplyRequest,
) => {
  return axiosInstance
    .get<GetChannelPostReplyResponse>(
      `/v1/channels/posts/${postId}/comments/${commentId}/replies`,
      params,
    )
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 답글 등록
 */
export const saveChannelPostReply = (
  channelId: number,
  postId: number,
  commentId: number,
  payload: SaveChannelPostReplyRequest,
) => {
  return axiosInstance
    .post<unknown>(`/v1/users/channels/posts/${postId}/comments/${commentId}/replies`, payload)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 답글 수정
 */
export const updateChannelPostReply = (
  channelId: number,
  postId: number,
  commentId: number,
  replyId: number,
  payload: SaveChannelPostReplyRequest,
) => {
  return axiosInstance
    .put<unknown>(
      `/v1/users/channels/posts/${postId}/comments/${commentId}/replies/${replyId}`,
      payload,
    )
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 답글 삭제
 */
export const deleteChannelPostReply = (
  channelId: number,
  postId: number,
  commentId: number,
  replyId: number,
) => {
  return axiosInstance
    .delete<unknown>(`/v1/users/channels/posts/${postId}/comments/${commentId}/replies/${replyId}`)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 채널 팝업 가져오기
 */
export const getChannelPopup = () => {
  return axiosInstance
    .get<GetChannelPopupResponse>("/v1/channels/popups")
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};
