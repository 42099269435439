import createCache from "@emotion/cache";

/**
 * Emotion Cache 인스턴스 생성
 */
const createEmotionCache = () => {
  return createCache({ key: "eduenv" });
};

export { createEmotionCache };
