import { CLEAR_POINT, SET_POINT } from "../actions/point";

export interface PointInitialState {
  amount: number;
  isReady: boolean;
}

const initialState: PointInitialState = {
  amount: null,
  isReady: false,
};

export const actionSetPoint = (amount: number) => ({
  type: SET_POINT,
  amount,
});

export const actionClearPoint = () => ({
  type: CLEAR_POINT,
});

const point = (state = initialState, action) => {
  switch (action.type) {
    case SET_POINT:
      return {
        amount: action.amount,
        isReady: true,
      };
    case CLEAR_POINT:
      return initialState;
    default:
      return state;
  }
};

export default point;
