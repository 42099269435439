import { css } from "@emotion/react";
import { MEDIA_QUERIES } from "common/constants/styles/responsive";

const styles = {
  container: css`
    position: fixed;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 1060;
  `,
  toast: css`
    ${MEDIA_QUERIES.max.sm} {
      width: 100%;
    }
  `,
  body: css`
    display: flex;
  `,
  button: css`
    margin: auto;
    margin-right: 0.5rem;
  `,
};

export { styles };
