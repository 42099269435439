// API URL
const API_URL = `${process.env.API_PROTOCOL}://${process.env.API_HOST}:${process.env.API_PORT}/eduenv`;

// 알림 소켓 URL
const NOTIFY_BROKER_URL = `${process.env.WEB_SOCKET_PROTOCOL}://${process.env.WEB_SOCKET_HOST}:${process.env.WEB_SOCKET_PORT}/eduenv/v1/notify/websockets`;

// 월드 소켓 URL
const WORLD_BROKER_URL = `${process.env.WEB_SOCKET_PROTOCOL}://${process.env.WEB_SOCKET_HOST}:${process.env.WEB_SOCKET_PORT}/eduenv/v1/worlds/websockets`;

// 룸 소켓 URL
const ROOM_BROKER_URL = `${process.env.WEB_SOCKET_PROTOCOL}://${process.env.WEB_SOCKET_HOST}:${process.env.WEB_SOCKET_PORT}/eduenv/v1/rooms/websockets`;

// 에디터 이미지 업로드 URL
const EDITOR_UPLOAD_IMAGE_URL = `${API_URL}/v1/editors/ck/images`;

// 클라이언트 URL
const CLIENT_URL = `${process.env.CLIENT_PROTOCOL}://${process.env.CLIENT_HOST}:${process.env.CLIENT_PORT}`;

// 돌핀코딩 URL
const DOLPHIN_CODING_CLIENT_URL = `${process.env.DOLPHIN_CODING_CLIENT_PROTOCOL}://${process.env.DOLPHIN_CODING_CLIENT_HOST}:${process.env.DOLPHIN_CODING_CLIENT_PORT}`;

// Scratch URL
const SCRATCH_CLIENT_URL = `${process.env.SCRATCH_CLIENT_PROTOCOL}://${process.env.SCRATCH_CLIENT_HOST}:${process.env.SCRATCH_CLIENT_PORT}`;

// Entry URL
const ENTRY_CLIENT_URL = `${process.env.ENTRY_CLIENT_PROTOCOL}://${process.env.ENTRY_CLIENT_HOST}:${process.env.ENTRY_CLIENT_PORT}`;

function cleanRedirectUrl(url: string) {
  // 모바일에서는 / 가 붙어서 안돌아감

  if (url === null) {
    return CLIENT_URL;
  }

  if (url.endsWith("/")) {
    return url.slice(0, -1);
  }
  if (url.endsWith("%2F")) {
    return url.slice(0, -3);
  }

  return url;
}

export {
  API_URL,
  NOTIFY_BROKER_URL,
  ROOM_BROKER_URL,
  WORLD_BROKER_URL,
  EDITOR_UPLOAD_IMAGE_URL,
  CLIENT_URL,
  DOLPHIN_CODING_CLIENT_URL,
  SCRATCH_CLIENT_URL,
  ENTRY_CLIENT_URL,
  cleanRedirectUrl,
};
