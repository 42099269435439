import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "../shared/request";
import { ChannelProfile } from "./room";

export type FriendshipStatus = "ACCEPT" | "PENDING" | "REJECT";

export const FRIENDSHIP_VIEW = {
  ACCEPT: "깐부",
  PENDING: "대기중",
  REJECT: "거절",
};

export interface ResponseFriendshipList {
  friendList: FriendshipDto[];
  pageCount: number;
  totalCount: number;
}

export interface ResponseLobbyUserList {
  userList: LobbyUserDto[];
  pageCount: number;
  totalCount: number;
}

export interface LobbyUserDto {
  userId: string;

  nickname: string;
  profile: string;
  state: string;
  activeState: string;

  level: number;
  gender: "MAN" | "WOMAN";

  channelId: number;
  channelProfile: ChannelProfile;
}
export interface FriendshipDto {
  friendshipId: number;
  userId: string;
  friendshipStatus: FriendshipStatus;
  lastAccessDate: string;
  activeState: string;

  nickname: string;
  profile: string;
  state: string;

  level: number;
  gender: "MAN" | "WOMAN";

  channelId: number;
  channelProfile: ChannelProfile;
}

/**
 * API:
 * 로비 유저 가져오기
 */
export const getLobbyUserList = (params) => {
  return axiosInstance
    .get<ResponseLobbyUserList>("/v1/auth/rooms/lobbies/members", params)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 사용자의 친구 목록 가져오기
 **/
export const getFriendList = (params) => {
  return axiosInstance
    .get<ResponseFriendshipList>("/v1/users/friendships", params)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 사용자의 친구 목록 가져오기
 **/
export const getAutocompeteFriendList = (params) => {
  return axiosInstance
    .get<ResponseFriendshipList>("/v1/users/friendships/search/autocomplete", params)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 사용자의 친구 목록 가져오기
 **/

export const getReceiveFriendList = (params) => {
  return axiosInstance
    .get<ResponseFriendshipList>("/v1/users/friendships/receives", params)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 친구 요청
 */
export const requestFriendship = (params: { userId?: string; nickname?: string }) => {
  return axiosInstance
    .post<unknown>(`/v1/users/friendships`, params)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 친구 요청 승인
 */
export const acceptFriendship = (friendshipId) => {
  return axiosInstance
    .put<unknown>(`/v1/users/friendships/${friendshipId}/accept`)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

/**
 * API:
 * 친구 요청 거절
 *  */
export const rejectFriendship = (friendshipId) => {
  return axiosInstance
    .put<unknown>(`/v1/users/friendships/${friendshipId}/reject`)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};
