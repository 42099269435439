import Swal, { SweetAlertResult } from "sweetalert2";
import { actionSetIsProgress, PaymentInitialState } from "../modules/reducers/payment";
import { AxiosError } from "axios";
import { Fragment } from "react";
import { isClient } from "common/utils/common";
import { renderToString } from "react-dom/server";
import { RootReducer } from "../modules/reducers";
import { useDispatch, useSelector } from "react-redux";
import {
  PrepareDolphinSubscriptionResponse,
  PrepareChannelSubscriptionResponse,
  prepareDolphinSubscription,
  prepareChannelSubscription,
} from "../api/payment";

const usePayment = () => {
  const dispatch = useDispatch();

  const { isProgress }: PaymentInitialState = useSelector(
    (state: RootReducer) => state.paymentReducer,
  );

  const init = () => {
    if (!isClient()) {
      return;
    }

    (window as any).IMP.init(process.env.MERCHANT_ID);
  };

  const request = (params, callback: () => void) => {
    (window as any).IMP.request_pay(params, (response) => {
      dispatch(actionSetIsProgress(false));

      // 결제 성공
      if (response.success) {
        window.location.href = "/purchase/result";
      }

      // 결제 실패 및 취소
      else {
        Swal.fire({
          title: "알림",
          html: renderToString(
            <Fragment>
              결제가 완료되지 않았습니다.
              <br />
              다시 시도해 주세요.
            </Fragment>,
          ),
          showCloseButton: true,
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: "닫기",
          customClass: "swal2-sm",
        }).then((result: SweetAlertResult) => {
          callback();
        });
      }
    });
  };

  /**
   * 구독 결제
   */
  const requestSubscription = (
    params: {
      engCodeName: "DOLPHIN_PRO" | "CHANNEL_PRO";
      name: string;
      channelId?: number;
      amount?: number;
    },
    callback: () => void,
  ) => {
    dispatch(actionSetIsProgress(true));

    const payload = {
      engCodeName: params.engCodeName as any,
    };

    switch (params.engCodeName) {
      case "DOLPHIN_PRO":
        prepareDolphinSubscription(payload)
          .then((response: PrepareDolphinSubscriptionResponse) => {
            let pg = "";

            // 실제 결제
            if (window.location.hostname.includes("eduenv.com")) {
              pg = "tosspayments.bill_im_edpmf4";
            }
            // 테스트 결제
            else {
              pg = "tosspayments.bill_im_edpmf4";
            }

            request(
              {
                pg,
                customer_uid: response.customerUid,
                merchantUid: response.merchantUid,
                name: params.name,
                amount: response.amount,
                buyer_name: response.name,
                buyer_email: response.email,
                buyer_tel: response.phoneNumber,
                custom_data: response.custom_data,
                m_redirect_url: `${window.location.origin}/purchase/result`,
                ...(!window.location.hostname.includes("eduenv.com") && {
                  notice_url: "https://api.dev.eduenv.kr/eduenv/v1/payments/completions",
                }),
              },
              callback,
            );
          })
          .catch((error: AxiosError) => {
            dispatch(actionSetIsProgress(false));
          });
        break;
      case "CHANNEL_PRO":
        prepareChannelSubscription(params.channelId, payload)
          .then((response: PrepareChannelSubscriptionResponse) => {
            let pg = "";

            // 실제 결제
            if (window.location.hostname.includes("eduenv.com")) {
              pg = "tosspayments.bill_im_edpmf4";
            }
            // 테스트 결제
            else {
              pg = "tosspayments.bill_im_edpmf4";
            }

            request(
              {
                pg,
                customer_uid: response.customerUid,
                merchantUid: response.merchantUid,
                name: params.name,
                amount: response.amount,
                buyer_name: response.name,
                buyer_email: response.email,
                buyer_tel: response.phoneNumber,
                custom_data: response.custom_data,
                m_redirect_url: `${window.location.origin}/purchase/result`,
                ...(!window.location.hostname.includes("eduenv.com") && {
                  notice_url: "https://api.dev.eduenv.kr/eduenv/v1/payments/completions",
                }),
              },
              callback,
            );
          })
          .catch((error: AxiosError) => {
            dispatch(actionSetIsProgress(false));
          });
        break;
      default:
    }
  };

  return {
    isProgress,
    init,
    requestSubscription: (
      params: {
        engCodeName: "DOLPHIN_PRO" | "CHANNEL_PRO";
        name: string;
        channelId?: number;
        amount?: number;
      },
      callback: () => void,
    ) => {
      requestSubscription(params, callback);
    },
  };
};

export default usePayment;
