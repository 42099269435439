import {
  actionSetConfettiVisible,
  actionSetCounterState,
  actionSetWaveState,
  AnimationInitialState,
} from "../modules/reducers/animation";
import { RootReducer } from "../modules/reducers";
import { useDispatch, useSelector } from "react-redux";
import { AnimationWave } from "../constants/animations/wave";

const useAnimation = () => {
  const dispatch = useDispatch();

  const animationState: AnimationInitialState = useSelector(
    (state: RootReducer) => state.animationReducer,
  );

  const counter = {
    show: () => {
      dispatch(actionSetCounterState("SHOW"));
    },
    close: () => {
      dispatch(actionSetCounterState("CLOSE"));
    },
  };

  const wave = {
    setState: (state: AnimationWave) => {
      dispatch(actionSetWaveState(state));
    },
    show: () => {
      dispatch(actionSetWaveState("SHOW"));
    },
    close: () => {
      dispatch(actionSetWaveState("CLOSE"));
    },
  };

  const confetti = {
    show: () => {
      dispatch(actionSetConfettiVisible(true));
    },
    close: () => {
      dispatch(actionSetConfettiVisible(false));
    },
  };

  return {
    wave: {
      ...animationState.wave,
      ...wave,
    },
    counter: {
      ...animationState.counter,
      ...counter,
    },
    confetti: {
      ...animationState.confetti,
      ...confetti,
    },
  };
};

export default useAnimation;
