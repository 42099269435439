import Cookies from "js-cookie";

import useAuth from "./useAuth";
import useAvator from "../../../../common/src/hooks/useAvator";
import useCash from "./useCash";
import usePoint from "./usePoint";
import usePopup from "./usePopup";
import useQuest from "./useQuest";
import useSocket from "./useSocket";
import useSound from "common/hooks/useSound";
import useToast from "common/hooks/useToast";
import { AxiosError } from "axios";
import { Fragment, MouseEvent, useEffect, useState } from "react";
import { GoalList, QuestList, rewardQuest } from "../api/quest";
import { NotificationList } from "../api/notification";
import { RootReducer } from "../modules/reducers";
import { useDispatch, useSelector } from "react-redux";
import {
  NotificationChatInfoState,
  NotificationInitialState,
  NotificationPagination,
  actionSetChatInfoState,
  actionSetChatMessage,
  actionSetIsDataReady,
  actionSetIsReady,
  actionSetNotificationList,
  actionSetPagination,
} from "../modules/reducers/notification";
import { getChannelUrl } from "../utils/url";
import { CLIENT_URL } from "../constants/urls";

export interface SubscribeNotificationResponse {
  title: string;
  description: string;
  notificationType: "NOTIFICATION" | "REWARD";
}

export interface SubscribeNotificationInviteRoomResponse {
  title: string;
  description: string;
  roomCode: string;
  notificationType: "NOTIFICATION" | "REWARD";
}

export interface SubscribeQuestResponse {
  questNotificationType: "PUBLISH_QUEST" | "QUEST_GOAL_UPDATED";
  userQuest: QuestList;
}

export interface SubscribeRewardResponse {
  title: string;
  description: string;
  completedDescription: string;
  rewardType: "POINT" | "USER_EXP";
  count: number;
}

const useNotification = () => {
  const dispatch = useDispatch();

  const auth = useAuth();
  const avator = useAvator();
  const cash = useCash();
  const point = usePoint();
  const popup = usePopup();
  const quest = useQuest();
  const socket = useSocket();
  const sound = useSound();
  const toast = useToast();

  const { notificationList, chatInfo, pagination, isDataReady, isReady }: NotificationInitialState =
    useSelector((state: RootReducer) => state.notificationReducer);

  // isControl이 true인 Hook에서만 제어
  const [isControl, setIsControl] = useState<boolean>(false);

  // 알림 목록
  const appendData = (data: NotificationList[]) => {
    dispatch(actionSetNotificationList([...notificationList, ...data]));
    dispatch(actionSetIsDataReady(true));
  };

  const setPagination = (pagination: NotificationPagination) => {
    dispatch(actionSetPagination(pagination));
  };

  const checkData = (notificationId: string) => {
    const newState = [...notificationList];

    const findedIndex = newState.findIndex(
      (row: NotificationList) => row.notificationId === notificationId,
    );

    newState[findedIndex] = {
      ...newState[findedIndex],
      checked: true,
    };

    dispatch(actionSetNotificationList(newState));
  };

  const deleteData = (notificationId: string) => {
    const newState = [...notificationList];

    const findedIndex = newState.findIndex(
      (row: NotificationList) => row.notificationId === notificationId,
    );

    newState.splice(findedIndex, 1);

    dispatch(actionSetNotificationList(newState));
  };

  // 알림 갱신 요청
  const clearData = () => {
    dispatch(actionSetNotificationList([]));
    dispatch(
      actionSetPagination({
        itemsPerPage: 0,
        total: 0,
        currentPage: 1,
      }),
    );
    dispatch(actionSetIsDataReady(false));
  };

  const setChatInfo = (chatInfo: NotificationChatInfoState) => {
    // 모두에게, 귓속말, 채널 채팅 등등
    dispatch(actionSetChatInfoState(chatInfo));
  };

  // world subscribe
  const lobbyChatSubscribeInitial = () => {
    // 게임 입장시 유저 리스트
    const roomChatUrl = `/topic/room/lobby/chat/client`;
    socket.notification.client?.subscribe(roomChatUrl, subscribeLobbyChat, {
      id: roomChatUrl,
      token: Cookies.get("accessToken"),
    });
  };

  // world subscribe
  const lobbyChatUnsubscribe = () => {
    // 게임 입장시 유저 리스트
    const roomChatUrl = `/topic/room/lobby/chat/client`;
    socket.notification.client?.unsubscribe(roomChatUrl);
  };

  // world subscribe
  const roomChatSubscribeInitial = (roomCode: string) => {
    // 게임 입장시 유저 리스트
    const roomChatUrl = `/topic/room/code/${roomCode}/chat/client`;
    socket.notification.client?.subscribe(roomChatUrl, subscribeRoomChat, {
      id: roomChatUrl,
      "room-token": window.sessionStorage.getItem("roomToken"),
    });
  };

  // world subscribe
  const roomChatUnsubscribe = (roomCode: string) => {
    // 게임 입장시 유저 리스트
    const roomChatUrl = `/topic/room/code/${roomCode}/chat/client`;
    socket.notification.client?.unsubscribe(roomChatUrl);
  };

  // world subscribe
  const roomScoreboardChatSubscribeInitial = (roomCode: string) => {
    const roomScoreboardChatUrl = `/topic/room/code/${roomCode}/scoreboard/chat/client`;

    socket.notification.client?.subscribe(roomScoreboardChatUrl, subscribeScoreboardChat, {
      id: roomScoreboardChatUrl,
      "room-token": window.sessionStorage.getItem("roomToken"),
    });
  };

  // world subscribe
  const roomScoreboardChatUnsubscribe = (roomCode: string) => {
    const roomScoreboardChatUrl = `/topic/room/code/${roomCode}/scoreboard/chat/client`;

    socket.notification.client?.unsubscribe(roomScoreboardChatUrl);
  };

  // 소켓 연결
  const connect = () => {
    if (socket.notification.state === "CONNECTED") {
      return;
    }

    setIsControl(true);

    socket.connect({ target: "NOTIFICATION" });
  };

  // 소켓 연결 종료
  const disconnect = () => {
    setIsControl(true);

    socket.disconnect({ target: "NOTIFICATION" });
  };

  const subscribeChannelChat = (message: { body: string }) => {
    const response = JSON.parse(message.body);
    //Event bus로 메세지 전달
    // EventBus.emit("receive-chat", response);
    dispatch(
      actionSetChatMessage({
        ...response.content,
        chatType: "CHANNEL",
      }),
    );

    // 말풍선 채팅 띄우는 용도로 EventBus로 전달
    // EventBus.emit("send-chat-ballon", response);
  };
  const subscribeUserChat = (message: { body: string }) => {
    const response = JSON.parse(message.body);
    //Event bus로 메세지 전달
    // EventBus.emit("receive-chat", response);
    dispatch(
      actionSetChatMessage({
        ...response.content,
        chatType: "USER",
      }),
    );

    // 말풍선 채팅 띄우는 용도로 EventBus로 전달
    // EventBus.emit("send-chat-ballon", response);
  };
  const subscribeLobbyChat = (message: { body: string }) => {
    const response = JSON.parse(message.body);
    //Event bus로 메세지 전달
    // EventBus.emit("receive-chat", response);
    dispatch(
      actionSetChatMessage({
        ...response.content,
        chatType: "LOBBY",
      }),
    );
    // 말풍선 채팅 띄우는 용도로 EventBus로 전달
    // EventBus.emit("send-chat-ballon", response);
  };

  const subscribeScoreboardChat = (message: { body: string }) => {
    const response = JSON.parse(message.body);
    //Event bus로 메세지 전달
    // EventBus.emit("receive-chat", response);
    dispatch(
      actionSetChatMessage({
        ...response.content,
        chatType: "SCOREBOARD",
      }),
    );

    // 말풍선 채팅 띄우는 용도로 EventBus로 전달
    // EventBus.emit("send-chat-ballon", response);
  };
  const subscribeRoomChat = (message: { body: string }) => {
    const response = JSON.parse(message.body);
    //Event bus로 메세지 전달
    // EventBus.emit("receive-chat", response);
    dispatch(
      actionSetChatMessage({
        ...response.content,
        chatType: "ROOM",
      }),
    );

    // 말풍선 채팅 띄우는 용도로 EventBus로 전달
    // EventBus.emit("send-chat-ballon", response);
  };

  // 전체 알림에 대한 응답
  const subscribeNotificationCallback = (message: { body: string }) => {
    const response: SubscribeNotificationResponse = JSON.parse(message.body);

    sound.play({ target: "NOTIFICATION" });

    toast
      .message(
        <Fragment>
          <h6 className="dc-toast-title m-0">{response.title}</h6>
          <div className="dc-toast-content">{response.description}</div>
        </Fragment>,
      )
      .show({
        type: "warn",
        config: {
          autoClose: false,
        },
      });

    clearData();
  };

  // 유저 알림에 대한 응답
  const subscribeUserNotificationCallback = (message: { body: string }) => {
    const response: SubscribeNotificationResponse = JSON.parse(message.body);

    sound.play({ target: "NOTIFICATION" });

    toast
      .message(
        <Fragment>
          <h6 className="dc-toast-title m-0">{response.title}</h6>
          <div className="dc-toast-content">{response.description}</div>
        </Fragment>,
      )
      .show({
        type: "warn",
        config: {
          autoClose: false,
        },
      });

    clearData();
  };

  // 유저 알림에 대한 응답
  const subscribeUserInviteBattleNotificationCallback = (message: { body: string }) => {
    const response: SubscribeNotificationInviteRoomResponse = JSON.parse(message.body);

    sound.play({ target: "NOTIFICATION" });

    toast
      .message(
        <Fragment>
          <h6 className="dc-toast-title m-0">{response.title}</h6>
          <div className="dc-toast-content">{response.description}</div>
          <button
            type="button"
            className="btn border bg-white"
            onClick={(event: MouseEvent<HTMLButtonElement>) => {
              publishAcceptInviteBattle(response.roomCode, "");
            }}
          >
            수락하기
          </button>
        </Fragment>,
      )
      .show({
        type: "success",
        config: {
          autoClose: false,
        },
      });

    clearData();
  };

  // 퀘스트 대한 응답
  const subscribeQuestCallback = (message: { body: string }) => {
    const response: SubscribeQuestResponse = JSON.parse(message.body);

    sound.play({ target: "NOTIFICATION" });
    quest.clear();

    clearData();

    // 메인 퀘스트인 경우 다음 퀘스트를 팝업으로 띄움
    if (
      response.questNotificationType === "PUBLISH_QUEST" &&
      response.userQuest.quest.publishEventView === "POP_UP"
    ) {
      popup.quest.setQueue([...popup.quest.queue, response.userQuest]);
      return;
    }

    toast
      .message(
        <Fragment>
          <h6 className="dc-toast-title m-0">
            {response.userQuest.quest.clear ? (
              <Fragment>{response.userQuest.quest.title} 퀘스트 완료</Fragment>
            ) : (
              <Fragment>{response.userQuest.quest.title}</Fragment>
            )}
          </h6>
          <div className="dc-toast-content">
            <ul className="m-0">
              {response.userQuest.quest.goalList.map((goalRow: GoalList, goalIndex: number) => (
                <li key={goalIndex}>
                  {goalRow.goalName}{" "}
                  <span className="d-inline-block fw-bold">
                    ({goalRow.completedCount} / {goalRow.goalCount})
                  </span>
                </li>
              ))}
            </ul>
            {response.userQuest.quest.clear && (
              <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={(event: MouseEvent<HTMLButtonElement>) => {
                  rewardQuest(response.userQuest.userQuestId)
                    .then((response: unknown) => {
                      quest.clear();
                      point.clear();
                      cash.clear();
                    })
                    .catch((error: AxiosError) => {});
                }}
              >
                보상받기
              </button>
            )}
          </div>
        </Fragment>,
      )
      .show({ type: "info" });
  };

  // 보상에 대한 응답
  const subscribeRewardCallback = (message: { body: string }) => {
    const response: SubscribeRewardResponse = JSON.parse(message.body);

    switch (response.rewardType) {
      case "POINT":
        {
          quest.clear();
          sound.play({ target: "PURCHASE" });
          popup.reward.show({ description: response.completedDescription, amount: response.count });
        }
        break;
      case "USER_EXP":
        {
          avator.clear();
        }
        break;
      default:
    }
  };

  // 채팅 전송
  /**
   * @param type WORLD, CHANNEL.. 과 같이 전송할 위치 분기처리 용도
   */

  const publishSendChat = (message: string) => {
    let destinationUrl = "";
    let token = null;
    let roomToken = null;
    switch (chatInfo.type) {
      case "LOBBY":
        destinationUrl = `/topic/room/lobby/chat/server`;
        token = Cookies.get("accessToken");
        break;

      case "ROOM":
        destinationUrl = `/topic/room/code/${chatInfo.roomCode}/chat/server`;
        roomToken = window.sessionStorage.getItem("roomToken");
        break;
      // Todo: 타입 추가 예정..
      case "SCOREBOARD":
        destinationUrl = `/topic/room/code/${chatInfo.roomCode}/scoreboard/chat/server`;
        roomToken = window.sessionStorage.getItem("roomToken");
        break;
      case "CHANNEL":
        destinationUrl = `/topic/channel/${chatInfo.channelId}/chat/server`;
        token = Cookies.get("accessToken");
        break;
      case "USER":
        destinationUrl = `/user/${chatInfo.userId}/queue/chat/server`;
        token = Cookies.get("accessToken");
        break;

      // Todo: 타입 추가 예정..
      default:
        console.log("NO TYPE");
    }

    socket.notification.client?.publish({
      destination: destinationUrl,
      body: JSON.stringify(message),
      headers: {
        "Content-Type": "application/json",
        ...(token ? { token: token } : null),
        ...(roomToken ? { "room-token": roomToken } : null),
      },
    });
  };

  const publishAcceptInviteBattle = (roomCode: string, message: string) => {
    let destinationUrl = "";
    let token = null;
    let roomToken = null;
    destinationUrl = `/queue/room/code/${roomCode}/member/invite/accept`;
    token = Cookies.get("accessToken");

    socket.notification.client?.publish({
      destination: destinationUrl,
      body: JSON.stringify(message),
      headers: {
        "Content-Type": "application/json",
        ...(token ? { token: token } : null),
        ...(roomToken ? { "room-token": roomToken } : null),
      },
    });
  };

  useEffect(() => {
    console.log("isReady Control : ", isReady, isControl);
    if (isReady || !isControl) {
      return;
    }

    if (socket.notification.state === "CONNECTED") {
      // 준비됨
      dispatch(actionSetIsReady(true));
      dispatch(
        actionSetChatInfoState({
          type: "CHANNEL",
          channelId: auth?.channelProfile?.channelId,
          roomCode: null,
          userId: null,
          nickname: null,
        }),
      );

      // 전체 알림
      socket.notification.client.subscribe(
        "/queue/notification/client",
        subscribeNotificationCallback,
      );

      // 대표 채널 채팅
      if (auth?.channelProfile && auth?.channelProfile?.channelId) {
        const destination = `/topic/channel/${auth?.channelProfile?.channelId}/chat/client`;
        const token = Cookies.get("accessToken");

        socket.notification.client.subscribe(destination, subscribeChannelChat, {
          id: destination,
          token: token,
        });
      }

      const notificationDestination = `/user/${auth.userId}/queue/notification/client`;
      // 유저 알림
      socket.notification.client.subscribe(
        notificationDestination,
        subscribeUserNotificationCallback,
        {
          id: notificationDestination,
          token: Cookies.get("accessToken"),
        },
      );

      const notificationInviteBattle = `/user/${auth.userId}/queue/invite/battle/notification/client`;
      // 유저 배틀 초대 알림
      socket.notification.client.subscribe(
        notificationInviteBattle,
        subscribeUserInviteBattleNotificationCallback,
        {
          id: notificationInviteBattle,
          token: Cookies.get("accessToken"),
        },
      );

      const questDestination = `/user/${auth.userId}/queue/quest/client`;
      // 퀘스트
      socket.notification.client.subscribe(
        `/user/${auth.userId}/queue/quest/client`,
        subscribeQuestCallback,
        {
          id: questDestination,
          token: Cookies.get("accessToken"),
        },
      );

      const rewardDestination = `/user/${auth.userId}/queue/reward/client`;
      // 보상
      socket.notification.client.subscribe(
        `/user/${auth.userId}/queue/reward/client`,
        subscribeRewardCallback,
        {
          id: rewardDestination,
          token: Cookies.get("accessToken"),
        },
      );

      const userChatDestination = `/user/${auth.userId}/queue/chat/client`;
      // 귓속말 채팅
      socket.notification.client.subscribe(
        `/user/${auth.userId}/queue/chat/client`,
        subscribeUserChat,
        {
          id: userChatDestination,
          token: Cookies.get("accessToken"),
        },
      );
    }

    if (socket.notification.state === "DISCONNECTED") {
      // 준비됨
      dispatch(actionSetIsReady(true));
    }
  }, [socket.notification.state, isReady, isControl]);

  return {
    notificationList,
    pagination,
    isDataReady,
    isReady,
    appendData: (data: NotificationList[]) => {
      appendData(data);
    },
    setPagination: (pagination: NotificationPagination) => {
      setPagination(pagination);
    },
    checkData: (notificationId: string) => {
      checkData(notificationId);
    },
    deleteData: (notificationId: string) => {
      deleteData(notificationId);
    },
    clearData,
    publishSendChat,
    chatInfo,
    setChatInfo,
    lobbyChatSubscribeInitial,
    lobbyChatUnsubscribe,
    roomChatSubscribeInitial,
    roomChatUnsubscribe,
    roomScoreboardChatSubscribeInitial,
    roomScoreboardChatUnsubscribe,
    connect,
    disconnect,
  };
};

export default useNotification;
